import React from 'react'
import '../Testamonial/Testamonial.css'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";


const Testamonial = () => {
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 2,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
        },
        tablet: {
            breakpoint: { max: 1024, min: 820 },
            items: 3,
        },
        mobile: {
            breakpoint: { max: 632, min: 0 },
            items: 1,
        },
    };

    const TastemonialData = [
      {
          id: 0,
          image: "imagepath",
          Name: "Sumon Chakraborty",
          Review: "I had a seamless experience using the platform. The variety of insurance options available was impressive, and the comparison tools helped me make an informed decision. The customer support was also very helpful throughout the process. Highly recommend this service for anyone looking to explore insurance options!",
          Givingstar: 5
      },
      {
          id: 1,
          image: 'imagepath',
          Name: "Arajit Mondal",
          Review: "Overall, I’m satisfied with the experience. The platform was easy to use, and I was able to compare various insurance policies quickly. However, I feel that there could be a few more personalized recommendations based on my profile.",
          Givingstar: 4
      },
      {
          id: 2,
          image: 'imagepath',
          Name: "Sanchita Sharma",
          Review: "The website was very user-friendly, and I appreciated the detailed policy breakdowns. It made my decision-making process a lot easier. A little more information on customer reviews of the insurance providers would make it even better.",
          Givingstar: 4
      },
      {
          id: 3,
          image: 'imagepath',
          Name: "Subhadip Sarkar",
          Review: "The platform was helpful, but I had a few challenges with the navigation and finding detailed policy terms. I had to contact customer support for clarification, but they were responsive and resolved my query quickly. Still, there is room for improvement in terms of user experience.",
          Givingstar: 5
      },
      {
          id: 4,
          image: 'imagepath',
          Name: "Nilanjan Bhattacharya",
          Review: "I couldn’t have asked for a better experience! The platform provided a wide variety of insurance policies to compare, and the user interface was very intuitive. I found the perfect plan for me in no time. The customer service was excellent as well!",
          Givingstar: 5
      },
      {
          id: 5,
          image: 'imagepath',
          Name: "Biplab Sarkar",
          Review: "The platform is solid and provides a great selection of insurance options. I liked how easy it was to compare quotes. However, I feel there could be more filters to help narrow down the options more effectively.",
          Givingstar: 4
      },
      {
          id: 6,
          image: 'imagepath',
          Name: "Koushik Jhaa",
          Review: "The service was generally good. I was able to compare policies and choose one that fits my needs. There were a few delays in getting in touch with support, but overall, the experience was smooth, and I’m happy with the plan I selected.",
          Givingstar: 4
      },
      {
          id: 7,
          image: 'imagepath',
          Name: "Goutam Das",
          Review: "Excellent platform! It helped me find an insurance policy that suited my needs perfectly. The comparison feature was incredibly helpful in understanding what each plan offers. I’ll definitely be using this service again for future insurance purchases.",
          Givingstar: 5
      },
      {
          id: 8,
          image: 'imagepath',
          Name: "Suparna Saha",
          Review: "I had a good experience using the platform. The variety of plans was great, and the process was simple. The only thing I think could be improved is having more in-depth comparisons between plans, especially for those with complex coverage options.",
          Givingstar: 4
      },
      {
          id: 9,
          image: 'imagepath',
          Name: "Sanchita Triwari",
          Review: "I’m very satisfied with the service provided. The website made it easy to compare multiple insurance plans, and the information provided was clear and helpful. The customer support team was excellent when I had questions about my policy.",
          Givingstar: 5
      },
      {
          id: 10,
          image: 'imagepath',
          Name: "Rajib Sarkar",
          Review: "A great experience! I found the platform to be very intuitive, and I was able to compare quotes from multiple insurance providers in minutes. The claims process could be explained in more detail, but overall, I’m very happy with my experience.",
          Givingstar: 5
      },
      {
          id: 10,
          image: 'imagepath',
          Name: "Purab Jha",
          Review: "The platform is good, but I had some issues with the mobile interface. It took longer than expected to get through the comparison process. Once I reached customer support, they were very helpful in guiding me through the options, but the website could use a bit of improvement.",
          Givingstar: 3
      }
  ];
  


    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.slice(0, maxLength) + '...';
        }
        return text;
    };



    const renderStars = (count) => {
        return '⭐'.repeat(count);
    };
    return (
        <>
        <div className="fixwidthDivtext">
        <div className="container">
 
  <div className="tile-text-with-text-shadow">
    <h2>TESTIMONIALS</h2>
  </div>
  



<div className="commonone">

      <h4 className='theheadingcolor'>
        What Our Customers Are Saying
      </h4>
      <div className="yellowline"></div>
    <span className='textformat'>
      Discover testimonials from satisfied customers about our insurance
      services. See how we've protected and supported individuals and families
    </span>
</div>






        <div className="uppercarsoul">
        <div className="backgroundcarasoul">
        <Carousel
        swipeable={false}
                draggable={false}
                showDots={false}
                responsive={responsive}
                ssr={false} 
                infinite={true}
                autoPlay={true}
                // autoPlaySpeed={2000} 
                keyBoardControl={true}
                customTransition="transform 0.5s ease" 
                transitionDuration={500}
                containerClass="carousel-container"
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px">

     {
        TastemonialData.map((data)=>{
          return(
            <div className="tastemonialcard">
            <div className="nameandstar">
            <div className='undertextstar'>
            <h6>{data.Name}</h6>
            <p>{renderStars(data.Givingstar)}</p>
            </div>
            </div>
           <div className="discriptiontestomonial">
           <span>{truncateText(data.Review, 370)}</span>
           </div>
            </div>
          )
        })
     }
       

 
            </Carousel>
            </div>
            </div>
            </div>
            </div>
            </>
    );
}

export default Testamonial;
