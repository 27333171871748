import React, { useEffect } from "react";
import "../HomePage/Homepage.css";
import Blog from "./Blog";
import { Link } from "react-router-dom";
import FAQ from "../AllPageComponents/FAQ/FAQ";
import PartnerCompany from "../AllPageComponents/PartnerCompany/PartnerCompany";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Preferredpolicy from "../AllPageComponents/ Preferredpolicy";
import Stepper from "./Stepper/Stepper";
import Homepagecalculator from "./HomepageCalculator/Homepagecalculator";
import ImportenceOfInsurence from "../CommonPages/ImportenceOfInsurence/ImportenceOfInsurence";
import AdviceFromExpert from "./AdviceFromExperts/AdviceFromExpert";
import FastImage from "../FastImage";
import { auto } from "@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core";
import Testamonial from "../CommonPages/Testamonial/Testamonial";
import BeemaAdvantage from "../CommonPages/BeemaAdvantage/BeemaAdvantage";
import { Helmet } from "react-helmet-async";




const Homepage = () => {

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
  };
 
  const imageData = [
   {id:1,image:"Website%20Img/two-min.jpg?updatedAt=1731579162650", link:'/health-insurance'},
   {id:2,image:"Website%20Img/three.jpg?updatedAt=1718705583082", link:'/term-insurance'},
   {id:3,image:"Website%20Img/one.jpg?updatedAt=1718705583042", link: "/Bike-insurance"},];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);






  return (
    <div className="noscroll">
        <Helmet>
    <title>Compare & Buy Discount Insurance  Health, Term, Car, Bike</title>
    <meta name="description" content="Compare and buy discounted insurance policies for health, term life, car, and bike. Get instant insurance quotes and find the best plans tailored to your needs. Save on coverage today!" />
    <meta name="keywords" content="Affordable Health Insurance, Term Life Insurance, Cheap Car Insurance, Best Bike Insurance, Insurance Quotes, Buy Insurance Online, Discounted Insurance Plans, Compare Insurance" />
</Helmet>

      <section className="home-first-section home-section">
        <div className="container">
          <div className="row first-section-of-first second-section-of-first">
            <div className="col-sm-12 col-md-7 col-lg-7 hero-main-one">
              <h6 className="tag_line">
                <span className="blue">#WE DON'T SELL</span>&nbsp;
                <span>WE GUIDE</span>
              </h6>
              <h1>
                <span className="tomato-red"><span className="heighlighittext">Best Insurance </span>Policy in India</span>
                <span className="normal-blue">Health, Life, Term, Car & Bike</span>
                
              </h1>

              <p className='textformat'>
              We Beema1, help you to understand insurance in simple words, we guide you through various elements of insurance, 
              identify and grasp your policy. Here you can easily compare and buy the best insurance policy in India,get your queries addressed, and secure yourself—all conveniently under one umbrella.
              </p>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-5 marginslider">
              <div className="owl-stage-outer classpointer">
                <Slider {...settings}>
                  {imageData.map((item) => (
                    <Link to={item.link}>
                    <div key={item.id}>
                      <FastImage
                        url={item.image}
                        width={470}
                        height={260}
                        src={item.image}
                         loading="eager"
                        />
                    </div>
                        </Link>
                  ))}
                </Slider>
              </div>
            </div>
          </div>

          <div className="row third-section-of-first">
            <div className="col-sm-12 col-md-2 col-lg-2 animation">
              <Link className="ins-type-box" to={"/car-insurance"}>
                <div className="arrow-hover">
                 
                  <FastImage
                      
                  url={"/Website%20Img/right-arrow.png?updatedAt=1718706342956"}
                  width={auto}
                  height={auto}
                  alt={"right-arrow"}
                  src={"/Website%20Img/right-arrow.png?updatedAt=1718706342956"}
                   loading="eager"
                  />
                </div>
                <div className="inner-sub-box">
                  <div className="icon-back-sure">
                    

                    <FastImage
                      
                      url={"/Website%20Img/car-insurance.png?updatedAt=1718706593007"}
                      width={auto}
                      height={auto}
                      alt={"car-insurance"}
                      src={"/Website%20Img/car-insurance.png?updatedAt=1718706593007"}
                       loading="eager"
                      />
                  </div>
                  <h4>Car</h4>
                  <h6>Insurance</h6>
                </div>
              </Link>
            </div>
            <div className="col-sm-12 col-md-2 col-lg-2 animation">
              <Link className="ins-type-box" to={"/Bike-insurance"}>
                <div className="arrow-hover">
                 

                  <FastImage
                      
                      url={"/Website%20Img/right-arrow.png?updatedAt=1718706342956"}
                      width={auto}
                      height={auto}
                      alt={"right-arrow"}
                      src={"/Website%20Img/right-arrow.png?updatedAt=1718706342956"}
                       loading="eager"
                      />
                </div>
                <div className="inner-sub-box">
                  <div className="icon-back-sure">
                   

                    <FastImage
                      
                      url={"/Website%20Img/bike-insurance.png?updatedAt=1718707037051"}
                      width={auto}
                      height={auto}
                      alt={"bike-logo"}
                      src={"/Website%20Img/bike-insurance.png?updatedAt=1718707037051"}
                       loading="eager"
                      />
                  </div>
                  <h4>Bike</h4>
                  <h6>Insurance</h6>
                </div>
              </Link>
            </div>
            <div className="col-sm-12 col-md-2 col-lg-2 animation">
              <Link className="ins-type-box" to={"/health-insurance"}>
                <div className="arrow-hover">
                  
                  <FastImage
                      
                      url={"/Website%20Img/right-arrow.png?updatedAt=1718706342956"}
                      width={auto}
                      height={auto}
                      alt={"right-arrow"}
                      src={"/Website%20Img/right-arrow.png?updatedAt=1718706342956"}
                       loading="eager"
                      />
                </div>
                <div className="inner-sub-box">
                  <div className="icon-back-sure">
                   
                    <FastImage
                      
                      url={"/Website%20Img/health-insurance.png?updatedAt=1718709127282"}
                      width={auto}
                      height={auto}
                      alt={"health-logo"}
                      src={"/Website%20Img/health-insurance.png?updatedAt=1718709127282"}
                       loading="eager"
                      />
                  </div>
                  <h4>Health</h4>
                  <h6>Insurance</h6>
                </div>
              </Link>
            </div>
            <div className="col-sm-12 col-md-2 col-lg-2 animation">
              <Link to={"/term-insurance"} className="ins-type-box">
                <div className="arrow-hover">
                  
                  <FastImage
                      
                      url={"/Website%20Img/right-arrow.png?updatedAt=1718706342956"}
                      width={auto}
                      height={auto}
                      alt={"right-arrow"}
                      src={"/Website%20Img/right-arrow.png?updatedAt=1718706342956"}
                       loading="eager"
                      />
                </div>
                <div className="inner-sub-box">
                  <div className="icon-back-sure">
                   
                    <FastImage
                      
                      url={"/Website%20Img/term-insurance.png?updatedAt=1718709323751"}
                      width={auto}
                      height={auto}
                      alt={"term"}
                      src={"/Website%20Img/term-insurance.png?updatedAt=1718709323751"}
                       loading="eager"
                      />
                  </div>
                  <h4>Term</h4>
                  <h6>Insurance</h6>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>


      <ImportenceOfInsurence />
      <BeemaAdvantage/>
      <Stepper />
      <AdviceFromExpert />

      {/* <NoteWarthyAdvice /> */}
      {/* <Howbeemaone/> */}

   <Preferredpolicy />
    <PartnerCompany />
    <FAQ />
    <Testamonial/>
      <Homepagecalculator />
      <Blog />
    </div>
  );
};

export default Homepage;
