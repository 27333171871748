import React,{useState} from 'react';
import FAQ from "../AllPageComponents/FAQ/FAQ";
import PartnerCompany from "../AllPageComponents/PartnerCompany/PartnerCompany";
import "../CarInsurnce/Carinsurence.css";
import CarInsuranceForm from './CarInsuranceForm';
import FastImage from "../FastImage";
import { auto } from '@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core'
import Redirect from '../AllPageComponents/Rederict';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { FaCaretDown } from "react-icons/fa";





const AddonCover = () => {
  const initialText = "Add-ons in motor insurance are covers that can be used to reinforce the coverage of the existing policy. You can purchase this add-on with some extra premiums. Let’s discuss various types of Car Insurance Add-on covers in length and breadth:"
const [showFullText, setShowFullText] = useState(false);

const toggleShowFullText = () => {
  setShowFullText(!showFullText);
};

const QNA = [
{
  q:"Q. Why to buy a Zero Depreciation Car Insurance Plan?",
  a:"Expenditures belonging to voluntary deductibles and depreciation of the car parts are paid by the policy holder during any claim. Most importantly, a zero-dep cover helps in reducing the car insurance premiums. On the other hand, you will be eligible to claim the entire sum assured along with this add-on. Excluding the tubes and tiers, depreciation on the car parts are not deducted. If you are the owner of a brand-new car then you must enroll for a nil depreciation cover."

},
{
  q:"Q. What is the Insured Declared Value in a nil depreciation car insurance cover?",
  a:"A. IDV is equal to the sum assured value that the policyowner gets in the situation or circumstances of theft or total loss of the insured vehicle. It is the current market worth or valuation of the vehicle deducted from the vehicle registration cost and depreciation value of the vehicle parts.."

},
{
  q:"Q. Is there No Claim Bonus (NCB) in Zero Depreciation Car Insurance policy?",
  a:"A. No Claim Bonus is compiled to the insured’s account every policy year for not making any claim. No claim bonus is accrued year on year to the insured’s account with each passing claim-free year, depicted and clarified in the table below:"

},
{
  q:"Q. How one can confirm if his car is insured under zero-dep cover?",
  a:"A. You will come to know whether your car is a zero-dep one by checking your car insurance policy document. Every detail of your zero-dep cover will be mentioned in your policy paper as because it’s an add-on cover to your existing insurance."

},
{
  q:"Q. When can I purchase a Zero Depreciation add-on cover for my car?",
  a:"A. This Add-on is valid for cars only up to 5 years. Some insurance companies also provide Zero-dep to the cars till 5 years – 10 years as well."

},
{
  q:"Q. What are the elements or the components that are intricately connected with Zero-Dep car insurance?",
  a:"A. The number of years counted to determine the age of the car to be insured, is a major aspect. The geographical location, make and model of the car are also vital determining factors."

},
{
  q:"Q. What are the coverage limits of Zero Depreciation Add-on cover?",
  a:"A.A Zero Depreciation Add-on cover insures all inclusive cent percent depreciation expenses sustained by your car. This add-on facility does not cover mechanical, electrical default or negligence, replacement of tubes or tiers, damage of engine or any other disruption.  Driving under the influence of alcohol or drugs, or even driving without a valid driving license is also not covered."

},
{
  q:"Q. Is it possible to get a third-party car insurance policy be converted into a zero depreciation?",
  a:"A. Unfortunately, that’s not possible to convert a third-party car insurance policy into a zero – dep. But, this add-on can be availed only with a comprehensive car insurance policy"

},

]

const addonsqna = [
{
q:"Q. Is the cost of the AC Coolant covered?",
a:"The best way to confirm is to check with your insurance provider. Mostly the air-conditioned coolant is covered under this add-on."
},
{
  q:"Q. How do you avail a consumables add-on cover along with your car insurance policy ?",
  a:" Click on the website of Beema1 to avail the plan. However, this add-on cover can be purchased both online and offline. If you wish to buy online then do not forget to compare all the insurance coverage options available on the platform of Beema1."
  },
  {
    q:"Q. Who shall buy a consumables Car Insurance Add-on?",
    a:"Whoever wants to reduce the regular expense of minor repairs and replacement should opt for this optional. If a car is older than 5 years then this cover may not be available. "
    },
    {
      q:"Q. Will consumables cover reimburse for routine wear and tear?",
      a:"Routine repairs are not covered under this add-on. The cover is subject to depreciation and does not covers consequential damage. "
      },
      {
        q:"Q. Is this cover applicable for commercial vehicles?",
        a:"This cover can be availed for commercial vehicles such as tourist vehicles, cabs and cars etc."
        },
        {
          q:"Q. Roadside Assistance Add-On Cover ?",
          a:"Roadside Assistance (RSA) is an insurance coverage that offers necessary reassurance and relaxation to the owner of the vehicle, when the owner’s vehicle is defunct with any mechanical issues, automatic failure or certain mechanical glitches. Without the (RSA) cover, it can be astounding for the vehicle owner to be abandoned in unknown places without any access to a mechanic. Roadside Assistance add-on cover comes with the following basic features:"
          },
]

const RoadsideQna = [
  {
    q:'If a claim is raised based on Roadside Assistance Cover, will the no claim bonus get effected?',
    a:'Roadside Assistance Cover does not account for claiming. If you have not raised any claim under it then your NCB will not get effected.'
  },
  {
    q:'Is purchasing a 24*7 Roadside Assistance Cover beneficial?',
    a:'It is beneficial for those who frequently goes out on long drives to far of places. You can never anticipate about what’s going to happen next, your car might run out of fuel at some off-beat places and as a result your car breaks down. In such scenarios this add-on cover is useful.'
  },
  {
    q:'Whether this add-on cover the assistance in unlocking the car?',
    a:'If you lose your car keys or if you might forget your car keys in your car then this insurance cover will be handy for you.'
  },
  {
    q:'How to opt for a Roadside Assistance cover online?',
    a:'You can avail this cover by visiting the website of Beema1.com and clicking on the Roadside Assistance Cover.'
  },
  {
    q:'How to opt for a Roadside Assistance cover online?',
    a:'You can avail this cover by visiting the website of Beema1.com and clicking on the Roadside Assistance Cover.'
  },
]

const personalbelonging = [
  {
    "q": "What will happen if a person loses some of his personal belongings that’s kept inside the car overnight, will the insurance company provide insurance coverage in such a case?",
    "a": "The insurance provider will not provide insurance coverage for the lost items kept inside your car overnight."
  },
  {
    "q": "What if you miss to address a lost thing and it is not mentioned in the police report, will you get coverage for it?",
    "a": "You are not supposed to receive insurance coverage for any lost item that wasn’t mentioned in the police report. Claims can be raised only for those items for which a non-detectable report has been issued by the police."
  },
  {
    "q": "What are the items that fall into the category of Personal Belongings List?",
    "a": "The items included under the Personal Possessions Cover vary from insurer to insurer. To get a clear understanding, you will need to thoroughly read through the policy wordings regarding each and every add-on cover provided by your insurance provider in detail."
  },
  {
    "q": "Does the Personal Belongings Cover come with the Comprehensive Car Insurance or have to be availed separately?",
    "a": "The Personal Belongings Cover does not come with the Comprehensive Car Insurance. The policy owner needs to purchase this add-on cover separately by paying an extra premium towards their insurance policy."
  }
]

const ReplacementProtection = [
  {q:'Q. What are the advantages of Key-Protect Add-on?',a:'A – A key-protect add-on cover helps in having the wherewithal for the cost incurred in earning a replacement for your lost/damage key. This makes your life hassle-free as well.'},
  {q:'Q. What if your car key is stolen?',a:'A – If car keys get stolen then you should obviously file a FIR and inform the insurance company. After receiving an intimation from the car owner, the insurance company will provide you with a duplicate key or reimburse you for the sum of money incurred in acquiring a similar key.'},
  {q:"Q. Justify the worth of availing a key-protect add-on?",a:'A – Definitely you must avail this option for a key-protect add-on because it will surely help and assist you to get a replacement for your lost and stolen keys immediately in pursuing any expenditures for it.'},
  {q:'Q. Does key-protect add-on cover lock damage?',a:'A- Yes, lock damage is covered under Key-protect cover.'},
  {q:"Q. Is it possible to avail key-protect add-on with your third-party car insurance policy?",a:"A – Absolutely no. It is not possible to avail a key-protect add-on with your third-party key insurance. It is only possible to opt for this add-on along with a comprehensive car insurance policy or a standalone own damage plan."},
  {q:'Q. Can I raise a claim online in case my car key is lost or gets stolen?',a:"A – Yes, under such events you can undoubtedly raise a claim online."},
  {q:'Q. One who by obligation has to park his/her car outside the boundary of the house, will need a key-protect add-on mandatorily or not?',a:'A – One who parks the car outside the boundary of the house must avail a key-protect add-on because it might so happen that a burglar may try to break open the lock of the car and barge in and start the car by using unrivalled methods that can damage the lock-set of the car.'},
]

const Tyerprotection = [
  {
      q: "Will the costs incurred for tyre upgradation at the time of a claim be covered under tyre protection cover?",
      a: "The insurance provider shall replace the tyre with same or similar make and specifications or with similar techniques. But, if the same specifications are not available then they will compensate (pay off) for the loss up to the payable limit only."
  },
  {
      q: "Is the loss incurred because of the robbery of tyre covered under the tyre protection cover?",
      a: "Theft of tyre is not covered or compensated by any insurance company under the Tyre Protection Cover."
  },
  {
      q: "Is it practically possible to avail for a tyre protection cover with my third-party car insurance policy?",
      a: "No, it is not possible to opt for a tyre protection cover with a third-party car insurance policy. It can be availed only with standalone damage car insurance plan or may merged with a comprehensive car insurance policy."
  },
  {
      q: "Let’s say, that I have a comprehensive car insurance plan and while renewing it I want to club a tyre protection cover to my existing comprehensive policy. Will it be possible?",
      a: "Why not, you can indefinitely opt for the tyre protection add-on at the time of renewing your existing comprehensive car insurance policy."
  },
  {
      q: "How to avail a tyre protector cover for my car with my car insurance policy?",
      a: "An add-on for tyre protection cover can be purchased while purchasing a comprehensive car insurance policy. The policy can be availed through online or offline both. To choose the best tyre protection cover for your car, you must navigate through all the available insurance policy options on the digital platform of Beema1 before opting for one."
  },
  {
      q: "Will the tyre protection cover compensate for tyres making noise or vibrations?",
      a: "If a scraped or scuffed tyre in any consequence make vibrations or noise, but does not impact the general operations or functions of the car, then it won’t be covered by the car insurance policy under the tyre protection cover."
  },
  {
      q: "How to save your car tyres from slitting or ripping?",
      a: "Slitting or ripping is caused to your car under the circumstances of bad road conditions and for defacements or destructions. It is always wise to take preventive measures as under such scenarios your car will be compensated under the tyre protection cover. Following are the precautionary measures that you should follow:"
  }
];

const ReturnInvoice = [
  {
      q: "What is the difference between Return to Invoice and Zero Depreciation Cover?",
      a: "Both the covers Return to Invoice and Zero Depreciation Cover are additional insurance covers that cater to distinct needs. Return to Invoice cover add-on covers you in certain scenarios of total/complete loss or theft of the vehicle by compensating for the original rate of invoice. But, Zero Depreciation covers the difference between the real cost and the devalued price of car parts damaged in accidents, ensuring full coverage for repairs."
  },
  {
      q: "How is Return to Invoice Calculated?",
      a: "You pay the on-road price at the time of purchasing the car. On-road expenses include the ex-showroom price, road tax, and charges for registration which are based on the make and model of the car. Without an RTI cover, if your car is declared a total loss, you will receive coverage based on the insured declared value. With RTI cover, the insurance company compensates for the following values:\n\n# Registration Charges + Ex-Showroom Price + Road Tax, when you purchased the brand-new car.\n# Current Replacement Charges inclusive of Ex-Showroom Price + Registration Charges + Road Tax."
  },
  {
      q: "Who is advised to purchase the Return to Invoice add-on cover?",
      a: "People who have recently purchased a new car are advised to opt for the Return to Invoice add-on cover."
  },
  {
      q: "Does RTI Cover have various restrictions or limitations?",
      a: "RTI is provided when your car is stolen or is impaired or mutilated beyond repair."
  },
  {
      q: "Do car insurance companies provide a Return to Invoice add-on cover for cars older than 5 years?",
      a: "No insurance companies in India provide Return to Invoice add-on cover for cars older than 5 years."
  }
];

const DailyAddonCover = [
  {
      q: "How to avail a Daily Allowance Add-on cover for a commercial car?",
      a: "This is something you need to check with your respective insurance company. If they provide Daily Allowance Add-on cover, then you can opt for this option with your standard car insurance plan."
  },
  {
      q: "Any possibilities to purchase Daily Allowance add-on cover in the middle of the policy term?",
      a: "It depends on the respective insurance companies. Not all, but some of the insurance providers do have the option to provide Daily Allowance add-on cover in the middle of the policy period."
  },
  {
      q: "Is it possible to purchase Daily Allowance add-on cover for a commercial vehicle?",
      a: "The Daily Allowance Add-on cover can be bought for both private and commercial cars."
  },
  {
      q: "How can Daily Allowance add-on cover be purchased with a third-party car insurance?",
      a: "This add-on cover cannot be availed without an own-damage car insurance policy. If an individual purchases a comprehensive car insurance plan, this option can be opted for an additional premium. You can look for this option on the Beema1 website, where some of the best motor insurance plans can be compared for the right choice through the online platform within a quick span of time."
  },
  {
      q: "What is the maximum tenure of coverage period provided by the insurance provider under the Daily Allowance Add-on?",
      a: "Based on the type of damage, the extent and the depth of the repair needed may vary depending on the insurer."
  },
  {
      q: "If an individual purchases a daily allowance add-on cover, how much allowance is provided to that individual?",
      a: "Based on the policy term and features, these factors vary. Generally, Rs. 500 or less is allotted per day by most insurance companies. In scenarios of permanent damage or theft, this sum can be offered as a round sum based on the insurance company."
  },
  {
      q: "If your insured car had an accident 6 days ago, is it possible to make a claim in regards to that accident?",
      a: "The first and foremost task is to inform and update the insurance company about the accident at the earliest. A claim cannot be raised beyond a certain time period. Most insurers require the policyholder to initiate the claim within a maximum of 3 days of the damage."
  }
];

const EngineProctection = [
  {
      "q": "What is the eligibility of purchasing Engine Protection Add-on Cover?",
      "a": "A comprehensive car insurance policy holder can avail an Engine Protection Policy Cover. An extra payment on premium is all required to get this add-on cover."
  },
  {
      "q": "How and When an Engine Protection Cover can be availed of?",
      "a": "You can purchase this Add-on cover while purchasing a comprehensive car insurance policy or at the time of your policy renewal."
  },
  {
      "q": "What all are covered under the Engine Protection Add-on cover?",
      "a": "Engine Protection Cover provides coverage for leakage of the lubricant oil, gearbox malfunction, ingression of water etc."
  },
  {
      "q": "What are the common causes of car engine failure?",
      "a": "The most common causes for engine failure are\n# Water Ingression\n# Engine Overheating\n# Low-Level of Engine Oil"
  },
  {
      "q": "Does Your Car Insurance Policy Covers Car Leaks?",
      "a": "Yes, expenses against the damages are covered that happen due to the leakage of lubricating oil."
  }
]












const tableStyle = {
  width: '100%',
  borderCollapse: 'collapse',
  margin: '20px 0',
  fontSize: '16px',
  textAlign: 'left',
};

const thStyle = {
  backgroundColor: '#f2f2f2',
  padding: '12px',
  border: '1px solid #ddd',
};

const tdStyle = {
  padding: '12px',
  border: '1px solid #ddd',
};

const th = {
  border: '1px solid #ddd',
  padding: '8px',
  backgroundColor: '#3F6ABF',
  color:'white',
  textAlign: 'left',
};

const td = {
  border: '1px solid #ddd',
  padding: '8px',
};

    
  return (
    <>
    <Redirect/>
    <section className="car-insurance insurance-all insurance-background">
  <div className="container">
    <div className="row insurance-row-one">
      <div className="col-sm-12 col-md-4 col-lg-4 mian-one">
        <div className="car-insurance-first-inner">

          <h4 style={{color:"#0166B3",fontFamily:"Poppins",fontWeight:500,fontSize:"18pt"}}>Car Insurance With Top Add-on Covers For Extra Protection</h4>

          <span style={{ textAlign: 'justify' }}>
        {showFullText ? initialText : initialText.slice(0, 200)}
        {!showFullText && initialText.length > 200 && (
          <a style={{ color: '#2959B8', cursor: 'pointer' }} onClick={toggleShowFullText}>
            ... Show More
          </a>
        )}
        {showFullText && (
          <a style={{ color: '#2959B8', cursor: 'pointer' }} onClick={toggleShowFullText}>
            {' '}
            Show Less
          </a>
        )}
      </span>
        </div>
      </div>
      <div className="col-sm-12 col-md-4 col-lg-4 side-image">
      <FastImage
            url={"Website%20Img/carImagMascord.png?updatedAt=1718773961090"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            />
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html: "\n    .hide{\n        display: none;\n    }\n"
        }}
      />
      <CarInsuranceForm/>
    </div>
  </div>
</section>

<div className="container">
    <h5 style={{color:"#2959B8"}}>Benefits of car insurance Add-On Covers.
</h5>
    <div className="yellowline"></div>
    <br />
    <p>  <b>1. Zero Depreciation Add-On Cover or Bumper to Bumper Cover –</b>   
  A zero dep is an add-on cover under which the insurance company doesn’t impose the depreciation sustained by the insured car. With a Zero depreciation coverage, the policy holder doesn’t have to pay the depreciation value of the damaged or the reinstated parts of the car.
   A zero dep is such an Add-on cover under which the insurance provider doesn’t charge the depreciation sustained by the insured car. A policy holder can claim the total expenses of replacement of the car parts during an accidental damage under a nil depreciation add-on insurance cover.
    Hence, its quite cost saving. This is also applied and stand valid for the vehicles those are less than 5 years, and the policy owner can avail of the benefit to the core only twice during the year.
    If you have recently purchased a new car or comparably a new car then definitely it makes sense to opt for zero depreciation insurance or for the following reasons:
# Owners of luxury cars
# Driving where the probability of happening accidents are higher.
# If the spare parts of the car are expensive.
# If you want to reduce out -of pocket expenses
#New Driver</p>    
  </div>
<br />










<section className="full-space-block">
  <div className="container">
    <div className="row full-space-block-row">
      <div className="col-sm-12 col-md-12 col-lg-12">
        <div className="insurance-less-farage">
          <div className="simple-text-block">
           
            <section
              className="full-space-block"
              style={{
                marginBottom: 67,
                fontFamily:
                  '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
              }}
            >
              <div
                className="container"
                style={{
                  width: 1200,
                  padding: "0px 15px !important",
                  maxWidth: "1200px !important"
                }}
              >
                <div
                  className="row full-space-block-row"
                  style={{ marginRight: "-15px", marginLeft: "-15px" }}
                >
                  <div
                    className="col-sm-12 col-md-12 col-lg-12"
                    style={{ width: 1200, paddingRight: 15, paddingLeft: 15 }}
                  >
                    <div className="insurance-less-farage">
                      <div className="simple-text-block">
                    

                        <h4 style={{color:"#2959B8", lineHeight: 1.3,
                            fontSize: 26,
                            fontFamily:"Poppins",}}>Wish to opt for Zero Depreciation Add-on Cover ?
</h4>
<div className="yellowline"></div>
                        <p
                          style={{
                            fontFamily:"Poppins",
                            lineHeight: 1.3,
                            color: "rgb(20, 20, 20)"
                          }}
                        >
                       If you have bought a new car or you own a car that’s not much old than you must opt for a zero-dep car insurance for the following reasons:


<b># Owners of new cars:-</b> Be it a high-end car or a medium ranged car, once a new car is out of the showroom the value of the car starts declining. Hence, you should definitely go for a Zero Depreciation Add-On Cover for your premium car.
<br />
<b># New Drivers:-</b> If you are a new driver then chances of meeting up with accidents are much higher which will result into out of pocket expenses for repair and replacements of the parts of your vehicle. To get recompensed for the full repair amount, you can avail of a Zero Depreciation Add-on Cover policy.
<br />
<b># Driving through accident prone terrains:–</b> Driving in any accident-prone areas might lead you to more repair expenses.  If you don’t own a zero-dep cover in such situations, you might end up spending off a lot of money out of your pocket. Hence, you should purchase a zero Depreciation Add-on cover for your four-wheeler.
<b># Expensive car spare parts:–</b> There are many cars having expensive spare parts leading to high depreciation value. In case you are not ready to pay for this depreciated value then avail of a nil depreciation cover along with your car insurance policy.
                        </p>

<h6>What are the benefits of a Zero Dep policy for your car?</h6>
<p>The benefits of this Add-on cover are as follows:
<br />
# This add-on cover safeguards from financial loss and compensates the complete repair and replacements amount to the policyholder.
<br />
# This add-on cover helps in minimizing out of pocket expenses without deducting the devaluation on the parts of the car and hence it’s called a nil depreciation policy.
<br />
# This add-on cover is available at a trivial premium under a car insurance policy.</p>
<br />
<br />

<h4>How is Zero Depreciation Policy Is Planned with Purpose?</h4>
<p>
Following are the factors that determine the calculation of zero dep car insurance premiums-

<b># Place of registration –</b> The cost of the premium depends on the place where the insured lives. The cost of the premium is always higher in the metro cities like Bangalore, Mumbai, Chennai, Delhi, Kolkata, Pune and Ahmedabad as compared to tier B cities.
<br />
<b># Sum Insured or Insured’s Declared Value (IDV) –</b> It is the current market value of the insured car from which the devaluation is deducted while calculating the premium.
<br />
<b># Type of Engine – </b>Premium of vehicles with higher cubic capacity is relatively higher than those with an engine having lower cubic capacity.
<br />
<b># Initiation of Car Equipment – </b>Premium for car equipment and accessories and the further features attached along with it, are calculated individually.
<br />
<b># Types of Coverage – </b>The type of coverage is determined as per the rate of premium you pay. A Comprehensive Car Insurance is likely to have higher premiums as compared to a Third-Party Car Insurance.
<br />
<b># Vehicle Age Factor – </b>Vehicle is significantly considered while calculating the premium of a zero-depth car insurance.
<br />
<b># The Fuel Factor –</b> Diesel, petrol, CNG, or electric car, fuel is a primary factor to be considered while deciding on the Zero Depreciation Car Insurance premiums.
<br />
<b># Add-on Covers –</b> Add-ons like the Personal Belongings Cover, Zero Depreciation Cover, Roadside Assistance Cover etc. also determines the premium of the car insurance policy.
</p>
<br />
<br />
<h4>Zero Depreciation Car Insurance VS Comprehensive Coverage</h4>
<br />
<p>The below mentioned table explicitly explains the basic differences between a car insurance policy with a zero depreciation and a basic comprehensive car insurance policy:</p>
<br />
<br />
<table border="1" style={{ width: '100%', borderCollapse: 'collapse', textAlign: 'left' }}>
      <thead>
        <tr>
          <th style={{ padding: '8px', backgroundColor: '#f2f2f2' }}>FEATURES</th>
          <th style={{ padding: '8px', backgroundColor: '#f2f2f2' }}>ZERO DEPRECIATION</th>
          <th style={{ padding: '8px', backgroundColor: '#f2f2f2' }}>COMPREHENSIVE CAR INSURANCE</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={{ padding: '8px' }}>Premium</td>
          <td style={{ padding: '8px' }}>Higher</td>
          <td style={{ padding: '8px' }}>Lower</td>
        </tr>
        <tr>
          <td style={{ padding: '8px' }}>Claim Amount</td>
          <td style={{ padding: '8px' }}>
            Covers total loss or damage without deducting the depreciation
          </td>
          <td style={{ padding: '8px' }}>
            Depreciated value of the car parts is deducted from the IDV during Claim Settlement
          </td>
        </tr>
        <tr>
          <td style={{ padding: '8px' }}>Age factor of the car</td>
          <td style={{ padding: '8px' }}>Up to 10 years</td>
          <td style={{ padding: '8px' }}>Up to 15 years</td>
        </tr>
        <tr>
          <td style={{ padding: '8px' }}>Own Expense</td>
          <td style={{ padding: '8px' }}>
            Less burden on the policyholder at the time of claim
          </td>
          <td style={{ padding: '8px' }}>
            Policyholder has to pay the cost of depreciation nurtured by the insured car
          </td>
        </tr>
        <tr>
          <td style={{ padding: '8px' }}>Cost of plastic parts and repairing</td>
          <td style={{ padding: '8px' }}>Maximum Coverage</td>
          <td style={{ padding: '8px' }}>Comparatively less</td>
        </tr>
      </tbody>
    </table>

                        {/* <h6
                          style={{
                           
                            lineHeight: 1.3,
                            fontSize: 15,
                            fontFamily:"Poppins",
                            color: "rgb(20, 20, 20)"
                          }}
                        >
                          1. Zero Depreciation Add-on Cover
                        </h6>
                        <p
                          style={{
                            fontFamily:"Poppins",
                            lineHeight: 1.3,
                            color: "rgb(20, 20, 20)"
                          }}
                        >
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer too Lorem Ipsum is simply
                          dummy text of the printing and typesetting industry.
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer too
                        </p>
                        <h6
                          style={{
                           
                            lineHeight: 1.3,
                            fontSize: 15,
                            fontFamily:"Poppins",
                            color: "rgb(20, 20, 20)"
                          }}
                        >
                          2. Consumables Add-on Cover
                        </h6>
                        <p
                          style={{
                            fontFamily:"Poppins",
                            lineHeight: 1.3,
                            color: "rgb(20, 20, 20)"
                          }}
                        >
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer too Lorem Ipsum is simply
                          dummy text of the printing and typesetting industry.
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer too
                        </p>
                        <h6
                          style={{
                           
                            lineHeight: 1.3,
                            fontSize: 15,
                            fontFamily:"Poppins",
                            color: "rgb(20, 20, 20)"
                          }}
                        >
                          3. Roadside Assistance Add-on Cover
                        </h6>
                        <p
                          style={{
                            fontFamily:"Poppins",
                            lineHeight: 1.3,
                            color: "rgb(20, 20, 20)"
                          }}
                        >
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer too Lorem Ipsum is simply
                          dummy text of the printing and typesetting industry.
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer too
                        </p>
                        <h6
                          style={{
                           
                            lineHeight: 1.3,
                            fontSize: 15,
                            fontFamily:"Poppins",
                            color: "rgb(20, 20, 20)"
                          }}
                        >
                          4. Engine Protection Add-on Cover
                        </h6>
                        <p
                          style={{
                            fontFamily:"Poppins",
                            lineHeight: 1.3,
                            color: "rgb(20, 20, 20)"
                          }}
                        >
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer too Lorem Ipsum is simply
                          dummy text of the printing and typesetting industry.
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer too
                        </p>
                        <h6
                          style={{
                           
                            lineHeight: 1.3,
                            fontSize: 15,
                            fontFamily:"Poppins",
                            color: "rgb(20, 20, 20)"
                          }}
                        >
                          5. Key Loss Add-on Cover
                        </h6>
                        <p
                          style={{
                            fontFamily:"Poppins",
                            lineHeight: 1.3,
                            color: "rgb(20, 20, 20)"
                          }}
                        >
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer too Lorem Ipsum is simply
                          dummy text of the printing and typesetting industry.
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer too
                        </p>
                        <h6
                          style={{
                           
                            lineHeight: 1.3,
                            fontSize: 15,
                            fontFamily:"Poppins",
                            color: "rgb(20, 20, 20)"
                          }}
                        >
                          6. Passenger Assistance Add-on Cover
                        </h6>
                        <p
                          style={{
                            fontFamily:"Poppins",
                            lineHeight: 1.3,
                            color: "rgb(20, 20, 20)"
                          }}
                        >
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer too Lorem Ipsum is simply
                          dummy text of the printing and typesetting industry.
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer too
                        </p>
                        <h6
                          style={{
                           
                            lineHeight: 1.3,
                            fontSize: 15,
                            fontFamily:"Poppins",
                            color: "rgb(20, 20, 20)"
                          }}
                        >
                          7. Tyre Damage Add-on Cover
                        </h6>
                        <p
                          style={{
                            fontFamily:"Poppins",
                            lineHeight: 1.3,
                            color: "rgb(20, 20, 20)"
                          }}
                        >
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer too Lorem Ipsum is simply
                          dummy text of the printing and typesetting industry.
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer too
                        </p> */}
                      </div>
                      <div
                        className="simple-text-block mr-top"
                        style={{ marginTop: 35 }}
                      >
                  

                        <h5 style={{color:"#2959B8", lineHeight: 1.3,
                            fontSize: 26,
                            fontFamily:"Poppins",}}> Benefits of Add-on Covers in Car Insurance
</h5>
<div className="yellowline"></div>
                        <p
                          style={{
                            fontFamily:"Poppins",
                            lineHeight: 1.3,
                            color: "rgb(20, 20, 20)"
                          }}
                        >
                          The add-on covers for car insurance brings several
                          benefits:
                        </p>
                        <h6
                          style={{
                           
                            lineHeight: 1.3,
                            fontSize: 15,
                            fontFamily:"Poppins",
                            color: "rgb(20, 20, 20)"
                          }}
                        >
                          1. Cover Car’s Depreciation
                        </h6>
                        <p
                          style={{
                            fontFamily:"Poppins",
                            lineHeight: 1.3,
                            color: "rgb(20, 20, 20)"
                          }}
                        >
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer too Lorem Ipsum is simply
                          dummy text of the printing and typesetting industry.
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer too
                        </p>
                        <h6
                          style={{
                           
                            lineHeight: 1.3,
                            fontSize: 15,
                            fontFamily:"Poppins",
                            color: "rgb(20, 20, 20)"
                          }}
                        >
                          2. Get Emergency Assistance
                        </h6>
                        <p
                          style={{
                            fontFamily:"Poppins",
                            lineHeight: 1.3,
                            color: "rgb(20, 20, 20)"
                          }}
                        >
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer too Lorem Ipsum is simply
                          dummy text of the printing and typesetting industry.
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer too
                        </p>
                        <h6
                          style={{
                           
                            lineHeight: 1.3,
                            fontSize: 15,
                            fontFamily:"Poppins",
                            color: "rgb(20, 20, 20)"
                          }}
                        >
                          3. Provides Engine Protection
                        </h6>
                        <p
                          style={{
                            fontFamily:"Poppins",
                            lineHeight: 1.3,
                            color: "rgb(20, 20, 20)"
                          }}
                        >
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer too Lorem Ipsum is simply
                          dummy text of the printing and typesetting industry.
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          too&nbsp;
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div className="container">
  <h5 className='healthHeading'>What are the benefits of a Zero Dep policy for your car? </h5>
  <div className="yellowline"></div>
  <br />
  <span># This add-on cover safeguards from financial loss and compensates the complete repair and replacements amount to the policyholder.
</span>
<br />
<span># This add-on cover helps in minimizing out of pocket expenses without deducting the devaluation on the parts of the car and hence it’s called a nil depreciation policy</span>
<br />
<span># This add-on cover is available at a trivial premium under a car insurance policy.</span>
<br />
<br />

<h5 className='healthHeading'>How is Zero Depreciation Policy Is Planned with Purpose?</h5>
<div className="yellowline"></div>
<br />
<span># Place of registration – The cost of the premium depends on the place where the insured lives. The cost of the premium is always higher in the metro cities like Bangalore, Mumbai, Chennai, Delhi, Kolkata, Pune and Ahmedabad as compared to tier B cities.</span>
<br />
<br />
<span># Sum Insured or Insured’s Declared Value (IDV) – It is the current market value of the insured car from which the devaluation is deducted while calculating the premium.</span>
<br />
<br />
<span># Type of Engine – Premium of vehicles with higher cubic capacity is relatively higher than those with an engine having lower cubic capacity.</span>
<br />
<br />
<span># Initiation of Car Equipment – Premium for car equipment and accessories and the further features attached along with it, are calculated individually.</span>
<br />
<br />
<span># Types of Coverage – The type of coverage is determined as per the rate of premium you pay. A Comprehensive Car Insurance is likely to have higher premiums as compared to a Third-Party Car Insurance.</span>
<br />
<br />
<span># Vehicle Age Factor – Vehicle is significantly considered while calculating the premium of a zero-depth car insurance</span>
<br />
<br />
<span># The Fuel Factor – Diesel, petrol, CNG, or electric car, fuel is a primary factor to be considered while deciding on the Zero Depreciation Car Insurance premiums.</span>
<br />
<br />
<span>
# Add-on Covers – Add-ons like the Personal Belongings Cover, Zero Depreciation Cover, Roadside Assistance Cover etc. also determines the premium of the car insurance policy.
</span>

<br />
<br />

<h5 className='healthHeading'>Zero Depreciation Car Insurance VS Comprehensive Coverage</h5>
<div className="yellowline"></div>
<br />
<br />
<li>
<span>The below mentioned table explicitly explains the basic differences between a car insurance policy with a zero depreciation and a basic comprehensive car insurance policy:
</span>
</li>
<br />
<table style={tableStyle}>
      <thead>
        <tr>
          <th style={th}>FEATURES</th>
          <th style={th}>ZERO DEPRECIATION</th>
          <th style={th}>COMPREHENSIVE CAR INSURANCE</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={td}>Premium</td>
          <td style={td}>Higher</td>
          <td style={td}>Lower</td>
        </tr>
        <tr>
          <td style={td}>Claim Amount</td>
          <td style={td}>Depreciation is not deducted from the IDV during claim settlement</td>
          <td style={td}>Depreciated value of car parts is deducted from the IDV during claim settlement</td>
        </tr>
        <tr>
          <td style={td}>Age Factor of the Car</td>
          <td style={td}>Up to 10 years</td>
          <td style={td}>Up to 15 years</td>
        </tr>
        <tr>
          <td style={td}>Own Expense</td>
          <td style={td}>Less burden on the policyholder at the time of claim</td>
          <td style={td}>Policyholder has to pay the cost of depreciation of the insured car</td>
        </tr>
        <tr>
          <td style={td}>Cost of Plastic Parts and Repairing</td>
          <td style={td}>Maximum Coverage</td>
          <td style={td}>Comparatively Less Coverage</td>
        </tr>
      </tbody>
    </table>


<div className="container">
  <h5 className='healthHeading'>Number of Zero Depreciation Claims According To Various Insurance Providers</h5>
  <div className="yellowline"></div>
  <br />
  <table style={tableStyle}>
      <thead>
        <tr>
          <th style={th}>Name of Insurance Providers</th>
          <th style={th}>Vehicle’s Age in Years</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={tdStyle}>Bajaj Allianz Car Insurance</td>
          <td style={tdStyle}>Up to 7</td>
        </tr>
        <tr>
          <td style={tdStyle}>Go Digit Car Insurance</td>
          <td style={tdStyle}>Up to 10</td>
        </tr>
        <tr>
          <td style={tdStyle}>HDFC Car Insurance</td>
          <td style={tdStyle}>Up to 10</td>
        </tr>
        <tr>
          <td style={tdStyle}>ICICI Lombard Car Insurance</td>
          <td style={tdStyle}>Up to 8</td>
        </tr>
        <tr>
          <td style={tdStyle}>Tata AIG Car Insurance</td>
          <td style={tdStyle}>Up to 8</td>
        </tr>
        <tr>
          <td style={tdStyle}>Kotak Mahindra Car Insurance</td>
          <td style={tdStyle}>Up to 6</td>
        </tr>
        <tr>
          <td style={tdStyle}>National Car Insurance</td>
          <td style={tdStyle}>Up to 10</td>
        </tr>
        <tr>
          <td style={tdStyle}>New India Car Insurance</td>
          <td style={tdStyle}>Up to 5</td>
        </tr>
        <tr>
          <td style={tdStyle}>Reliance Car Insurance</td>
          <td style={tdStyle}>Up to 10</td>
        </tr>
        <tr>
          <td style={tdStyle}>Royal Sundaram Car Insurance</td>
          <td style={tdStyle}>Up to 10</td>
        </tr>
        <tr>
          <td style={tdStyle}>SBI Car Insurance</td>
          <td style={tdStyle}>Up to 5</td>
        </tr>
        <tr>
          <td style={tdStyle}>Zuno Car Insurance</td>
          <td style={tdStyle}>Up to 8</td>
        </tr>
      </tbody>
    </table>
</div>

<br />
<br />
<div className="conatiner">
  <h5 className='healthHeading'>Rate of Depreciation in Car Insurance</h5>
  <div className="yellowline"></div>
  <br />
  <span>The current market value of the car is determined by the (IDV) Insured Declared Value. It is hugely impacted by the depreciation sustained by the insured car. This value is the ultimate value considered as Sum insured payed by the insurance company, depreciation sustained by the insured car. In situations where the insured undergoes total loss or theft, the IDV is provided. </span>
  <br />
  <br />
  <span>Rates of Reduction or Deduction Applicative on The Replaced Parts</span>

  <br />
  <br />
  <p>
  # 30% deduction on fiberglass elements
  <br />
# Deductions are not applicable on parts made of glass
<br />
# 50% of deduction is applicable on nylon, rubber or plastic airbags, tubes, tires, tubes and batteries

  </p>
</div>





</div>


<br />
<br />

<div className="container">
<h5 className='healthHeading'>VEHICLE AGE DEPRECIATION RATE TO ADJUST WITH THE (IDV) IN %age</h5>
<div className="yellowline"></div>
<br />
<table style={tableStyle}>
      <thead>
        <tr>
          <th style={th}>Age Range</th>
          <th style={th}>Value</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={tdStyle}>Less than 6 months</td>
          <td style={tdStyle}>5</td>
        </tr>
        <tr>
          <td style={tdStyle}>B/w 6 months and 1 year</td>
          <td style={tdStyle}>15</td>
        </tr>
        <tr>
          <td style={tdStyle}>B/w 1 and 2 years</td>
          <td style={tdStyle}>20</td>
        </tr>
        <tr>
          <td style={tdStyle}>B/w 2 and 3 years</td>
          <td style={tdStyle}>30</td>
        </tr>
        <tr>
          <td style={tdStyle}>B/w 3 and 4 years</td>
          <td style={tdStyle}>40</td>
        </tr>
        <tr>
          <td style={tdStyle}>B/w 4 and 5 years</td>
          <td style={tdStyle}>50</td>
        </tr>
      </tbody>
    </table>
    <br />
    <br />
    <li>
      <span>If you don’t avail the zero-dep cover than this depreciation value will be deducted from the reparation amount.
</span>
      </li>

      <br />
      <br />
      <li>#. Visit the website of Beema1.com</li>
      <br />
      <li>#. Visit the Car Insurance page from the homepage of Beema1</li>
      <br />
      <li># Enter your vehicle number online in the given form.</li>
      <br />
      <li># Select the mandatory fields with details that need to be filled in</li>
      <br />
      <li># Select the details like car model, make fuel type, variant, RTO and registration year</li>
      <br />
      <li># Compare all the plans available on the website of Beema1</li>
      <br />
      <li># Select the most suitable one that you consider to be the appropriate for your car</li>
      <br />
      <li># Select ‘Zero Depreciation’ cover for your policy from the Addons and Accessories from the dropdown list.</li>
      <br />
      <li># Your premium will increase once you add the Add-on cover to your insurance plan.</li>
      <br />
      <li># Pay your final premium amount via any payment mode of your choice.</li>
      <br />
      <li># After you make the payment for towards your car insurance plan, your zero-depreciation car insurance policy will generate</li>
      <br />
      <li># The soft copy of your policy document will be sent to you at your email address.</li>
      <br />
      <br />
      <li># Keep a reminder on your phone or on your calendar to track your car insurance renewal date. The insurance provider or the insurance web aggregator generally sends a reminder email at your registered email address regarding your insurance policy, or may alert you through a phone call. A record for the same is advised to keep for the renewal of the policy before the due date. 
</li>
<br />
<br />
<li># The complete details of the policy need to be entered at the time of renewing the zero-dep car insurance policy online. The online process of renewing your car insurance policy has made life easier with no paper work, no running around helter-skelter for renewing your existing car insurance policy or even if you apply for a new one. Click on the website of Beema1 and get everything done online. You just need to select the existing or a new customer option to begin the process, as mentioned. </li>
<br />
<br />
<li># Submit all the necessary mandatory documents by following the instructions, fill in the form and click on the payment option and make the payment.</li>
<br />
<li># Payment to the insurance company online using your debit or credit card. </li>
<br />
<li># Renewal of Policy – The insurance provider will notify the insured with a prior intimation regarding the zero - depreciation car insurance renewal on your registered email address and registered email address.</li>
</div>
<br />




<div className="container">
<h5 className='healthHeading'>Claim Settlement with Zero-Depreciation Car Insurance:</h5>
<div className="yellowline"></div>
<br />
<li>
During a claim settlement, the insurance company, the depreciation applied on the car parts is clearly defined in the policy wordings. Any which ways, when you claim a zero-depreciation car insurance, you are not supposed to pay anything g to the insurer. Hence, you will receive the full claim without reducing the depreciation sustained by your car. In case of the regular insurance car insurance plan without car zero-dep cover. The insurance provider only compensates the loss after the deduction of the depreciation value of the replaced parts, unlike a nil depreciation car insurance policy.

</li>
<br />

<h5 className='healthHeading'>Why should you trust Beema1 to purchase a Zero-Dep car insurance policy?</h5>
<div className="yellowline"></div>
<br />
<li># 24*7 Claims Support – A 24*7 claim assistance and guidance team is available to cooperate with you. Availability of a commentative team to advice towards Zero-Dep car insurance, for notifying about claims and status update.</li>
<br />
<li># Cashless Cushion – A Cashless Assurance provides you cashless facilities regarding repairs of damages at any garage across India. For example, if in a certain scenario the cashless aid has not been provided to you, the claim reimbursement will be paid off within 24hrs – 48hrs (working hours) post submission of your valid original documents and original invoices.
</li>
<br />
<li># Ensures Repairing in 3 days - Your vehicle will be repaired in some trustworthy garage and will be dispatched back within 3 working days.</li>
<br />
<li># Pick-up and Drop for Free – Give a call when in need of a claim, hence, and your garage partner shall pick you up or drop your car within three working hours.</li>
<br />
<li># Video Recording based Claims – The claim settlement process is even smoother and hassle-free. Just upload a video of your damage through our claim’s office.</li>
<br />
<li>#Windshield Replacement Claims – Contact us when there’s a windshield replacement game and the garage partner will replace the wind shield at your home itself.</li>
<br />

</div>

<div className="container">
      <h4 className='healthHeading'>Questions & Answers About the Facts & Figures of Zero Depreciation Car Insurance:</h4>
      <div className="yellowline"></div>
      <br />
      <br />
      {QNA.map((data, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<FaCaretDown />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
          >
            <Typography>{data.q}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {data.a}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>


    <div className="container">
      <br />
      <h5 className='healthHeading' >Usefulness of Consumable Add-On Cover:</h5>
      <div className="yellowline"></div>
      <span>
      Consumables are those equipment or hardware stuffs that get implemented or used in your car every time your car gets serviced. The consumables diminish with due course of time and need to be replaced with new one because of constant usage which leads to damage and loss. These include fuel-filters, nuts-bolts, screws, grease and lubricating oil, air-conditioner and gas, and so on. By paying an additional 
amount one can avail this Add-on if he/she wishes to avail this cover, because it has been already discussed earlier that consumables are not covered under a standard comprehensive car insurance policy.
      </span>
      <br />
      <br />
      <h5 className='healthHeading' >What is covered under Consumable Add-on Cover? </h5>
      <div className="yellowline"></div>
      <span>The cost of the consumables can accumulate up to a huge amount. Consumables cover in a car insurance policy is much pocket friendly or you can say economical. Consumable cover in a car insurance insures the following components:</span>
      <br />
      <br />
  <li>#Engine and Gearbox Oil</li>
  <li>#Grease and Lubricants</li>
  <li>#Nuts-Bolts</li>
  <li>#AC Gas Oil</li>
  <li>#AC Gas</li>
  <li>#Power Steering Oil</li>
  <li>#Radiator Coolant</li>
  <li>#Washers</li>
  <li>#Oil Filters, etc.</li>

  <br />
  <br />
  <h5 className='healthHeading'>Certain things to remember and to be noted while purchasing Add-on cover:</h5>
  <div className="yellowline"></div>
  <br />
  <br />

  <li>#This add-on cover can be availed for a vehicle that is less than 5 years old.</li>
  <li>#This add-on cover is not available under any Third-Party Car Insurance Policy.</li>
  <li>#The consumables used due to the mechanical or electronic breakdown of the vehicle cannot be claimed.</li>
  <li>#Delayed information to the insurance provider regarding any damage or loss to the car can deny the coverage under this add-on insurance policy.</li>
  <li>#Damages to the car that occurred due to driving under the influence of alcohol and drugs are considered to be penalized, hence the claim gets declined or rejected in the first place under this cover.</li>
  <li>#Driving the insured person’s car without a valid driving license.</li>
  <li>#A personal vehicle used for any commercial purpose will have its claim rejected immediately if a damage occurs and a claim is raised.</li>
  <li>#The coverage level of a consumable cover may vary from make and model of one to another.</li>
  <li>#If a car meets with an accident, the consumables must be repaired within 3 days to get a claim accepted. The insurance provider has a high chance of rejecting the claim if the repair is not done within the pre-fixed time frame.</li>

  </div>

<br />
<br />
<div className="container">
  <h5 className='healthHeading'>The Claim Process for Consumables Cover:</h5>
  <div className="yellowline"></div>
  <br />
  <li>For raising a claim against Consumable Add-on cover, the policy holder needs to inform their insurance provider regarding the accident or damage at the earliest. Two commonly known claim types, The Cashless Claim and The Reimbursement Claim can be raised. </li>
  <br />
  <br />
  <li># Cashless Claim – The car has to be taken to a network garage. Once the car is repaired, the car bill is sent to the insurance company for settlement and the bill is directly paid to the network garage by the insurance provider.</li>
  <br />
  <li># Reimbursement Claim – Here, in this case, the car can be repaired at any non-network garage. The car owner makes the payment for repairing directly to the garage and then sends the bill to the insurance company, the insurance company shall verify with the garage and shall reimburse the amount paid by the car owner to the non-network garage for the purpose of repairing. </li>
  <br />
</div>
<br />
<br />
<div className="container">
  <h5 className='healthHeading'>Q/A Section Based on All the Queries Regarding Consumables Add-on Cover:</h5>
  <div className="yellowline"></div>
  <br />
  <br />
  {addonsqna.map((data, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<FaCaretDown />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
          >
            <Typography>{data.q}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {data.a}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
</div>
<br />
<br />
<div className="container">
  <h5 className='healthHeading'>What are the inclusions?</h5>
  <div className="yellowline"></div>
  <br />
  <br />

  <li>#Minor Repairing – The insurance company sends a mechanic or mechanics to check and verify the trivial faults or breakdowns faced by the insured.</li>
  <li>#Breakdown of the Car Battery – This add-on cover provides for the car breakdown and, circumstantially, when the car needs stimulation, specifically when the car needs to be jump-started.</li>
  <li>#Towing – In certain scenarios, when your car breaks down, you need to tow it to the nearest garage or workshop. This facility is also provided under the add-on insurance cover of Roadside Assistance.</li>
  <li>#A Flat Tyre – Availing of this add-on insurance cover should be treated as having common sense, right? As we all are aware how often we face this issue while on the roads. A phone call to the insurer is your assurance that help and assistance come your way.</li>
  <li>#Requirement of Fuel – Suppose you have embarked on a long route journey and your fuel tank runs empty midway through your journey. The insurance provider shall send fuel supply to you at your location so that you do not need to run helter-skelter to look around for fuel stations which may not be available for miles.</li>
  <li>#Requirement of Spare Keys – Under this add-on insurance cover, a spare key is provided to resolve hassles. At times, we forget our car key inside the car and leave the car locked behind us, or misplace or lose the car key somewhere.</li>


</div>

<br />
<div className="container">
  <h5 className='healthHeading'>What are the exclusions?</h5>
  <div className="yellowline"></div>
  <br />
  <br />

  <li>#A Fallback Option – An insured is capable of utilizing roadside assistance only when there is no other alternative. If the insured manages to take the car to a nearby garage without third-party help and still intends to utilize the coverage facility, the insurer shall not guide or assist in such cases.</li>
  <li>#Not as a Claim – The Roadside Assistance cover does not count as a “claim” as the insured can use it a specific number of times according to the policy documents.</li>
  <li>#If Found Drunk – Drink and drive is excluded under the roadside assistance add-on insurance cover.</li>
  <li>#Without a Driving License – If a driver is caught driving without a driving license at the time of breakdown, the insurer will not cover it.</li>


</div>
<br />
<br />
<div className="container">
  <h5 className='healthHeading'>Who and When Can You Decide To Buy A Roadside Assistance cover?</h5>
  <div className="yellowline"></div>
  <br />


  <li>#If you own a car that’s less than 5 years old, then you are advised to opt for a Roadside Assistance Add-on Cover.</li>
  <li>#A person who travels quite often, whether by passion or profession, must opt for this add-on insurance cover.</li>
  <li>#If someone drives their car on a frequent basis and covers long distances, this cover is highly recommended.</li>
  <li>#If you know little or nothing about repairing a car, you should avail of this insurance cover mandatorily.</li>
  <li>#Under the following situations and circumstances can you opt for a Roadside Assistance Add-on Insurance Cover – While availing of car insurance for your car, you can add on this cover to your insurance policy, and it would start reflecting along with your policy.</li>
  <li>#The insured can add on this cover while renewing the existing car insurance policy. Visit the website of Beema1.com and complete the process by adding a Roadside Assistance Cover to your existing car insurance plan.</li>

</div>
<br />
<br />
<div className="container">
  <h5 className='healthHeading'>Provisions Associated to the Use and Application of Roadside Assistance Add-on</h5>
  <div className="yellowline"></div>

  <span>This point should be emphasized that the costs for opting to avail all the services are spend from your pocket and hardly the insurance provider facilitates the availability.

Let’s take a random example, if you run out of fuel on your midway while driving to a far distance destination, fuel will be supplied to you but you will have to bear the expense of the fuel. In case of towing, the garage and the mechanical servicing related expenses are borne by the policy holder. However, apart from the charges involved, having a roadside assistance cover is beneficial and worthy because it reduces the hassles involved with the availability of services. Any which ways, you will have to bear the costs of the fuel used and the expense related to the mechanism yourself even without the cover but the cover will assure swift service at your location. </span>
</div>
<br />
<div className="container">
  <h5 className='healthHeading'>Questions and Answers Associated with Roadside Assistance Add-on.</h5>
  <div className="yellowline"></div>
  <br />

  {RoadsideQna.map((data, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<FaCaretDown />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
          >
            <Typography>{data.q}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {data.a}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
</div>
<br />
<br />
<div className="container">
  <h4 className='healthHeading'>Personal Belongings Cover</h4>
  <div className="yellowline"></div>
  <br />
  <span>Personal Possessions Cover is an Add-on car insurance cover reimburses for any kind of damage or loss of the personal possessions kept inside the car at the time of parking at any licensed and recognized parking. As because this one is an Add-on cover you need to pay an extra titular premium amount to get coverage for your personal possessions. Personal Possessions Cover is a car insurance add-on cover that only a comprehensive car insurance policy holder can purchase. The insurance provider will provide coverage for any kind of loss or damage to any of your personal possessions kept inside the car as per the insured proclaimed value of your car insurance policy. Just for an example, your car got purloined from your parking and inside the car there lies your personal possessions like your high-end smart phone, a branded hand-bag, your wallet filled with hard cash and your office laptop. In such scenarios, availing of the Personal Belonging Cover will compensate you for the mislaying or misplacement of your belongings as per the IDV of your car. If you don’t buy this cover then only the theft will be covered as per your standard comprehensive car insurance policy.</span>
  <br />
  <br />

  <li>a. Claims under hired or borrowed property are not covered.</li>
  <li>b. Any incident that is the consequence of any policy holder’s laxity.</li>
  <li>c. Theft of any important possessions is excluded.</li>
  <li>d. Personal belongings that are kept locked inside a safety deposit box are also excluded from Personal Belongings Coverage.</li>
  <li>e. Personal possessions that the policy holders don’t carry around.</li>
  <li>f. Personal belongings that were used by a third-party.</li>
  <li>g. Personal belongings that were left inside the car overnight.</li>
  <li>h. Personal belongings that are cited and quoted in the police reports.</li>
  <li>i. The Personal Possessions Cover will not stand valid if the incident is not informed to the police within 24 hours of the theft.</li>
  <li>j. Casualty or damage due to climatic conditions, wear & tear, electrical failure, or mechanical failure.</li>
  <li>k. Personal belongings that were used by a third-party.</li>
  <li>l. Loss of items due to a car accident is not covered under the Loss of Personal Belongings Cover.</li>
</div>
<br />
<br />
<div className="container">
  <h5 style={{color:"#2959B8"}}>Process for raising a claim under Personal Belongings Cover – The policyholder will have to follow the below mentioned steps to raise a claim under a Loss of Personal Belonging Cover:</h5>
  <br />

  <li>#Inform the car insurance company within 24 hours of discovering the theft.</li>
  <li>#You need to provide your personal details, including your name and contact details, policy number, area of the incident, date, and time of theft.</li>
  <li>#A police file report and its copy are mandatory.</li>
  <li>#To file a fruitful car theft claim, you need to follow the process listed below. All the information needs to be provided within a definite time:</li>
  <li>#File an FIR with the police – This is an essential precondition. As soon as you are informed about the theft, you must report the incident at the closest police station from your residence. Without an FIR, your car theft claim request will not be accepted for processing.</li>
  <li>#Call Your Motor Insurer – Once you file your FIR report, you need to contact your insurer immediately and inform them about your car theft. Every insurance provider follows their own process and has its own way of dealing with a car theft claim, with the condition that there should not be any delay from your end.</li>
  <li>#The Transport Office Needs to Be Intimated – It is important and compulsory as per the law to intimate the Regional Transport Office about your car theft. The documents and papers need to be transferred by them, so ensure to complete the process.</li>
  <li>#Arrange All Your Insurance Documents – You need to arrange all the important documents to corroborate and substantiate a claim. Once you have gathered all your documents, validate the claim. After assembling all your documents in one place, complete the car theft claim form. You can obtain the form from any insurance company or download it online. Then, submit all the requisite documents.</li>


</div>
<br />
<br />
<div className="container">
<h5 style={{color:"#2959B8"}}>Documents required for personal belongings claim settlement process are as follows:</h5>
<br />

  <li>a. Claim form correctly signed and filled</li>
  <li>b. Copy of the FIR report</li>
  <li>c. Receipts and invoices as needed</li>
  <li>d. Police investigation report</li>


</div>
<br />
<br />
<div className="container">
  <h5 style={{color:"#2959B8"}}>Why should you purchase insurance plans through Beema1?</h5>
  <br />

  <span><b># Round-the-clock Claim Assistance – </b>People can receive 24*7 customer care support by connecting with our customer care support executives.</span><br/>

<span><b># Pick Up & Drop is Free – </b>When your claim is being processed or during your claim process, free pick up and drop services are provided absolutely with zero expense.</span>
<br/>

<span><b># An Affirmation of 3-day Repair – </b>The insured car will be fixed-up at a trusted network garage and will be delivered back to you within 3 working days.</span><br/>

<span><b># Self-Video Claims – </b>Raising a car insurance claim can be made conveniently from the place of accident through the PB Claims App. It’s a hassle-free process where you just need to upload a video of your car and get your claim settled in a simplistic manner.</span><br/>

<span><b># Windshield Claims at Home – </b>By contacting and summoning the claim executives, you can get your car’s windshield refurbished and repaired at home.</span>


</div>
<br />
<br />
<div className="container">
<h5 style={{color:"#2959B8"}}>Q/A Associated with Personal Belongings Cover</h5>
<div className="yellowline"></div>
  <br />
  {personalbelonging.map((data, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<FaCaretDown />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
          >
            <Typography>{data.q}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {data.a}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
      <br />
      <br />
      <h5 style={{color:"#2959B8"}}>Lock & Key Replacement Add-On</h5>
      <div className="yellowline"></div>
      <span>The insurance company separately provides this add-on cover on the comprehensive car insurance policy. This cover can be purchased only if the insured is covered under a comprehensive car insurance policy. An extra sum has to be added to the premium amount to avail this add-on cover as getting a key replaced becomes quite expensive in today’s technologically advanced era. The key replacement cover is an add-on insurance cover which you can avail with both a comprehensive car insurance policy and a standalone own damage policy. The Key Replacement Cover is a benefit and a facility which covers for the cost of replacement of the insured car’s key if the key is lost, misplaced, damaged, or stolen. The lost key add-on also provides compensation for the cost sustained in re-fixing the lock-set if the car lock is damaged.</span>
      <br />
      <br />
      <h5 style={{color:"#2959B8"}}>Options Covered Under Key Protection Add-on</h5>
      <div className="yellowline"></div>
      <span>Coverage for the following is provided under a key protection add-on:</span>
      <br />
      <br />
      <li>Lost key</li>
      <li>Damaged key</li>
      <br />
      <h5 style={{color:"#2959B8"}}>What Are the Plans with Which Key Protection Add-on is Available?</h5>
      <div className="yellowline"></div>
      <span>A key protection add-on cover is available to opt with a comprehensive car insurance plan and with a standalone own-damage car insurance plan.</span>
      <br />
    
  <li>Smart Car Key: Smart car keys are the most high-tech keys for cars. A smart car key automatically unlocks the door of the car without any bodily contact between the car and the smart key.</li>
  <li>Remote Car Key: A Remote Car key has a few security features. A remote key has a transceiver which is useful in locking/unlocking the car. This is proportionately higher-priced than a traditional car key.</li>
  <li>Traditional Car Key: A Traditional Car Key is the most basic, made of steel without any security features.</li>
  <li>This can be easily duplicated by a locksmith.</li>

<br />
<br />
<h5 style={{color:"#2959B8"}}>Benefits of Key Replacement Add-On Cover</h5>
<div className="yellowline"></div>
<br />

  <li>This add-on cover helps the policyholder with the replacement of the car key/lockset or lock that was stolen or damaged.</li>
  <li>It aids the policyholder in recovering or reinstating the key if there is any incident of burglary or theft, and replacement of the lockset in case of damage or theft.</li>
  <li>Qualification for the No Claim Bonus of the policyholder will not be impacted while renewing the policy.</li>
  <li>This policy comes with an upper limit on the number of times the policy can be claimed. Hence, one needs to be cautious and careful regarding handling the car keys, as the policy will insure up to the acceptable number of claims.</li>
  <li>This follows the normal claim raising and claim settlement process just like a regular car insurance claim, starting from notifying and updating the insurance provider to the final settlement.</li>
<br />
<br />
<h5 style={{color:"#2959B8"}}>Know the Cost of Car key Replacement Add-on</h5>
<br />
<span>The premium that is paid against Key replacement add-on cover is lesser and minor in contrast to the actual incurred cost that is required to change and replace the damaged lockset. While the keyless FOB and remote keys are a bit expensive in regards to substitution. Paying some amount for purchasing a key replacement add-on cover along with a comprehensive car insurance plan, that will save you in the future from paying a higher replacement cost for your key in future. However, the amount that you would receive in the form of insurance coverage is dependent upon the maximum sum insured that you have opted for.</span>
<br />
<br />
<h5 style={{color:"#2959B8"}}>Who Are Eligible to Buy Key Replacement Car Insurance In India?</h5>
<div className="yellowline"></div>
<br />

  <li>#The possibilities of theft or chances of attempted theft are high, and that’s why parking areas are considered unsafe.</li>
  <li>#The repairing or replacement cost for the keys of luxurious cars, and even mid-range cars, can be expensive. Key protection cover acts as a cushion that covers the replacement cost.</li>
  <li>#Cars with locks operated by a remote key or advanced technology should consider opting for this cover.</li>
<br />
<br />
<h5 style={{color:"#2959B8"}}>Key Replacement Cover Premium</h5>
<div className="yellowline"></div>
<span>The premium charged by an insurance provider for this cover is minimal as it is an add-on cover. The percentage varies between 5%-8% of the total car insurance premium. Key Replacement Car Insurance Premium may vary depending on the model of the car or insurance provider.</span>
</div>
<br />
<br />
<div className="container">
  <h5 className="healthHeading">What Is the Process To Claim Key Replacement Cover?</h5>
  <div className="yellowline"></div>

   <ul>
  <li>#Register or enroll your claim either through a phone call to the insurance company, or by directly writing an email to them.</li>
  <li>#A police FIR is mandatory and essential to register a claim in case of theft.</li>
  <li>#Get your car keys repaired or replaced by raising a claim asking for reparation.</li>
  <li>#The insurance company will verify, confirm, and approve the claim based on the required valid documents and shall settle the claim by reimbursing the costs.</li>
  <li>#The claim settlement is directly managed through the partnered and registered cashless garage by the insurance provider in case of any cashless claims.</li>
</ul>
<br />
<br />
<h5 className="healthHeading">Let Us Know About the Terms and Conditions of Key Protection Car Insurance Cover</h5>
<div className="yellowline"></div>
<ul>
  <li># As soon as a claim is raised, you should contact the insurance provider and inform them on an immediate basis.</li>
  <li># A police FIR is the most essential document that is mandatorily required while raising a car insurance claim regarding replacement of a car key.</li>
  <li># The replaced car key, lock-set, and the lock should be of the same type as the previous one.</li>
  <li># Only damaged and broken car keys are considered for replacement by the insurance companies. The whole lock or set of keys will be entirely changed in the event of theft or burglary.</li>
</ul>
<br />
<br />
<h5 style={{color:"#2959B8"}}>The Facts & Findings of Key Replacement/Protection Add-on through the Following Q/A:</h5>
<div className="yellowline"></div>
<br />
{ReplacementProtection.map((data, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<FaCaretDown />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
          >
            <Typography>{data.q}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {data.a}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
</div>
<br />
<br />
<div className="container">
<h5 style={{color:"#2959B8"}}>6.Tyre Protection Coverage or Tyre Protector Add-On</h5>
<div className="yellowline"></div>
<br />
<span> This benefit is also an Add-On Cover and can be opted for, on purchasing an all-inclusive (Comprehensive) car insurance policy. This add-on facility covers the expenses of repairs and replacement of the tyres. If any accidental damage happens, or any type of cut or loss to the tiers, the related cost shall be barred as per the policy term of the insurance company.</span>
</div>
<br />
<div className="container">
  <h5 className="healthHeading">What is Tyre Protector in car insurance?</h5>
  <div className="yellowline"></div>
  <span>
Tyres are the most crucial parts of the car and are subject unavoidable ‘wear and tear’. They not just bear the weight of the entire vehicle, but pass through a lot of abuse of ever-changing road conditions. Because of inevitable damages, regular car insurance will not provide coverage if they are damaged. Hence, Tyre Protection Cover plays its role. <br /> <br /> By paying an additional premium Tyre Protection Cover can be availed with a comprehensive policy. The cover provides protection and security for several repair and replacements associated with the expenses of the tyres. In such scenarios of accidental damage to the tyres. The insurance will cover the related expenses as per your policy term. Many tyre protection covers also includes the labour charges for repairing and replacements. This should be confirmed with the insurance company before making any purchase.
</span>
<br />
<br />
<span>What is covered under Tyre Protection Cover?</span>
<br />
<span>Under a tyre protector cover, labor expenses, costs incurred in replacing or fixing-up a damaged tyre and other vandalizations caused due to accidents are covered.</span>
<br />
<br />

        <li># Tyre puncture and other minor tyre repairs.</li>
        <li># If car servicing is done at any unauthorized service center or an unauthorized garage.</li>
        <li># Routine expenses such as compensating, adapting, and orientation etc.</li>
        <li># Damages caused because of manufacturing defects.</li>
        <li># Loss pursued due to violating operating guidelines such as car racing or car rallying.</li>
        <li># Damage caused by over-loading the vehicle with people or luggage.</li>

<br />
<br />
<span>Apart from these there are certain other exclusions depending on the insurance provider. It is highly advisable to thoroughly go through the policy terms before buying any Tyre Protection Add-on cover.</span>
<br />
<br />
<b>Who are supposed to buy Tyre Protection Insurance?</b>
<br />
<br />

        <li># People using their car for travelling on a regular basis should ensure the protection of their car tyres and tubes.</li>
        <li># For example, driving through different states, and for people who travel long distances on a daily basis.</li>
        <li># People residing at hill stations and on the mountains, as vehicles in those regions need higher tyre protection due to rough and rugged terrains.</li>
        <li># Tyre protection add-on cover does not cover any claim arising out of robbery, burglary, or vandalism of the tube or tyre.</li>
        <li># It would be useless to purchase a tyre protection cover for individuals using their four-wheelers in car races.</li>
  
</div>

<br />
<br />

<div className="container">
<h5 style={{color:"#2959B8"}}>Questions & Answers Associated with Tyre Protection Cover</h5>
<br />
{Tyerprotection.map((data, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<FaCaretDown />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
          >
            <Typography>{data.q}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {data.a}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
      <br />
      <br />

        <li># If possible try and avoid bad road conditions or potholes.</li>
        <li># Always try to park your car in a safe and secured area to eliminate the chances of vandalism.</li>
        <li># Installation or initiation of security cameras and motion-sensing lights in the parking areas would certainly be much helpful to get rid of such destructive nature of occurring.</li>

</div>
<br />
<br />
<div className="container">
  <h5 style={{color:"#2959B8"}}>7. Return to Invoice Add-On Cover</h5>
  <br />
  <span>      Bought a new car? Then, Return to Invoice Add-on Cover is a well-judged decision. (RTI) or Return to Invoice is a cover that is a part of comprehensive car insurance plan. The add-on cover allows you to accept compensation equal to the car’s invoice value, i.e., the actual value of the car when you bought it. It can be claimed only when a car is stolen or if the car cannot be repaired in case of total loss.  This Add-on cover can be availed only if you have an extra amount in your car insurance premium.
         This add-on becomes valid only if your car has sustained any major damage and is not in a usable condition. The Return To Invoice add-on is not applicable in case of small damages.</span>
         <br />
         <br />
         <b>Features of Return to Invoice Add-On Cover</b>
         <br />
         <br />
         <li>Generally, one can avail this add-on cover for cars not older than 3 years of age.</li>
         <li>You can opt for it by paying a cost of around 10% more compared to your normal comprehensive/standalone own-damage car insurance policy.</li>

</div>
<br />

<div className="container">
  <h5 style={{color:"#2959B8"}}>Advantages and Aids of Return to Invoice Add-on Cover</h5>
  <div className="yellowline"></div>

        <li># It is particularly meant for new cars and will help compensate for the cost of a stolen car or a car that has been damaged beyond repair.</li>
        <li># If you live in an area where theft of cars and two-wheelers is a common phenomenon, it is a good idea to avail this cover as it will assure that you are preserved from these cases.</li>
        <li># The compensation provided by standard car insurance is always lower than the amount you paid for a car. Thus, if your car is stolen or totally destroyed, no insurance provides complete benefits for it. This is no longer a concern with the RTI Cover.</li>
</div>
<br />
<br />
<div className="container">
<h5 className="healthHeading">How Return to Invoice Add-On Cover Works for us?</h5>
  <div className="yellowline"></div>
  <br />
  <span>
  A - A basic car insurance policy would not earn you the actual value of your car or the money that you had spend while you had purchased the car. If we take the rate of deflation into acknowledgement, the rate of deflation or depreciation is 5% is applied on your car which is 6 months old from the day of purchase, and 10% is enforced for one year. In such scenarios, you have a chance to lose quite a huge amount. But, on the other hand you need not bear the differences or the variation is the price as the insurance provider pays off the full value of your car until the car tuns 3 years old.
  <br />
  <br />
  <b>When does this cover comes into play?</b>
  <br />
  <br />
  This cover is generally useful to cover the gap between the IDV and the invoice value of your car. This Add-on is an optional cover to earn you the complete value of loss when you claim your insurance in case of an accident or total loss for that matter. This cover is beneficial at an accidental cost. Generally, RTI cost 10% more than your comprehensive car insurance. This cover can be availed only once your car reaches its specific age.
  <br />
  <br />
  <b>How Much Reimbursement You Can Avail of?</b>
  <br />
  <br />
  While you purchase a car insurance, the insurer provides decides the IDV of your car by applying the depreciation from the invoice value. It is the prevailing market price of your car or the maximum amount up to which you can raise for a claim in case of a total loss.
  <br />
  <br />
  <b>When does Return to Invoice Add-on cover is enforced?</b>
  <br />
  <br />
  This Add-on cover is never be suggested or proposed to purchase Return to Invoice car insurance to cover small incisions and repair costs of the minor damages of your car. This cover becomes fruitful in covering the full value of the vehicle in the scenarios of total damage or loss. It is beneficial if your car is stolen or defends the damages and impairments beyond repair.

All the more, when you live in an area prone to larceny, frauds and embezzlements without a secured car parking provisions, then this cover has to be considered without fail. Any which ways, this Add-on cover cannot be carried always.

After a few renewals of your policy, you need to eliminate or pull off this cover. It is a loss for the insurance company to pay you a value that is quite higher compared to the prevailing market value of your car, and specifically for those cars whose abrasion (WEAR AND TEAR) expenses have gone up over a period of time.
<br />
<br />
<b>How do you calculate the Invoice Add-on cover?</b>
<br />
<br />
At the time of purchasing the car, you pay the on-road price. Certain components are included under On-road price, such as ex-showroom price, road tax and registration charges that depends on the make and model of the car. So, if your vehicle is stated has a complete loss, moreover if you do not have an RTI add-on cover, the insurance provider will provide you coverage according to your insured announced value. Whenever you raise a claim under the total loss scenario the insurance company compensates for the values declared below:
  </span>
</div>
<br />
<br />
<div className="container">
  <h5 style={{color:"#2959B8"}}>Questions and Answers Associated with Return To Invoice Cover</h5>
  <div className="yellowline"></div>
  <br />
  <b>Q. Who Should Buy RTI Add-On Cover?</b>
  <br />
  <li>A - Individuals having a new car</li>
        <li>Individuals who have a high-end car</li>
        <li>People living in a theft-prone area</li>
        <br />
        <br />
        <b>Q. In Which Cases is the Return to Invoice Cover Not Valid?</b>
        <br />
<br />
        <li># The return to Invoice cover has been created and implemented only for new cars. Suppose, your car is more than 5 years old and therefore you cannot opt for this add-on cover.</li>
        <li># If it is possible to repair the damage to the car, the return to invoice add-on cover cannot be applied. This option can only be opted for people who have had their car stolen or the car got damaged to a certain limit beyond repair.</li>
        <li># If you are not successful in filing an FIR after the vehicle is stolen or is damaged entirely due to an accident, the RTI cover cannot be applied, because the FIR is vital as a bolstering evidence to your claim.</li>
</div>
<br />
<br />
<div className="container">
{ReturnInvoice.map((data, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<FaCaretDown />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
          >
            <Typography>{data.q}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {data.a}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
</div>
<br />
<div className="container">
  <h5 style={{color:"#2959B8"}}>8.Daily Allowance Add-On</h5>
  <div className="yellowline"></div>
  <br />
  <span>     Downtime Allowance or Daily Allowance Add-On is a kind of car insurance option that offers coverage for the cost of commuting in case the insured person’s car is being repaired. This claim is applicable if the time duration of repairing the car takes more than two days. This is a benefit for the insured person during the period of their regular commuting while their car is under repair. The sum varies from one insurance provider to another. By enrolling for this option, the policy holder receives a daily allowance from the insurer when the two-wheeler is lost or cannot be repaired.
As the name advocates, this Add-on cover provides a daily allotment of a fixed sum to support the insured to cover their daily expenses of transportation where as their insured vehicle is currently undergoing restoration. In India, this amount can change from one insurance provider to another. Generally, Rs. 500 per day for up to 2 weeks. This is beneficial during emergencies or accidents that impacts the insured’s car.</span>
<br />
<br />
<b>Why should people avail Daily Allowance add-on cover with the car insurance?</b>
<br />
<span>
  <b>#For people who does not prefer availing public transport </b>
Public transports are in good shape in many countries, but even after maintenance, most of the times because of huge population in Indian states, public transport is not expectantly clean and not safe at the same time and that is the main reason a section of people avoid travelling by public mode of transportation. Hence, this add-on has to be selected by those who is public transport averse.
</span>
<br />
<br />
<span>
  <b>#People with only one car  </b>
  Professionals who own only one car and are dependent on the same must purchase this add-on because it’s essential for them because this facility will help those individuals if their private vehicles are in the garage. They shouldn’t be bothered about traveling by bus, auto, metro or any other mode of public transports.</span>
<br />
<br />
<span>
  <b>#An example of daily allowance add-on: </b>
  Mr. Paul had a 20 km drive to his work place. One fine morning, he unfortunately had met with an accident and his car landed up in a garage for few following days. But, Mr. Paul had a comprehensive car insurance policy along with a daily allowance Add-on or time-out allowance and he contacted his insurer. His insurance provider confirmed that it would take at least</span>
  <br />
  <br />
  <h5  style={{color:"#2959B8"}}>How constructive and profitable is Daily Allowance Add-on Cover?</h5>
  <br />
  <br />
  <span>
  Availing cabs and taxis and your daily mode of transportation on a regular basis could cost you a hefty sum at the end of the month, especially during peak office hours or at the time of peak traffic jam. Hence, if an insured’s car requires more than two days to get repaired at an authorized garage then this benefit can be conveniently sufficed. The Daily Allowance optional add-on cover has many benefits as follows:
  </span>
  <br />
  <br />
  <span>
  Economical – You can most conveniently save money during exigencies by not availing high priced mode of transportation for daily commute. You can use the allowance for your commute.
  </span>
  <br />
  <br />
  <span>
  Availability & Beneficial Service – The Daily Allowance Add-on is quite useful for your daily commute even though you have your car left at the authorized garage for repairing and service.
  </span>
  <br />
  <br />
  <span>
  Options for Travelling – People who use their own car often find it a bit difficult and clumsy to avail any other mode of transportations, such as public transports, or they might it find it inconvenient because of lack of transportation in that particular area where they reside. But, the good news is that this allowance allows you to choose and opt for your own means of transport in case their car malfunctions. They will just notify their insurance provider through an intimation to raise a claim and the rest will be taken care of. This allowance also covers many types of catastrophic and man-made events that may turn out to be a break-down. In spite of all these the allowance will be provided as per the policy issuance schedule.
  </span>
  <br />
  <br />
  <h5 style={{color:"#2959B8"}}>Time-out or Down town Allowance: What is covered under this Add-on?</h5>
  <br />
  <br />
  <span>
  This Add-on allowance will reimburse the expense of daily transportation in various events leading to deficiency or omission of the insured’s car while it’s in an authorized garage for repair. 
  </span>
  <br />
  <br />
  {DailyAddonCover.map((data, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<FaCaretDown />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
          >
            <Typography>{data.q}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {data.a}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
      <br />
      <br />
      <h5 style={{color:"#2959B8"}}>9.Pay As You Drive</h5>
      <br />
      <span>
      As the name suggests ‘Pay As You Drive’(PAYD) is a cover that you can opt for along with your base car insurance while buying a comprehensive or own damage car insurance, if only you drive less than 1000 km/hour. The insurance company will provide the benefit to the policyholder at the end of the policy term. The policyholder will be benefited based on the total kilometers driven during the policy period. The insurance industry keeps progressing and moving forward to cater to the ever-changing needs of the customers. The expansion and transformation of insurance sector from traditional mode of purchasing to a digital mode of purchase has brought about a massive transformation in the insurance industry. The most recent transformation in the Motor Insurance part in India is the base of ‘Pay As You Drive’ insurance add-on cover. This add-on cover is already time-tasted and has been successful over a period of time. This add-on benefit is called by various names, such as ‘Pay As You Drive/Pay As You Go/ Pay As You Use/ Pay Per km’ Insurance.
      </span>
      <br />
      <br />
      <li>
      Pay-as-you-drive or Pay-as-you-use insurance is a type of comprehensive insurance where the cost of the premium is decided based on the usage of the vehicle. A tool/appliance is used to track the vehicle’s usage and this gadget is also used to maintain the overall distance travelled by the car. The Insurance Regulatory and Development Authority of India (IRDAI) has announced the above-mentioned policy as a usage-based policy. You can connect with the
      </li>
      <br />
      <br />
      <li>
       car insurer to replenish the account when the money is consumed. According to the policies, this can be done at any time throughout or after the plan's duration.
‘Pay as You Drive’ Car Insurance has various benefits. Observe and read through the features and benefits to know the content, significance, functioning, and the advantages of this add-on coverage mentioned below.
      </li>
      <br />
<br />
<h5 style={{color:"#2959B8"}}>How Does Pay As You Drive Car Insurance Add-On Cover Operate?</h5>
<div className="yellowline"></div>
    <li># The car’s usage for a one-year insurance tenure must be explained based on total kilometers driven. Insurance companies have policies that benefit you with various use slabs, which vary from one insurance company to another.</li>
    <li># The insurer will install a telematics device (a device made with a combination of information technology and telecommunications) in your car without any cost.</li>
    <li># The device records the distance traveled by the four-wheeler, the driver's nature of driving, and keeps a record of the remaining kilometers in the account.</li>
    <li># The policies include purchasing add-ons to enhance and extend the coverage.</li>
    <li># The usage of the car tier settles the premium you pay for the car insurance and add-ons you select.</li>
    <li># You can contact the car insurance company to replenish the account when the money is exhausted. According to the norms of the policies, this is done throughout or after the duration of the plan.</li>
    <li># Pay-as-you-drive insurance has a strategy that also saves you money on your personal damage car insurance premiums. The savings might range from 5% to 25% based on the type of car insurance.</li>
<br />
<br />
<h5 style={{color:"#2959B8"}}>Features of Pay As You Drive Car Insurance. This insurance coverage has a few significant features-</h5>
<div className="yellowline"></div>

    <li># The policy has a one-year term. IRDAI has started an experimental programmed initiative</li>
    <li># The policy has a one-year term.</li>
    <li># IRDAI determines the third-party premium</li>
    <li># A substitute to regular car insurance that is affordable/less expensive.</li>
    <li># The car’s damage premium is settled by its usage (total kilometers driven).</li>

<br />
<br />
<h5 style={{color:"#2959B8"}}>Who Is Recommended To Buy Pay As You Use Car Insurance?</h5>
<div className="yellowline"></div>
<br />

<li>#. Who do not drive their cars frequently.</li>
<br />
<li>#. People who mostly avail public transportations</li>
<br />
<li>#. An individual may own numerous cars, but you do not use all of them at the time.</li>
<br />
</div>
<br />
<br />
<div className="container">
<h5 style={{color:"#2959B8"}}>What are the consequences if the car usage limit is exhausted?</h5>
<div className="yellowline"></div>
<br />
<span>
If the vehicle usage limit is reached, it can be reloaded at any time before the policy expires or even during the policy tenure. If you haven't initiated any claims, shifting to a higher and major use slab or basic own-damage car insurance is viable. The insured individual will be liable for the hiked premium. While according to the car insurance policy, the damage coverage ought to be renewed once it expires as per the car insurance policy. Your third-party coverage will actively continue. Own damage claims cannot be submitted if the car's distance covered crosses the usage-limitations.
</span>
<br />
<br />
<h5 style={{color:"#2959B8"}}>How to purchase a Pay As You Drive Insurance Cover Plan?</h5>
<div className="yellowline"></div>
<br />

    <li># Visit the website of Beema1 and click on the “car insurance" option.</li>
    <li># You can select between pay-as-you-drive car insurance.</li>
    <li># Choose a slab based on how frequent your four-wheeler is used annually.</li>
    <li># Click on the four-wheeler’s information page and provide KYC information such as name, phone number, and so on.</li>
    <li># Complete filling up the details on the permission form.</li>
    <li># You also have the option to add the add-on covers based on your preference and requirements.</li>
    <li># The premium of your policy will be calculated and proposed based on the slab you select. Once the payment is successfully done online, your car insurance policy will be issued in your name.</li>
<br />
<br />
<span>
Before procuring a pay-as-you-drive car insurance policy, asses your financial scenes, usage and utilization of your car, your requirements for insurance coverage, add-on coverage options offered by the car insurance company, terms and conditions, features and benefits, and all expenses related to it is mentioned in the paperwork, like you would with customary car insurance. The claim process is actually hassle-free if you take little effort to thoroughly read the fine prints of the policy document and consider your options.
</span>
<br />
<br />
<h5 style={{color:"#2959B8"}}>based on Engine Protection Add-on Cover</h5>
<br />

{EngineProctection.map((data, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<FaCaretDown />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
          >
            <Typography>{data.q}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {data.a}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
</div>
<br />
<br />
<div className="container">
  <h5 style={{color:'red'}}>Disclaimer</h5>
  <br />
  <span>
  These details are published in the interest of general public and aimed for general information intents only. Whoever visits the website of Beema1 and read, would not to rely on the matters of the article as definitive in nature and should explore and experiment further or consult an expert.
  </span>
  <br />
  <br />
  <h5 style={{color:"#2959B8"}}>Bumper to Bumper Insurance</h5>
  <br />

  <span>
  Bumper to Bumper Insurance plays a very vital role in ensuring large-scale insurance protection. One should insure his/her car with a comprehensive protracted cover, but somehow if you find out that your existing car insurance cover is inadequate and faulty in few ways then you can trust Bumper to Bumper Add-On cover in such scenarios.
  </span>
  <br />
  <span>
  Bumper to Bumper, a nil depreciation or zero depreciation is a type of car insurance policy that provides an all-inclusive coverage to your car heedless of the deflation of its parts. Therefore, when your car gets affected with certain loss or damage caused by an accident, the insurance provider won’t deduct any depreciation value from the coverage amount excluding the car batteries and car tyres. Your car insurance policy will pay the entire expense of reinstating and supplementing the body parts of your car. Bumper to Bumper car insurance is also known as Full-Body car insurance benefits you with full coverage for all rubber, fiber, and metal parts of your vehicle without deducting the depreciation value. But, it will not cover water ingression and engine damage because of oil leakage. Any expenditures arousing out of mechanical breakdown, consumables or oil change are not covered. Moreover, the number of claims to be filed in a year is also restricted.
  </span>
</div>
<br />
<br />
<div className="container">
  <h5 style={{color:"#2959B8"}}>What edges and opportunities you can avail once you opt for the add-on Bumper to Bumper?</h5>
  <div className="yellowline"></div>
  <br />
  <span>
  If you have a nil depreciation or a zero- depreciation cover in your car insurance policy, it states that full compensation for any car loss or damage during the claim settlement process. The amount is invariably higher than the amount reimbursed under a comprehensive car insurance plan. This is because the claim amount in a comprehensive car insurance policy shall be paid based on current market valuation of the car after tailoring the value of depreciation. Apart from that there would be certain expenses that the owner of the car has to pay from his own pocket for repairing. On the other hand, one will get end-to-end expenses covered under the claim amount in a zero-depreciation policy. But in case of a zero-depreciation policy 

For an example: Let’s say that you have a (hatchback) car, that was impaired and the total expense of the repair was Rs. 50000, so you will have to pay somewhere around Rs. 25000 to Rs. 30000 from your pocket for depreciation on metal or plastic parts. But in a zero-depreciation policy as because the insurance company will cover the complete repair amount based on the IDV (INSURED DECLARED VALUE).
  </span>
  <br />
<br />
<h5 style={{color:"#2959B8"}}>
Downsides and Drawbacks of Bumper to Bumper Add-on Insurance Coverage?
</h5>
<br />
<span>
As compared to a basic standard comprehensive car insurance policy, a zero-depreciation add-on cover is priced 20% to 30% higher than the former. It does not provide coverage to tyres, tubes and batteries of the car. Moreover, this add-on is only available with the four- wheeler car insurance policy for cars up to 5 years old. There’s also a limit and restriction on the number of claims that could be filed during a policy term to receive the full claim benefits.
</span>
<br />
<br />

</div>
<PartnerCompany/>
<FAQ/>
    </>
  )
}

export default AddonCover