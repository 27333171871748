import React, { useEffect, useMemo, useState } from "react";
import "../../CommonCssFiles/planstyle.css";
import locations from "../../../locations.json";
import { useDispatch, useSelector } from "react-redux";
import { updateCity, updateCityDetails } from "../../redux/Slice";
import { fetchQuickQuote , updateQuickQuote } from "../../Api/Api";
import Loader from "../../../AllPageComponents/Loader";


const EditCity = ({ onBack, showSide , setShowside , setPlanData , setApiResponse,apiResponse,  setSidedata}) => {
  const [searchByDistrictOrPin, setSearchByDistrictOrPin] = useState("");
  const [pinCode, setPinCode] = useState();
  const [activeLocation, setActiveLocation] = useState({});
  const [isSelected, setIsSelected] = useState(false);
  const [defaultValue, setdefaultValue] = useState([]);
  const [enterPincode, setenterPincode] = useState([])
  const [userdata , setUserData] = useState();
  const [apiResponseEdit , setApiResponseEdit] = useState();
  const [isLoading , setIsLoading] = useState(false);
  // Default State Data is

  const [UserState, setUserState] = useState("");
  const [userPincode, setuserPincode] = useState("");


  const search = window.location.search;
  const params = new URLSearchParams(search);
  const unique_id = params.get("req_id");
  const plan_id = params.get("plan_id");
  const c_id = params.get("c_id");

  const getUniqueLocations = useMemo(() => {
    const uniquePincodesSet = new Set();
    const formattedArray = [];

    locations.forEach((item) => {
      const district = item.District;
      const pincode = item.Pincode;

      if (uniquePincodesSet.has(district)) {
        const existingEntry = formattedArray.find(
          (entry) => entry.District === district,
        );
        existingEntry.Pincode.push(pincode);
      } else {
        uniquePincodesSet.add(district);
        formattedArray.push({
          Pincode: [pincode],
          District: district,
          StateName: item.StateName,
        });
      }
    });
    return formattedArray;
  }, []);

  const filteredLocations = useMemo(() => {
    const term = (searchByDistrictOrPin || "").trim().toLowerCase();
    if (!term) {
      return [];
    }
    return getUniqueLocations.filter((item) => {
      return (
        item.District.toLowerCase().includes(term) ||
        item.Pincode.some((pin) => pin?.toString()?.includes(term))
      );
    });
  }, [getUniqueLocations, searchByDistrictOrPin]);

  const handleSearchByDistrictOrPin = (e) => {
    const Input = e.target.value;
    if (!e.target.value) {
      setIsSelected(false);
      setSearchByDistrictOrPin("");
      setPinCode();
    } else if (e.target.value && Number(e.target.value)) {
      setPinCode(e.target.value);
    }
    if (Input.length === 6) {
      setSearchByDistrictOrPin(Input);
    } else {
      setdefaultValue(Input);
    }
  };

  // const { userdata } = useSelector((state) => {
  //   return state.FetchPlan;
  // });

  const fetchApi = async () => {
    try {
      const response = await fetchQuickQuote(unique_id);
      setApiResponseEdit(response);
      setUserData(response.data.request);
      // alert(response.data.request.PinCode)
      setPinCode(response.data.request.PinCode);
      return response;
    } catch (error) {
      console.log("The error", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchApi();
      if (response) {
        setApiResponseEdit(response);
      }
    };

    fetchData();
  }, []);

  const handleSelectedLocation = (location) => {
    setSearchByDistrictOrPin(location.District);

    setActiveLocation({
      District: location.District,
      StateName: location.StateName,
      Pincode: pinCode,
    });
    setIsSelected(true);
  };

  useEffect(() => {
    if (showSide == false) {
      setChangesApplied(false);
    }
  }, [showSide]);

  useEffect(() => {
    setSearchByDistrictOrPin(userdata?.District);
    setIsSelected(true);
  }, [userdata?.District]);

  const [changesApplied, setChangesApplied] = useState(false);
  const dispatch = useDispatch();

  const handleCity = async() => {
    setIsLoading(true)
    // dispatch(updateCity(activeLocation));
    //console.log("the active location ", activeLocation);
    // dispatch(updateCityDetails(activeLocation));

    localStorage.setItem("PinCode", activeLocation.Pincode);
    localStorage.setItem("State", activeLocation.StateName);
    localStorage.setItem("District", activeLocation.District);

    setChangesApplied(true);
    let payloadData = {
      ...apiResponse.request,
      PinCode : activeLocation.Pincode,
      State : activeLocation.StateName,
      District :  activeLocation.District
    }

    console.log("apiResponse", apiResponse);
    console.log("payloadData",payloadData)

    await updateQuickQuote(payloadData , unique_id).then((res)=>{

      console.log("the response", res);
      
      setApiResponse(res.data);
      setSidedata(res.data.request)
      setShowside(false);
      setPlanData(res.data.response);
      setIsLoading(false)


    })
    onBack();
  };

  // //console.log("the userdata in editcity", userdata)

  useEffect(() => {
    if (filteredLocations) {
      filteredLocations.forEach((iteam) => {
        setSearchByDistrictOrPin(iteam.District);
        setuserPincode(pinCode);
        setActiveLocation({
          District: iteam.District,
          StateName: iteam.StateName,
          Pincode: enterPincode,
        });
      });
    }
  }, [filteredLocations]);

  //console.log("The Data",activeLocation)

  const FullDistrictandPin = `${UserState} ${pinCode}`;
  //console.log(FullDistrictandPin);





const userPinCode = (e)=>{
  setenterPincode(e.target.value)
}
function combinedHandler(event) {
  handleSearchByDistrictOrPin(event);
  userPinCode(event);
}
useEffect(()=>{
  let AdditnalPincode 
  if(apiResponse?.request?.PinCode){
   AdditnalPincode = apiResponse.request.PinCode ;
  }
  else{
    AdditnalPincode = localStorage.getItem('PinCode')

  }
  setenterPincode(AdditnalPincode)
},[])


  return (
    <>
    {isLoading && <Loader/>}
      {changesApplied && (
        <div class="alert alert-success" role="alert">
          Your data has been updated Sucessfully!
        </div>
      )}
      <div className="scrollable-container">
        <div className="form-group design">
          <label className="alignment" htmlFor="address">
            Search for district and pincode:
          </label>
          <input
            list="addressOptions"
            className="form-control"
            id="searchCoin"
            autoFocus="autofocus"
            onChange={combinedHandler}
            value={searchByDistrictOrPin ? `${activeLocation.District} ${enterPincode}` : enterPincode || defaultValue}
            maxLength={6}
            onKeyDown={(e) => {
              if (e.keyCode === 8) {
                setSearchByDistrictOrPin();
                setenterPincode()
              }
            }}
        
            required={true}
          />
         <ul className={isSelected ? "d-none" : "custom-dropdown"}>
            {filteredLocations.map((item) => {
              return (
                <li
                  key={item.District}
                  onClick={() => handleSelectedLocation(item)}
                >
                  {item.District} {pinCode}
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      <div className="searchbuttondiv">
        <button className="sidbtnn" onClick={onBack}>
          Back
        </button>
        <button className="sidbtnn" onClick={handleCity}>
          Apply Changes
        </button>
      </div>
    </>
  );
};

export default EditCity;
