import React, { useState, useEffect, useRef } from "react";
import "./Header.css";
import { RiCustomerService2Fill } from "react-icons/ri";
import { GiHamburgerMenu } from "react-icons/gi";
import { RxCross2 } from "react-icons/rx";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Login from "../../AllPageComponents/Login";
import Otp from "../../AllPageComponents/Otp";
import styled, { keyframes } from "styled-components";
import { useNavigate } from "react-router-dom";
import { IoMdArrowDropdown } from "react-icons/io";
import { FaUserCircle } from "react-icons/fa";
import { FaArrowRotateRight } from "react-icons/fa6";
import {
  updatedLoginPopup,
  selectLoginForm,
  updatedUserdata,
  selectOtpForm,
  selectUserData,
  updatedLoginUser,
} from "../../Health/redux/Slice";
import { BiLogoTelegram } from "react-icons/bi";
import { CgWebsite } from "react-icons/cg";
import { FaCarOn } from "react-icons/fa6";
import { RiEBikeFill } from "react-icons/ri";
import { MdHealthAndSafety } from "react-icons/md";
import { MdInterpreterMode } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import FileBase64 from "react-file-base64";
import Swal from "sweetalert2";
import FastImage from "../../FastImage";
import { auto } from "@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core";
import { RiAccountCircleFill } from "react-icons/ri";
import { FaUserEdit } from "react-icons/fa";
import { MdPolicy } from "react-icons/md";
import { BiLogOutCircle } from "react-icons/bi";
import { Box } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import { FaCircleUser } from "react-icons/fa6";
import { MdOutlineSupportAgent } from "react-icons/md";
import { BiSupport } from "react-icons/bi";
import { GrUserExpert } from "react-icons/gr";





const Header = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [popup, setPopup] = useState({ isOpen: false });
  const [showham, setShowHam] = useState(false);
  const [showhambody, setShowHambody] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [showcar, setShowCar] = useState(true);
  const [showbike, setShowBike] = useState(false);
  const [showhealth, setShowHealth] = useState(true);
  const [showterm, setShowTerm] = useState(false);
  const [showBox, setShowBox] = useState(false);
  const [showCallback, setShowCallback] = useState(false);
  const [showAccount, setShowAccount] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [isValid, setIsValid] = useState(false);

  const [formDataCall, setFormDataCall] = useState({
    name: "",
    product_name: "",
    mobile_number: "",
    time_slot: "",
    message: "",
    code: "",
  });






  const [captcha, setCaptcha] = useState(generateCaptcha());

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormDataCall({
      ...formDataCall,
      [name]: value,
    });
  };

  function handleRegenerate() {
    setCaptcha(generateCaptcha());
  }

  function generateCaptcha() {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < 6; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }

  const handleButtonClick = async () => {
    // Check if all required fields are filled
    const hasEmptyFields = Object.values(formDataCall).some((value) => !value.trim());
  

  console.log('the usercallback resquest',formDataCall)

   axios.post(`${process.env.REACT_APP_BASE_URL}cms/user_callback_request`,formDataCall).then((res)=>{
    console.log(res)
   })


    if (hasEmptyFields) {
      Swal.fire({
        title: "Error!",
        text: "Please fill out all fields.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return; // Prevent form submission if any field is empty
    }
  
    if (userInput !== captcha) {
      Swal.fire({
        title: "Error!",
        text: "Invalid captcha code",
        icon: "error",
        confirmButtonText: "Back",
      }).then(() => {
        handleRegenerate(); // Regenerate captcha code after showing the error message
      });
      return; // Prevent form submission if captcha is not valid
    }
  
    // If captcha is valid and all fields are filled, show success message
    Swal.fire({
      title: "Success!",
      text: "Form submitted successfully",
      icon: "success",
      confirmButtonText: "OK",
    }).then(() => {
      // Simulate a click on the close button
      document.querySelector(".modal-close-home").click();
  
      // Clear form fields after closing the modal
      setFormDataCall({
        name: "",
        product_name: "",
        mobile_number: "",
        time_slot: "",
        message: "",
        code: "",
      });
      setUserInput("");
      setCaptcha(generateCaptcha());
    });
  };
  
  
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Function to toggle the visibility of the box
  const toggleBox = () => {
    setShowBox(!showBox);
  };
  // Sticky Navbar

  const nav = useNavigate();
  const handleScroll = () => {
    if (window.scrollY > 150) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

  const Bar = styled.div`
    width: 30px;
    height: 3px;
    background-color: ${({ color }) => color || "white"};
    margin: 6px 0;
    transition: 0.4s;
  `;

  const HamburgerIcon = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    margin: 15px;
    transition: 0.5s;
    animation: ${({ isOpen }) => (isOpen ? "slideIn" : "slideOut")} 0.3s
      ease-in-out forwards;
  `;

  const handleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  // navbar Code Start Hear

  const dispatch = useDispatch();
  const loginPopup = useSelector(selectLoginForm);
  const otpPopup = useSelector(selectOtpForm);
  const userData = useSelector(selectUserData);

  useEffect(() => {
    checkLogin();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const checkLogin = () => {
    const userToken = localStorage.getItem("userToken");

    if (userToken) {
      let data = JSON.stringify({
        token: userToken,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_BASE_URL + "user/me",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          //console.log(JSON.stringify(response.data));
          dispatch(updatedUserdata(response.data));
          dispatch(updatedLoginUser(response.data));
          localStorage.setItem(
            "Proposalphonenumber",
            response.data.data.mobile_number
          );
          setUserGetData(response.data.data);
          setProfilePicture(userGetData.profile_image);
        })
        .catch((error) => {
          //console.log(error);
          dispatch(updatedUserdata(false));
        });
    } else {
      dispatch(updatedUserdata(false));
    }
  };

  // Get My Account options
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleMouseEnter = () => {
    setShowBox(true);
  };

  const handleMouseLeave = () => {
    setShowBox(false);
  };

  const handleAccountEnter = () => {
    setShowAccount(true);
  };
  const handleAccountLeave = () => {
    setShowAccount(false);
  };

  const handleCarEnter = () => {
    setShowCar(true);
    handleTermLeave();
    handleHealthLeave();
    handleBikeLeave();
  };

  const handleCarLeave = () => {
    setShowCar(false);
  };

  const handleBikeEnter = () => {
    setShowBike(true);
    handleCarLeave();
    handleTermLeave();
    handleHealthLeave();
  };

  const handleBikeLeave = () => {
    setShowBike(false);
  };

  const handleHealthEnter = () => {
    setShowHealth(true);
    handleCarLeave();
    handleBikeLeave();
    handleTermLeave();
  };

  const handleHealthLeave = () => {
    setShowHealth(false);
  };

  const handleTermEnter = () => {
    setShowTerm(true);
    handleHealthLeave();
    handleBikeLeave();
    handleCarLeave();
  };
  const handleTermLeave = () => {
    setShowTerm(false);
  };

  const heandelLogout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  const GetUserData = localStorage.getItem("ProposalName");

  const trimmedName = localStorage.getItem('AuthuserName')

  const Token = localStorage.getItem("userToken");

  const [userGetData, setUserGetData] = useState({});

  //console.log("the ComeData",userGetData.first_name)

  const [formData, setFormData] = useState({
    first_name: "" || userGetData.first_name,
    last_name: "",
    mobile_number: "",
    email: "",
    dob: "",
    annual_income: "",
    vehicle_info: "",
    city: "",
    home_address: "",
    gender: "",
    profile_image: "",
  });

  useEffect(() => {
    if (Object.keys(userGetData).length !== 0) {
      setFormData((prevState) => ({
        ...prevState,
        first_name: userGetData.first_name || "",
        last_name: userGetData.last_name || "",
        mobile_number: userGetData.mobile_number || "",
        email: userGetData.email || "",
        dob: userGetData.dob || "",
        annual_income: userGetData.annual_income || "",
        vehicle_info: userGetData.vehicle_info || "",
        city: userGetData.city || "",
        home_address: userGetData.home_address || "",
        gender: userGetData.gender || "",
      }));
    }
  }, [userGetData]);

  //console.log("the ComeData", userGetData.first_name);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckboxClick = (gender) => {
    setFormData((prevState) => ({
      ...prevState,
      gender: gender,
    }));
  };

  const onFileSubmit = (file) => {
    // Define regular expressions for different image MIME types
    const regexPNG = /^data:image\/png;base64,/;
    const regexJPG = /^data:image\/jpeg;base64,/;
    const regexSVG = /^data:image\/svg\+xml;base64,/;

    // Remove the appropriate prefix from the base64 string based on the file type
    let base64WithoutPrefix;
    if (file.type === "image/png") {
      base64WithoutPrefix = file.base64.replace(regexPNG, "");
    } else if (file.type === "image/jpeg") {
      base64WithoutPrefix = file.base64.replace(regexJPG, "");
    } else if (file.type === "image/svg+xml") {
      base64WithoutPrefix = file.base64.replace(regexSVG, "");
    }
    setFormData((prevState) => ({
      ...prevState,
      profile_image: base64WithoutPrefix,
    }));
  };

  const handleSubmit = async (e) => {
    const formDataObject = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      mobile_number: formData.mobile_number,
      email: formData.email,
      dob: formData.dob,
      annual_income: formData.annual_income,
      vehicle_info: formData.vehicle_info,
      city: formData.city,
      home_address: formData.home_address,
      gender: formData.gender, // Ensure this matches the key name in the formData object
      profile_image: formData.profile_image, // Ensure this matches the key name in the formData object
    };
    //console.log("The Data",formDataObject)
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}user/update_profile`,
        formDataObject,
        {
          params: { token: Token },
        }
      );
      //console.log('Response:', response.data);
    } catch (error) {
      console.warn("Something went wrong");
    }
  };

  const [profilepicture, setProfilePicture] = useState("");

  const [isStickyNav, setIsStickyNav] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 150) {
        setIsStickyNav(true);
      } else {
        setIsStickyNav(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const navItems = [
    {
      id: 0,
      name: "Insurance",
      submenu: [
        {
          id: 0,
          name: "Health Insurance",
          link: "/health-insurance",
          childsubmenu: [
            {
              id: 0,
              name: "Health Insurance Compare Quote",
              link: "/health-insurance/health-compare-quotes",
            },
            {
              id: 1,
              name: "Health Co Payment",
              link: "/health-insurance/health-co-payment",
            },
            {
              id: 2,
              name: "Parents Health Insurence",
              link: "/health-insurance/parents-health-insurance",
            },
            {
              id: 3,
              name: "Cashless Health Insurence",
              link: "/health-insurance/cashless-health-insurance",
            },
            {
              id: 4,
              name: "Health Claim Settelment",
              link: "/health-insurance/health-clame-settelment",
            },
            {
              id: 5,
              name: "Family Health Insurence",
              link: "/health-insurance/family-health-insurance",
            },
            {
              id: 6,
              name: "Critical Illness Insurance",
              link: "/health-insurance/critical-illness-insurance",
            },
            {
              id: 7,
              name: "Health Topup Plane",
              link: "/health-insurance/health-topup-plan",
            },
            {
              id: 8,
              name: "Senior Citizen Insurence",
              link: "/health-insurance/senior-citizen-insurance",
            },
            {
              id: 9,
              name: "Individual Health Insurance",
              link: "/health-insurance/individual-health-insurance",
            },
          ],
        },
        {
          id: 1,
          name: "Term Insurance",
          link: "/term-insurance",
          // childsubmenu: [
          //   {
          //     id: 0,
          //     name: "Term Insurance Compare Your Quotes",
          //     link: "/term-insurance/term-compare-quotes",
          //   },
          //   {
          //     id: 1,
          //     name: "Compare Insurance",
          //     link: "/term-insurance/term-compare-insurance",
          //   },
          //   {
          //     id: 2,
          //     name: "NRI Term Plans",
          //     link: "/term-insurance/term-nri-plans",
          //   },
          //   {
          //     id: 3,
          //     name: "Term insurance",
          //     link: "/term-insurance/term-insurance-inner",
          //   },
          //   {
          //     id: 4,
          //     name: "Tax Benefits",
          //     link: "/term-insurance/term-tax-benefits",
          //   },
          //   {
          //     id: 5,
          //     name: "Family / Group / Senior Citizen Health Insurance",
          //     link: "/term-insurance/family-insurance",
          //   },
          // ],
        },
        {
          id: 2,
          name: "Car Insurance",
          link: "/car-insurance",
          // childsubmenu: [
          //   {
          //     id: 0,
          //     name: "Car Insurence Compare Quotes",
          //     link: "car-insurence/compare-quotes",
          //   },
          //   {
          //     id: 1,
          //     name: "Cashless Garage",
          //     link: "car-insurence/cashless-garage",
          //   },
          //   {
          //     id: 2,
          //     name: "Premium Calculator",
          //     link: "car-insurence/premium-calculator",
          //   },
          //   {
          //     id: 3,
          //     name: "Renewal",
          //     link: "car-insurence/renewal",
          //   },
          //   {
          //     id: 4,
          //     name: "Addon Cover",
          //     link: "car-insurence/addoncover",
          //   },
          //   {
          //     id: 5,
          //     name: "Claimn Settlement",
          //     link: "car-insurence/claimn-settlement",
          //   },
          // ],
        },
        {
          id: 3,
          name: "Bike Insurance",
          link: "/bike-insurance",
          // childsubmenu: [
          //   {
          //     id: 0,
          //     name: "Bike Compare Quotes",
          //     link: "bike-insurance/bike-compare-quotes",
          //   },
          //   {
          //     id: 1,
          //     name: "Bike Companies",
          //     link: "bike-insurance/bike-companies",
          //   },
          //   {
          //     id: 2,
          //     name: "Cashless Garages",
          //     link: "bike-insurance/bike-cashless-garages",
          //   },
          //   {
          //     id: 3,
          //     name: "Bike Stand Alone Garages",
          //     link: "bike-insurance/bike-stand-alone-garages",
          //   },
          //   {
          //     id: 4,
          //     name: "Bike Renewal",
          //     link: "bike-insurance/bike-renewal",
          //   },

          //   {
          //     id: 5,
          //     name: "Bike Comprehensive",
          //     link: "bike-insurance/bike-comprehensive",
          //   },
          //   {
          //     id: 6,
          //     name: "Bike Zero Depreciation",
          //     link: "bike-insurance/bike-zero-depreciation",
          //   },
          //   {
          //     id: 7,
          //     name: "Bike Third Party",
          //     link: "bike-insurance/bike-third-party",
          //   },
          //   {
          //     id: 8,
          //     name: "Bike Addon Covers",
          //     link: "bike-insurance/bike-addon-covers",
          //   },
          //   {
          //     id: 9,
          //     name: "Bike Claimn Settlement",
          //     link: "bike-insurance/bike-claimn-settlement",
          //   },
          // ],
        },
        // {
        //   id: 4,
        //   name: "Travel Insurance",
        //   link: "/travel-insurence",
        // },
      ],
    },
  //  { userData.data == false ? (
  //  null
  //  ):(
  //   {
  //     id: 3,
  //     name: "Account",
  //     submenu: [
  //       {
  //         id: 0,
  //         name: "My Account",
  //         link: "/dashbord",
  //       },
  //       {
  //         id: 1,
  //         name: "Edit Profile",
  //         link: "/user-profile",
  //       },
  //       {
  //         id: 2,
  //         name: "Track Policy",
  //         link: "/dashbord-policy",
  //       },
  //     ],
  //   },
  //  )}

   ...(userData.data ? [
    {
      id: 4,
      name: "Account",
      submenu: [
        { id: 0, name: "My Account", link: "/dashbord" },
        { id: 1, name: "Edit Profile", link: "/user-profile" },
        { id: 2, name: "Track Policy", link: "/dashbord-policy" },
      ],
    },
  ] : []),







 
    {
      id: 1,
      name: "Blog",
      link: "/blogs",
    },
    {
      id: 2,
      name: "Career",
      link: "/career",
    },
    {
      id: 3,
      name: "calculator's",
      link: "/calculator",
    },

    {
      id: 3,
      name: "Contract US",
      link: "/contractus",
    },
    {
      id: 2,
      name: "About US",
      link: "/about-us",
    },
  ];

  // const renderMenuItems = (items) => {
  //   return items.map((item, index) => {
  //     return (
  //       <MenuItem key={index}>
  //         <Link to={item.link}>{item.name} </Link>
  //         {item.submenu && (
  //           <ul>
  //             {item.submenu.map((subItem, subIndex) => (
  //               <li key={subIndex}>
  //                 <Link to={subItem.link}>{subItem.name}</Link>
  //                 {subItem.childsubmenu && (
  //                   <ul>
  //                     {subItem.childsubmenu.map((nestedItem, nestedIndex) => (
  //                       <li key={nestedIndex}>
  //                         <Link to={nestedItem.link}>{nestedItem.name}</Link>
  //                       </li>
  //                     ))}
  //                   </ul>
  //                 )}
  //               </li>
  //             ))}
  //           </ul>
  //         )}
  //       </MenuItem>
  //     );
  //   });
  // };

  const renderChildSubmenu = (childsubmenu) => {
    return (
      <ul className="childsubmenu">
        {childsubmenu.map((childItem) => (
          <li key={childItem.id} className="childli">
            <span>
            
            </span>
            <Link to={childItem.link} onClick={toggleDrawer(false)}>
              {childItem.name}
            </Link>
          </li>
        ))}
      </ul>
    );
  };

  const [openSubmenuIndex, setOpenSubmenuIndex] = useState(null);
  const [openChildSubmenuIndex, setOpenChildSubmenuIndex] = useState({});
  const toggleSubmenu = (index) => {
    if (openSubmenuIndex === index) {
      setOpenSubmenuIndex(null);
    } else {
      setOpenSubmenuIndex(index);
    }
  };

  const toggleChildSubmenu = (parentIndex, index) => {
    setOpenChildSubmenuIndex((prevState) => ({
      ...prevState,
      [parentIndex]: prevState[parentIndex] === index ? null : index,
    }));
  };

  const drawerRef = useRef(null);

  useEffect(() => {
    // Handle clicks outside the drawer to close it
    const handleClickOutside = (event) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        toggleDrawer(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [toggleDrawer]);

  const userLogin = () => {
    setOpen(false); // Close the drawer or perform any other action
    dispatch(updatedLoginPopup(true)); // Update the login popup
  };

  
 
  const [showHeaderSticky, setShowHeaderSticky] = useState(false);

  const stickyHeaderPaths = [
    '/healthStep1',
    '/healthStep2',
    '/healthStep3',
    '/healthStep4',
    '/plans',
    '/afterplanfatch',
    '/proposal',
    '/medicaldetails',
    '/kyc',
    '/term-insurance/termstep1',
    '/term-insurance/termstep2',
    '/term-insurance/termstep3',
    '/term-insurance/termfetchplanes',
    '/term-insurance/termsummery',
    '/compairpolicy',
    '/health/payment/success',
    '/health/payment/failed',
    '/health/payment/process',
    "/compairpolicy"
  ];


  const checkHeaderType = () => {
    const currentPath = window.location.pathname;
    const isSticky = stickyHeaderPaths.some(path => currentPath.startsWith(path));
    setShowHeaderSticky(isSticky);
  };

  useEffect(() => {
    checkHeaderType(); // Check header type on initial load

    // Set an interval to check the pathname every second
    const intervalId = setInterval(checkHeaderType, 1000);

    // Cleanup the interval on component unmount
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <>
      <div>
        {loginPopup.show ? <Login /> : null}
        {otpPopup.show ? <Otp /> : null}

        <nav className={showHeaderSticky ? "Navbar" : "Navbar2"}>
          <div className="logo">
            <button onClick={() => setShowHambody(!showhambody)}>
              <HamburgerIcon open={isOpen}>
                <Bar color="#FFD700" />
                <Bar color="#32CD32" />
                <Bar color="#1E90FF" />
              </HamburgerIcon>
            </button>
            <Link className="navbar-brand" to="/">
              <FastImage
                url={"Website%20Img/beemaone.jpg?updatedAt=1718802389171"}
                width={auto}
                height={auto}
                alt={"logo"}
                src={"Website%20Img/beemaone.jpg?updatedAt=1718802389171"}
              />
            </Link>
          </div>

          <div className="Menubar">
            <ul>
              <li
                className="dropdown nav-dropdown"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                // onClick={handleMouseEnter}
              >
                <a href="#" className="menuahrf">
                  Insurance <IoMdArrowDropdown style={{ marginLeft: "6px" }} />
                </a>

                {showBox && (
                  <div className="nav-dropdown-content">
                    <div className="flexdisplay outer">
                      <li className="underDropdown leftsidedrop">
                        <div onMouseEnter={handleHealthEnter}>
                          <a
                            onClick={() => {
                              nav("/health-insurance");
                              handleMouseLeave();
                            }}
                          >
                            <FastImage
                              className={"car-logo-header"}
                              url={
                                "Website%20Img/health-insurance.png?updatedAt=1718802388983"
                              }
                              width={auto}
                              height={auto}
                              alt={"health-logo"}
                              src={
                                "Website%20Img/health-insurance.png?updatedAt=1718802388983"
                              }
                            />
                            Health Insurance <IoIosArrowForward />
                            {/* <span className="underarrowspan">
                            <FaChevronRight />
                          </span> */}
                          </a>
                        </div>

                        <div onMouseEnter={handleTermEnter}>
                          <a
                            onClick={() => {
                              nav("/term-insurance");
                              handleMouseLeave();
                            }}
                          >
                            <FastImage
                              className={"car-logo-header"}
                              url={
                                "Website%20Img/term-insurance.png?updatedAt=1718802388625"
                              }
                              width={auto}
                              height={auto}
                              alt={"Term-logo"}
                              src={
                                "Website%20Img/term-insurance.png?updatedAt=1718802388625"
                              }
                            />
                            Terms Insurance <IoIosArrowForward />
                       
                          </a>
                        </div>
                        <div onMouseEnter={handleCarEnter}>
                          <a
                            onClick={() => {
                              nav("/car-insurance");
                              handleMouseLeave();
                            }}
                          >
                            <FastImage
                              className={"car-logo-header"}
                              url={
                                "Website%20Img/car-insurance.png?updatedAt=1718802388747"
                              }
                              width={auto}
                              height={auto}
                              alt={"car-logo"}
                              src={
                                "Website%20Img/car-insurance.png?updatedAt=1718802388747"
                              }
                            />
                            Car Insurance <IoIosArrowForward />
                       
                          </a>
                        </div>
                        <div onMouseEnter={handleBikeEnter}>
                          <a
                            onClick={() => {
                              nav("/bike-insurance");
                              handleMouseLeave();
                            }}
                          >
                            <FastImage
                              url={
                                "Website%20Img/bike-insurance.png?updatedAt=1718802388764"
                              }
                              width={auto}
                              height={auto}
                              alt={"bike-logo"}
                              src={
                                "Website%20Img/bike-insurance.png?updatedAt=1718802388764"
                              }
                            />
                            Bike Insurance <IoIosArrowForward />
                  
                          </a>
                        </div>
{/* 
                        <div onMouseEnter={handleBikeEnter}>
                          <a
                            onClick={() => {
                              nav("/travel-insurence");
                              handleMouseLeave();
                            }}
                          >
                            <FastImage
                              url={
                                "Website%20Img/travel-insurance.png?updatedAt=1718709460988"
                              }
                              width={auto}
                              height={auto}
                              alt={"travel-logo"}
                              src={
                                "Website%20Img/travel-insurance.png?updatedAt=1718709460988"
                              }
                            />
                            Travel Insurance <IoIosArrowForward />
                          
                          </a>
                        </div> */}
                      </li>
                      <li className="rightDropdown">
                        {showcar && (
                          <div
                            className="flexdisplay childdrop container"
                            onMouseEnter={handleCarEnter}
                          >
                            <div className="col-md-6 underdropFloater">
                              <div className="underdrop">
                              <a
                                  className="childlink"
                                  onClick={() => {
                                    nav("/car-insurance");
                                    handleMouseLeave();
                                  }}
                                >
                                  <FaCarOn style={{ marginRight: "10px" }} />
                                  Car Insurance
                                </a>
                                <a
                                  className="childlink"
                                  onClick={() => {
                                    nav("/car-insurance/compare-quotes");
                                    handleMouseLeave();
                                  }}
                                >
                                  <FaCarOn style={{ marginRight: "10px" }} />
                                  Compare Your Quotes{" "}
                                </a>
                                <a
                                  className="childlink"
                                  onClick={() => {
                                    nav("car-insurance/cashless-garage");
                                    handleMouseLeave();
                                  }}
                                >
                                  <FaCarOn style={{ marginRight: "10px" }} />
                                  Cashless Garage{" "}
                                </a>
                                <a
                                  className="childlink"
                                  onClick={() => {
                                    nav("/car-insurance/premium-calculator");
                                    handleMouseLeave();
                                  }}
                                >
                                  <FaCarOn style={{ marginRight: "10px" }} />
                                  Premium Calculator{" "}
                                </a>
                              </div>
                            </div>

                            <div className="col-md-6 underdropFloater">
                              <div className="underdrop">
                                <a
                                  className="childlink"
                                  onClick={() => {
                                    nav("/car-insurance/renewal");
                                    handleMouseLeave();
                                  }}
                                >
                                  <FaCarOn style={{ marginRight: "10px" }} />
                                  Renewal{" "}
                                </a>
                                <a
                                  className="childlink"
                                  onClick={() => {
                                    nav("/car-insurance/addoncover");
                                    handleMouseLeave();
                                  }}
                                >
                                  <FaCarOn style={{ marginRight: "10px" }} />
                                  Add on cover
                                </a>
                                <a
                                  className="childlink"
                                  onClick={() => {
                                    nav("/car-insurance/claimn-settlement");
                                    handleMouseLeave();
                                  }}
                                >
                                  <FaCarOn style={{ marginRight: "10px" }} />
                                  Claimn Settlement{" "}
                                </a>
                              </div>
                            </div>
                          </div>
                        )}

                        {showbike && (
                          <div className="flexdisplay childdrop container">
                            <div className="col-md-6 underdropFloater">
                              <div className="underdrop">

                              <a
                                  className="childlink"
                                  onClick={() => {
                                    nav("/bike-insurance");
                                    handleMouseLeave();
                                  }}
                                >
                                  <RiEBikeFill
                                    style={{ marginRight: "10px" }}
                                  />
                                  Bike Insurance{" "}
                                </a>




                                {/* <a
                                  className="childlink"
                                  onClick={() => {
                                    nav("/bike-insurance/bike-compare-quotes");
                                    handleMouseLeave();
                                  }}
                                >
                                  <RiEBikeFill
                                    style={{ marginRight: "10px" }}
                                  />
                                  Compare Your Quotes{" "}
                                </a>
                                <a
                                  className="childlink"
                                  onClick={() => {
                                    nav("/bike-insurance/bike-companies");
                                    handleMouseLeave();
                                  }}
                                >
                                  <RiEBikeFill
                                    style={{ marginRight: "10px" }}
                                  />
                                  Companies{" "}
                                </a>
                                <a
                                  className="childlink"
                                  onClick={() => {
                                    nav(
                                      "/bike-insurance/bike-cashless-garages"
                                    );
                                    handleMouseLeave();
                                  }}
                                >
                                  <RiEBikeFill
                                    style={{ marginRight: "10px" }}
                                  />{" "}
                                  Cashless Garages
                                </a>
                                <a
                                  className="childlink"
                                  onClick={() => {
                                    nav(
                                      "/bike-insurance/bike-stand-alone-garages"
                                    );
                                    handleMouseLeave();
                                  }}
                                >
                                  <RiEBikeFill
                                    style={{ marginRight: "10px" }}
                                  />
                                  Standalone Own Damage{" "}
                                </a>
                                <a
                                  className="childlink"
                                  onClick={() => {
                                    nav("/bike-insurance/bike-renewal");
                                    handleMouseLeave();
                                  }}
                                >
                                  <RiEBikeFill
                                    style={{ marginRight: "10px" }}
                                  />{" "}
                                  Renewal
                                </a> */}
                              </div>
                            </div>

                            {/* <div className="col-md-6 underdropFloater">
                              <div className="underdrop">
                                <a
                                  className="childlink"
                                  onClick={() => {
                                    nav("/bike-insurance/bike-comprehensive");
                                    handleMouseLeave();
                                  }}
                                >
                                  <RiEBikeFill
                                    style={{ marginRight: "10px" }}
                                  />
                                  Comprehensive{" "}
                                </a>
                                <a
                                  className="childlink"
                                  onClick={() => {
                                    nav(
                                      "/bike-insurance/bike-zero-depreciation"
                                    );
                                    handleMouseLeave();
                                  }}
                                >
                                  <RiEBikeFill
                                    style={{ marginRight: "10px" }}
                                  />
                                  Zero Depreciation{" "}
                                </a>
                                <a
                                  className="childlink"
                                  onClick={() => {
                                    nav("/bike-insurance/bike-third-party");
                                    handleMouseLeave();
                                  }}
                                >
                                  <RiEBikeFill
                                    style={{ marginRight: "10px" }}
                                  />
                                  Third Party{" "}
                                </a>
                                <a
                                  className="childlink"
                                  onClick={() => {
                                    nav("/bike-insurance/bike-addon-covers");
                                    handleMouseLeave();
                                  }}
                                >
                                  <RiEBikeFill
                                    style={{ marginRight: "10px" }}
                                  />
                                  Add on Cover{" "}
                                </a>
                                <a
                                  className="childlink"
                                  onClick={() => {
                                    nav(
                                      "/bike-insurance/bike-claimn-settlement"
                                    );
                                    handleMouseLeave();
                                  }}
                                >
                                  <RiEBikeFill
                                    style={{ marginRight: "10px" }}
                                  />
                                  Claimn Settlement{" "}
                                </a>
                              </div>
                            </div> */}
                          </div>
                        )}

                        {showhealth && (
                          <div className="flexdisplay childdrop container">
                            <div className="col-md-6 underdropFloater">
                              <div className="underdrop">
                                <a
                                  className="childlink"
                                  onClick={() => {
                                    nav(
                                      "/health-insurance/health-compare-quotes"
                                    );
                                    handleMouseLeave();
                                  }}
                                >
                                  <MdHealthAndSafety
                                    style={{ marginRight: "10px" }}
                                  />
                                  Compare Your Quotes{" "}
                                </a>

                                <a
                                  className="childlink"
                                  onClick={() => {
                                    nav("/health-insurance/health-co-payment");
                                    handleMouseLeave();
                                  }}
                                >
                                  <MdHealthAndSafety
                                    style={{ marginRight: "10px" }}
                                  />
                                  Health Co Payment{" "}
                                </a>

                                <a
                                  className="childlink"
                                  onClick={() => {
                                    nav(
                                      "/health-insurance/parents-health-insurance"
                                    );
                                    handleMouseLeave();
                                  }}
                                >
                                  <MdHealthAndSafety
                                    style={{ marginRight: "10px" }}
                                  />
                                  Parents Health Insurence{" "}
                                </a>

                                <a
                                  className="childlink"
                                  onClick={() => {
                                    nav(
                                      "/health-insurance/cashless-health-insurance"
                                    );
                                    handleMouseLeave();
                                  }}
                                >
                                  <MdHealthAndSafety
                                    style={{ marginRight: "10px" }}
                                  />
                                  Cashless Health Insurence{" "}
                                </a>

                                <a
                                  className="childlink"
                                  onClick={() => {
                                    nav(
                                      "/health-insurance/health-clame-settelment"
                                    );
                                    handleMouseLeave();
                                  }}
                                >
                                  <MdHealthAndSafety
                                    style={{ marginRight: "10px" }}
                                  />
                                  Health Insurence Claim Settelment{" "}
                                </a>

                      
                              </div>
                            </div>

                            <div className="col-md-6 underdropFloater">
                              <div className="underdrop">
                              <a
                                  className="childlink"
                                  onClick={() => {
                                    nav(
                                      "/health-insurance/family-health-insurance"
                                    );
                                    handleMouseLeave();
                                  }}
                                >
                                  <MdHealthAndSafety
                                    style={{ marginRight: "10px" }}
                                  />
                                  Family Health Insurance{" "}
                                </a>

                                <a
                                  className="childlink"
                                  onClick={() => {
                                    nav(
                                      "/health-insurance/critical-illness-insurance"
                                    );
                                    handleMouseLeave();
                                  }}
                                >
                                  <MdHealthAndSafety
                                    style={{ marginRight: "10px" }}
                                  />
                                  Critical Illness Insurance
                                </a>
                                <a
                                  className="childlink"
                                  onClick={() => {
                                    nav("/health-insurance/health-topup-plan");
                                    handleMouseLeave();
                                  }}
                                >
                                  <MdHealthAndSafety
                                    style={{ marginRight: "10px" }}
                                  />
                                  Health Top-Up Plan{" "}
                                </a>
                                <a
                                  className="childlink"
                                  onClick={() => {
                                    nav(
                                      "/health-insurance/senior-citizen-insurance"
                                    );
                                    handleMouseLeave();
                                  }}
                                >
                                  <MdHealthAndSafety
                                    style={{ marginRight: "10px" }}
                                  />
                                  Senior Citizen Insurance
                                </a>
                                <a
                                  className="childlink"
                                  onClick={() => {
                                    nav(
                                      "/health-insurance/individual-health-insurance"
                                    );
                                    handleMouseLeave();
                                  }}
                                >
                                  <MdHealthAndSafety
                                    style={{ marginRight: "10px" }}
                                  />
                                  Individual Health Insurance{" "}
                                </a>
                              </div>
                            </div>
                          </div>
                        )}

                        {showterm && (
                          <div className="flexdisplay childdrop container">
                            {/* <div className="col-md-6 underdropFloater">
                              <div className="underdrop">
                                <a
                                  className="childlink"
                                  onClick={() => {
                                    nav("/term-insurance/term-compare-quotes");
                                    handleMouseLeave();
                                  }}
                                >
                                  <MdInterpreterMode
                                    style={{ marginRight: "10px" }}
                                  />
                                  Compare Your Quotes{" "}
                                </a>
                                <a
                                  className="childlink"
                                  onClick={() => {
                                    nav(
                                      "/term-insurance/term-compare-insurance"
                                    );
                                    handleMouseLeave();
                                  }}
                                >
                                  <MdInterpreterMode
                                    style={{ marginRight: "10px" }}
                                  />
                                  Compare Insurance{" "}
                                </a>
                                <a
                                  className="childlink"
                                  onClick={() => {
                                    nav("/term-insurance/term-nri-plans");
                                    handleMouseLeave();
                                  }}
                                >
                                  <MdInterpreterMode
                                    style={{ marginRight: "10px" }}
                                  />
                                  NRI Term Plans
                                </a>
                              </div>
                            </div> */}

                            <div className="col-md-6 underdropFloater">
                              <div className="underdrop">
                              <a
                                  className="childlink"
                                  onClick={() => {
                                    nav("/term-insurance");
                                    handleMouseLeave();
                                  }}
                                >
                                  <MdInterpreterMode
                                    style={{ marginRight: "10px" }}
                                  />
                                  Term Insurance
                                </a>
                                {/* <a
                                  className="childlink"
                                  onClick={() => {
                                    nav("/term-insurance/term-insurance-inner");
                                    handleMouseLeave();
                                  }}
                                >
                                  <MdInterpreterMode
                                    style={{ marginRight: "10px" }}
                                  />
                                  Term Insurance
                                </a> */}
                                {/* <a
                                  className="childlink"
                                  onClick={() => {
                                    nav("/term-insurance/term-tax-benefits");
                                    handleMouseLeave();
                                  }}
                                >
                                  <MdInterpreterMode
                                    style={{ marginRight: "10px" }}
                                  />
                                  Tax Benefits
                                </a>
                                <a
                                  className="childlink"
                                  onClick={() => {
                                    nav("/term-insurance/family-insurance");
                                    handleMouseLeave();
                                  }}
                                >
                                  <MdInterpreterMode
                                    style={{ marginRight: "10px" }}
                                  />
                                  Family/Group/Senior-Citizens{" "}
                                </a> */}
                              </div>
                            </div>
                          </div>
                        )}
                      </li>
                    </div>
                  </div>
                )}
              </li>
              <li
                className="dropdown"
                onMouseEnter={handleDropdown}
                onMouseLeave={handleDropdown}
                onClick={() => {
                  nav("/blogs");
                }}
              >
                <Link to={"/blogs"} className="menuahrf">
                  Blogs
                </Link>
              </li>
              <li
                className="dropdown"
                onMouseEnter={handleDropdown}
                onMouseLeave={handleDropdown}
                onClick={() => {
                  nav("/career");
                }}
              >
                <Link className="menuahrf">Career</Link>
              </li>

              <li
                className="dropdown"
                onMouseEnter={handleDropdown}
                onMouseLeave={handleDropdown}
                onClick={() => {
                  nav("/about-us");
                }}
              >
                <Link className="menuahrf">About Us</Link>
              </li>

              {userData.data == false ? (
                <a
                  className="loginbutton"
                  onClick={(e) => dispatch(updatedLoginPopup(true))}
                >
                  Login
                </a>
              ) : (
                <div
                  className="dropdown-container"
                  onMouseEnter={() => {
                    handleAccountEnter();
                  }}
                  onMouseLeave={() => {
                    handleAccountLeave();
                  }}
                >
                  <a
                    className="myaccount"
                    onClick={() => {
                      handleDropdownToggle();
                    }}
                  >
                    <FaUserCircle
                      style={{ marginBottom: "5px", marginRight: "5px" }}
                      fontSize={"14pt"}
                    />
                    Hey&nbsp;
                    {trimmedName ? trimmedName : "User"}
                  </a>

                  {showAccount && (
                    <div className="myaccountdrop">
                      <Link to={"/dashbord"} className="MyaccountDropLink"><RiAccountCircleFill style={{fontSize:'15pt',marginRight:'5px'}}/>My Account</Link>
                      <Link to={"/user-profile"} className="MyaccountDropLink"><FaUserEdit style={{fontSize:'15pt',marginRight:'5px'}}/>Edit Profile</Link>
                      <a href="/dashbord-policy" className="MyaccountDropLink"><MdPolicy style={{fontSize:'15pt',marginRight:'5px'}}/>Track Policy</a>
                      <a onClick={heandelLogout} className="MyaccountDropLink"><BiLogOutCircle style={{fontSize:'15pt',marginRight:'5px'}}/>Logout</a>
                    </div>
                  )}
                </div>
              )}
              <button
                className="callingbutton"
               data-bs-toggle="modal" data-bs-target="#exampleModalCenter"
              >
                <RiCustomerService2Fill />
              </button>
              {/* {showCallback && (
                <CallbackModal setShowCallback={setShowCallback} data-toggle="modal" data-target="#exampleModalCenter"/>
              )} */}

              <div
                class="modal fade"
                id="exampleModalCenter"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                data-bs-backdrop="static"
                aria-hidden="true"
                style={{backgroundColor:"rgba(0, 0, 0, 0.5)"}}
              >
                <div
                  class="modal-dialog modal-dialog-centered callback-modal-adj"
                  role="document"
                >
                  <div class="modal-content" style={{ padding: "0px" }}>
                    <div class="modal-header callback-header-adj">
                      <button
                        type="button"
                        class="close"
                        className="modal-close-home"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body " style={{ padding: "0px" }}>
                      <div className="row six-section-of-fifth photohide-area">
                        <div className="col-sm-12 col-md-4 col-lg-5 hide-mobile-img callbackphoto photohide">
                          <FastImage
                            className={"backphoto-home"}
                            url={
                              "Website%20Img/call-back-agent.png?updatedAt=1718704826908"
                            }
                            width={auto}
                            height={auto}
                            alt={"backphoto-logo"}
                            src={
                              "Website%20Img/call-back-agent.png?updatedAt=1718704826908"
                            }
                          />
                          <h3>Request a Callback for Expert Advice</h3>
                          <hr className="callback-hr"></hr>
                          <div className="callback-photo-section">
                            <div className="callback-icon-div">
                              <div className="callback-icon-round">
                                <MdOutlineSupportAgent className="callback-icon-main" />
                              </div>
                              <p>
                              24/7 Customer Care Service Available
                              </p>
                            </div>

                            <div className="callback-icon-div up-div">
                              <div className="callback-icon-round">
                                <BiSupport className="callback-icon-main" />
                              </div>
                              <p>
                              Supportive Customer Care Service Team
                              </p>
                            </div>

                            <div className="callback-icon-div up-div">
                              <div className="callback-icon-round">
                                <BiLogoTelegram className="callback-icon-main" />
                              </div>
                              <p>
                              Experienced Team of Experts Shall Answer Your Queries
                              </p>
                            </div>

                            <div className="callback-icon-div up-div">
                              <div className="callback-icon-round">
                                <CgWebsite className="callback-icon-main" />
                              </div>
                              <p>
                              Consistent Customer Service Communications and Quicker Response Time
                              </p>
                            </div>
                            <div className="callback-icon-div up-div">
                              <div className="callback-icon-round">
                                <GrUserExpert className="callback-icon-main" />
                              </div>
                              <p>
                              Book A Call Back for Free Consultations With Our Experts
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-8 col-lg-7 callbacktext calbacktext-adj">
                          <div className="call-back-agent-form">
                            <h6>Request a Call Back</h6>
                            <form className="callbackForm">
                              <div className="d-flex align-items-center form-input">
                                <div className="form-inner-input form-group callform">
                                  <label
                                    htmlFor="form_name"
                                    style={{
                                      color: "rgb(119 109 109 / 94%)",
                                    }}
                                  >
                                    Name <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    id="_name"
                                    name="name"
                                    className="form-control"
                                    placeholder="Enter your name"
                                    onChange={handleInputChange}
                                    value={formDataCall.name}
                                  />
                                </div>
                                <div className="form-inner-input form-group callback callform">
                                  <label
                                    htmlFor="form_need"
                                    style={{
                                      color: "rgb(119 109 109 / 94%)",
                                    }}
                                  >
                                    Product{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <select
                                    id="product_name"
                                    name="product_name"
                                    className="form-control call-option"
                                    onChange={handleInputChange}
                                    value={formDataCall.product_name}
                                  >
                                    <option
                                      selected=""
                                      disabled=""
                                      className="call-option"
                                    >
                                      Select product
                                    </option>
                                    <option
                                      value="Health Insurance"
                                      className="call-option"
                                    >
                                      Health Insurance
                                    </option>
                                    <option
                                      value="Motor Insurance"
                                      className="call-option"
                                    >
                                      Motor Insurance
                                    </option>
                                    <option
                                      value="Term Insurance"
                                      className="call-option"
                                    >
                                      Term Insurance
                                    </option>
                                    <option
                                      value="Travel Insurance"
                                      className="call-option"
                                    >
                                      Travel Insurance
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div className="d-flex align-items-center form-input ">
                                <div className="form-inner-input form-group callform">
                                  <label
                                    htmlFor="phone"
                                    style={{
                                      color: "rgb(119 109 109 / 94%)",
                                    }}
                                  >
                                    Mobile Number{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    id="mobile_number"
                                    name="mobile_number"
                                    placeholder="Enter your mobile number"
                                    className="form-control "
                                    maxLength={10}
                                    onInput={(e) => {
                                      e.target.value = e.target.value
                                        .replace(/[^0-9.]/g, "")
                                        .replace(/(\..*?)\..*/g, "$1");
                                    }}
                                    onChange={handleInputChange}
                                    required=""
                                    value={formDataCall.mobile_number}
                                  />
                                </div>
                                <div className="form-inner-input form-group callback ">
                                  <label
                                    htmlFor="Time Slot"
                                    style={{
                                      color: "rgb(119 109 109 / 94%)",
                                    }}
                                    className="call-option"
                                  >
                                    Time Slot{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <select
                                    id="time_slot"
                                    name="time_slot"
                                    className="form-control call-option"
                                    onChange={handleInputChange}
                                    value={formDataCall.time_slot}
                                  >
                                    <option
                                      selected=""
                                      disabled=""
                                      className="call-option"
                                    >
                                      Select preferred time slot
                                    </option>
                                    <option value="10:00 AM">10:00 AM</option>
                                    <option value="11:00 AM">11:00 AM</option>
                                    <option value="12:00 PM">12:00 PM</option>
                                    <option value="01:00 PM">01:00 PM</option>
                                    <option value="02:00 PM">02:00 PM</option>
                                    <option value="03:00 PM">03:00 PM</option>
                                    <option value="04:00 PM">04:00 PM</option>
                                    <option value="05:00 PM">05:00 PM</option>
                                  </select>
                                </div>
                              </div>
                              <div className="d-flex align-items-center form-input">
                                <div className="form-inner-input form-group text-area-group">
                                  <label
                                    htmlFor="form_message"
                                    style={{
                                      color: "rgb(119 109 109 / 94%)",
                                    }}
                                  >
                                    Message{" "}
                                    <span className="text-danger txtOnly">
                                      *
                                    </span>
                                  </label>
                                  <textarea
                                    id="message"
                                    name="message"
                                    className="form-control"
                                    placeholder="Type message here..."
                                    rows={6}
                                    defaultValue={""}
                                    onChange={handleInputChange}
                                 value={formDataCall.message}
                                  />
                                </div>
                              </div>
                              <div className="d-flex align-items-center form-input">
                                <div className="form-inner-input form-group callback-detail">
                              
                                  <div className="captcha-div callback-captcha-div">
                                    <p className="captcha-para callback-para">{captcha}</p>
                                    <FaArrowRotateRight
                                      style={{
                                        height: "20px",
                                        width: "30px",
                                        color: "#555252",
                                        marginLeft: "9px",
                                      }}
                                      onClick={() => {
                                        handleRegenerate();
                                      }}
                                    />
                                  </div>
                                  <input
                                    name="code"
                                    id="code"
                                    className="form-control codeinp call-option"
                                    placeholder="Enter captcha code"
                                    type="text"
                                    value={userInput}
                                    onChange={(event) => {
                                      setUserInput(event.target.value);
                                      handleInputChange(event);
                                    }}
                                    style={{ fontFamily: "Poppins!important" , marginRight:"-20px", marginLeft:"20px"}}
                                  />
                                </div>
                                <label id="captchaMsg" className="error" />
                              </div>



                              <div className="blue-btn" id="callBackFormButton">
                                <a
                                  onClick={handleButtonClick}
                                  className="call-option"
                                >
                                  Submit
                                  <FastImage
                                    url={
                                      "Website%20Img/button-arrow.png?updatedAt=1718712281133"
                                    }
                                    width={"auto"}
                                    height={"auto"}
                                    alt={"arrow"}
                                    src={
                                      "Website%20Img/button-arrow.png?updatedAt=1718712281133"
                                    }
                                  />
                                </a>
                              </div>
                            </form>
                          </div>
                        </div>

                        {popup.isOpen ? (
                          <div className="alert alert-success" role="alert">
                            <strong>Successful: </strong>Your Callback Request
                            is Successfully Submitted.
                            <button
                              type="button"
                              className="close"
                              data-dismiss="alert"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ul>
          </div>
          <GiHamburgerMenu onClick={toggleDrawer(true)} className="nav-ham" />
        </nav>
      </div>

     

      <div>
        <Drawer anchor="right" open={open} onClose={() => toggleDrawer(false)}>
          <Box sx={{ width: 340 }} role="presentation" ref={drawerRef}>
            <div className="bannermenu">
              <div className="crossdivbtn">
                <button onClick={toggleDrawer(false)}>
                  <RxCross2 />
                </button>
                <FastImage
                  url={"Website%20Img/Final_logo_B.jpg?updatedAt=1710323260497"}
                  width={auto}
                  height={auto}
                  alt="Logo"
                />
              </div>

              <div className="loggindivsep">
                {userData.data == false ? (
                  <button onClick={userLogin}>Login</button>
                ) : (
                  <p>
                    <FaCircleUser /> &nbsp; Hey{" "}
                    {trimmedName ? trimmedName : "User"}
                  </p>
                )}
              </div>
            </div>

            <div className="mobileul">
              {navItems.map((data, index) => (
                <li key={index}>
                  {data.link ? (
                    <Link to={data.link} onClick={toggleDrawer(false)}>
                      {data.name}
                    </Link>
                  ) : (
                    <a
                      onClick={() => {
                        if (data.submenu) {
                          toggleSubmenu(index);
                        }
                        // Close drawer
                      }}
                    >
                      {data.name}
                    </a>
                  )}
                  {data.submenu && (
                    <span
                      onClick={(e) => {
                        e.stopPropagation(); // Prevents triggering onClick for the drawer
                        if (data.submenu) {
                          toggleSubmenu(index);
                        }
                        // Close drawer
                      }}
                    >
                      {openSubmenuIndex === index ? (
                        <FaAngleUp />
                      ) : (
                        <FaAngleDown />
                      )}
                    </span>
                  )}
                  {openSubmenuIndex === index && data.submenu && (
                    <ul className="submenu">
                      {data.submenu.map((subItem) => (
                        <li key={subItem.id}>
                          {subItem.link ? (
                            <Link
                              to={subItem.link}
                              onClick={toggleDrawer(false)}
                            >
                              {subItem.name}
                            </Link>
                          ) : (
                            <a
                              href="javascript:void(0)"
                              onClick={() => {
                                if (subItem.childsubmenu) {
                                  toggleChildSubmenu(index, subItem.id);
                                }
                                // Close drawer
                              }}
                            >
                              {subItem.name}
                            </a>
                          )}
                          {subItem.childsubmenu && (
                            <span
                              onClick={(e) => {
                                e.stopPropagation(); // Prevents triggering onClick for the drawer
                                if (subItem.childsubmenu) {
                                  toggleChildSubmenu(index, subItem.id);
                                }
                                // Close drawer
                              }}
                            >
                              {openChildSubmenuIndex[index] === subItem.id ? (
                                <FaAngleUp />
                              ) : (
                                <FaAngleDown />
                              )}
                            </span>
                          )}
                          {openChildSubmenuIndex[index] === subItem.id &&
                            subItem.childsubmenu &&
                            renderChildSubmenu(subItem.childsubmenu, index)}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
              {
                userData.data == false ? (
 null
                ):(
                  <button className="logoutbtn" onClick={heandelLogout}>
                  Logout
                </button>
                )
              }
        
            </div>
          </Box>
        </Drawer>
      </div>

      <div
        class="modal fade"
        id="editprofilepopup"
        tabindex="-1"
        role="dialog"
        aria-labelledby="editprofilepopup"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered extrawidthEditprofile"
          role="document"
        >
          <div class="modal-content ">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div style={{ margin: "auto" }}>
              <h4>Edit Profile</h4>
            </div>

            <div className="modal-body d-flex flex-column">
              <form className="d-flex flex-column" onSubmit={handleSubmit}>
                <div className="d-flex flex-row mb-3">
                  <div className="flex-fill mr-3">
                    <div className="form-group">
                      <label htmlFor="first_name">First Name</label>

                      <input
                        type="text"
                        className="form-control"
                        id="first_name"
                        name="first_name"
                        value={formData.first_name}
                        placeholder="Enter first name"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="flex-fill">
                    <div className="form-group">
                      <label htmlFor="last_name">Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="last_name"
                        name="last_name"
                        placeholder="Enter last name"
                        value={formData.last_name}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-row mb-3">
                  <div className="flex-fill mr-3">
                    <div className="form-group">
                      <label htmlFor="mobile_number">Mobile Number</label>
                      <input
                        type="text"
                        className="form-control"
                        id="mobile_number"
                        name="mobile_number"
                        placeholder="Enter mobile number"
                        value={formData.mobileNumber}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="flex-fill">
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="Enter email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="d-flex flex-row mb-3">
                  <div className="flex-fill mr-3">
                    <div className="form-group">
                      <label htmlFor="dateOfBirth">Date of Birth</label>
                      <input
                        type="date"
                        className="form-control"
                        name="dob"
                        id="dob"
                        placeholder="Enter date of birth"
                        value={formData.dob}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="flex-fill">
                    <div className="form-group">
                      <label htmlFor="annualIncome">Annual Income</label>
                      <input
                        type="text"
                        className="form-control"
                        id="annual_income"
                        name="annual_income"
                        placeholder="Enter annual income"
                        value={formData.annual_income}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="d-flex flex-row mb-3">
                  <div className="flex-fill mr-3">
                    <div className="form-group">
                      <label htmlFor="vehicleInformation">
                        Vehicle Information
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="vehicle_info"
                        id="vehicle_info"
                        placeholder="Enter vehicle information"
                        value={formData.vehicleInformation}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="flex-fill">
                    <div className="form-group">
                      <label htmlFor="city">City</label>
                      <input
                        type="text"
                        className="form-control"
                        id="city"
                        name="city"
                        placeholder="Enter city"
                        value={formData.city}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="d-flex flex-row mb-3 flexdisplayse">
                  <div className="flex-fill mr-3">
                    <div className="form-group">
                      <label htmlFor="homeAddress">Home Address</label>
                      <textarea
                        type="text"
                        className="form-control addsressEdit"
                        id="home_address"
                        name="home_address"
                        placeholder="Enter home address"
                        value={formData.homeAddress}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="flex-fill mr-3">
                    <div className="form-inner-input form-group check-boxx d-flex">
                      <div
                        className="family-checkbox"
                        onClick={() => handleCheckboxClick("Male")}
                      >
                        <div className="checkbox-wrapper">
                          <div className="boxadd">
                            <div className="imag-bg">
                              <FastImage
                                url={
                                  "Website%20Img/man-self-icon.svg?updatedAt=1718801472073"
                                }
                                width={auto}
                                height={auto}
                                alt={"man-self"}
                                src={
                                  "Website%20Img/man-self-icon.svg?updatedAt=1718801472073"
                                }
                              />
                            </div>
                            <span>Male</span>
                          </div>
                        </div>
                      </div>

                      <div
                        className="family-checkbox"
                        onClick={() => handleCheckboxClick("Female")}
                      >
                        <div className="checkbox-wrapper">
                          <div className="boxadd">
                            <div className="imag-bg">
                              <FastImage
                                url={
                                  "Website%20Img/woman-self-icon.svg?updatedAt=1718801472103"
                                }
                                width={auto}
                                height={auto}
                                alt={"woman-self"}
                                src={
                                  "Website%20Img/woman-self-icon.svg?updatedAt=1718801472103"
                                }
                              />
                            </div>
                            <span>Female</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex flex-row mb-3 flexdisplayse">
                  <div className="flex-fill mr-3">
                    <div className="upload-cv-file">
                      <label className="">
                        <div className="upload-icon">
                          <FileBase64
                            multiple={false}
                            onDone={onFileSubmit}
                            type="file"
                            name="file"
                            className="FileChange"
                            id="file"
                          />

                          <FastImage
                            url={
                              "Website%20Img/upppp.png?updatedAt=1718799402584"
                            }
                            width={auto}
                            height={auto}
                            alt={"upload-file"}
                            src={
                              "Website%20Img/upppp.png?updatedAt=1718799402584"
                            }
                          />
                        </div>
                        <h4>
                          Upload Profile Image
                          <span className="text-danger">*</span>
                        </h4>
                        <span id="selectedFileName" />
                        <p>Drag and drop or browse to choose a file</p>
                        <span>File size: 10MB | File format: pdf, doc</span>
                      </label>
                    </div>
                  </div>

                  <div className="flex-fill flexxdisplaye">
                    <div className="form-inner-input form-group popup-img">
                      <img
                        id="profile-img-tag"
                        src={`${process.env.REACT_APP_MAIN_URL}/uploads/profile_pic/${profilepicture}`}
                        alt="profile image"
                        width="140px"
                        height="150px"
                      />
                    </div>
                  </div>
                </div>
                <button type="submit" class="savebutton">
                  Save changes
                </button>
              </form>
            </div>
            <div className="saveprofile"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;