import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import FastImage from "../FastImage";
import { auto } from "@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core";


const Blog = () => {
  const [footerBlog, setfooterBlog] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}blog/blog_list`
        );
        setfooterBlog(response.data.data);
      } catch (error) {
        console.error("Error fetching blog data:", error);
      }
    };

    fetchData();
  }, []);

  //console.log("Footer Blogs",footerBlog)

  return (
    <>
<div className="fixwidthDivtext">
        <div className="container">
              <div className="tile-text-with-text-shadow">
                <h2>Blogs</h2>
              </div>
           {/* <h2 className="beemaname">Beema<span className="onespan">1</span></h2> */}

              <div className="commonone">
              <h4 className="theheadingcolor">Beema<span className="onespan">1</span> Blogs</h4>
              <div className="yellowline"></div>
              <p className="textformat">
                Welcome to our blog section, where you'll find informative
                articles, helpful tips, and expert insights to enhance your
                knowledge. FAQs
              </p>
              </div>


         
          <div className="row second-section-of-twelve">
            <div className="col-sm-12 col-md-5 col-lg-5">
              <button className="knowmore">
                <div class="blog-left-side-img">
             
				  <FastImage
                      className={"blog-leftimg"}
                      url={"/Website%20Img/beema1_mascot.webp?updatedAt=1718710320617"}                 
                      alt={"beema1-mascot"}
                      src={"/Website%20Img/beema1_mascot.webp?updatedAt=1718710320617"}
                           loading="eager"
					  width={auto}
                      height={auto}
                      />
                </div>
              </button>
            </div>
            <div className="col-sm-12 col-md-7 col-lg-7">
              {/* Blog starting hear */}

              {footerBlog.map((bloges) => {
                const modifiedTitle = bloges.title.replace(/\s+/g, "-");

                const tempDiv = document.createElement("div");
                tempDiv.innerHTML = bloges.blog;
                // Get the text content of the parsed HTML, truncate it, and convert it back to a string
                const truncatedContent = tempDiv.textContent.slice(0, 110);

                return (
                  <Link
                    className="right-side-blog"
                    to={`/blogs/${modifiedTitle}/${bloges.id}`}
                  >
            <div className="blog-right-side-img">
  <img
    srcSet={`${process.env.REACT_APP_MAIN_URL}/uploads/blog_images/${bloges.image}?w=200 200w, ${process.env.REACT_APP_MAIN_URL}/uploads/blog_images/${bloges.image}?w=400 400w, ${process.env.REACT_APP_MAIN_URL}/uploads/blog_images/${bloges.image}?w=600 600w`}
    sizes="(max-width: 600px) 200px, (max-width: 1000px) 400px, 600px"
    src={`${process.env.REACT_APP_MAIN_URL}/uploads/blog_images/${bloges.image}?w=600`}
    style={{
      width: "123px",
      height: "123px",
      objectFit: "cover",
      aspectRatio: "1 / 1",
      borderRadius: "10px",
    }}
    alt="life-care"
    loading="lazy"
  />
</div>

                    <div className="home-blog-text">
                      <div className="hover-arrow">
                  
						<FastImage
                      
                      url={"/Website%20Img/right-arrow.png?updatedAt=1718706342956"}                 
                      alt={"right-arrow"}
                      src={"/Website%20Img/right-arrow.png?updatedAt=1718706342956"}
                           loading="eager"
					  width={auto}
                      height={auto}
                      />

                      </div>
                      <h6>{bloges.category}</h6>
                      <h4>{bloges.title}</h4>
                      <p
                        dangerouslySetInnerHTML={{ __html: truncatedContent }}
                      ></p>
                    </div>
                  </Link>
                );
              })}
              {/* Blog Ending hear */}

              <div className="blue-btn">
                <Link to={"/bloges"}>
                  <span>View All</span> 
	
				  <FastImage
                      
                      url={"/Website%20Img/button-arrow.png?updatedAt=1718712281133"}                 
                      alt={"button-arrow"}
                      src={"/Website%20Img/button-arrow.png?updatedAt=1718712281133"}
                           loading="eager"
					  width={auto}
                      height={auto}
                      />
                </Link>
              </div>
            </div>
          </div>

        </div>
        </div>
    </>
  );
};

export default Blog;
