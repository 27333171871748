import React, { useEffect, useState } from "react";
import "../../CommonCssFiles/planstyle.css";
import Filter from "./Filter";

import FetchPlan from "./FetchPlan";
import EditDetails from "./EditDetails";
import EditCity from "./EditCity";
import EditPED from "./EditPED";
import Loader from "../../../AllPageComponents/Loader";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Redirect from "../../../AllPageComponents/Rederict";
import { RxCross1 } from "react-icons/rx";
import { fetchQuickQuote } from "../../Api/Api";
import { useDispatch } from "react-redux";
import { countUniquePlans } from "../../helper/common";
import Swal from "sweetalert2";
import { IoMdArrowBack } from "react-icons/io";
import { Helmet } from "react-helmet-async";

const Plans = (ondatapass) => {
  const [apiResponse, setApiResponse] = useState();
  const [planData, setPlanData] = useState("");
  const [showside, setShowside] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [updateLoader, setUpdateLoader] = useState();
  const [nextstep, setNextStep] = useState([true, false, false]);
  const [sidedata, setSidedata] = useState({});
  const [planCount, setPlanCount] = useState();
  const [onloadingData, setOnLoadingData] = useState("");

  const [stopLoading, setStopLoading] = useState(false);
  const navigate = useNavigate();

  const handleError = (message) => {
    Swal.fire({
      title: "Error!",
      text: message,
      icon: "error",
      confirmButtonText: "OK",
    }).then((result) => {
      if (result.isConfirmed) {
        // Reload the page when user clicks "OK"
        window.location.reload();
      }
    });
    setStopLoading(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const unique_id = params.get("req_id");
  const plan_id = params.get("plan_id");
  const c_id = params.get("c_id");

  const fetchData = async () => {
    try {
      const response = await fetchQuickQuote(unique_id);
      if (response.data.response == null) {
        setStopLoading(true);

        handleError("Oops there is an Error ");
      }
      setApiResponse(response.data);
      setSidedata(response.data.request);
      setOnLoadingData(response.data.response);

      let planNum = countUniquePlans(response.data.response);
      console.log("the plancount in fetchdata", planNum);

      setPlanCount(planNum);
    } catch (error) {
      console.log("Error in plans page data fetching", error);
      setStopLoading(true);

      handleError("Oops there is an Error");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let data = countUniquePlans(planData);
    setPlanCount(data);
  }, [planData]);

  if (!apiResponse && stopLoading == false) {
    return (
      <div>
        <Loader />
      </div>
    );
  } else if (apiResponse ? apiResponse.response == null : "") {
    setStopLoading(true);

    handleError("Oops there is an Error");
  }

  function getFirstName(fullName) {
    // Split the full name by spaces
    const nameParts = fullName.split(" ");

    // Return the first part of the name
    return nameParts[0];
  }

  const handleClicknextstep = (stepNumber) => {
    const updatedNextStep = [...nextstep];
    if (stepNumber === 1) {
      updatedNextStep[0] = false;
      updatedNextStep[1] = true;
      updatedNextStep[2] = false;
      updatedNextStep[3] = false;
      updatedNextStep[4] = false;
    } else if (stepNumber === 2) {
      updatedNextStep[0] = false;
      updatedNextStep[1] = false;
      updatedNextStep[2] = true;
      updatedNextStep[3] = false;
      updatedNextStep[4] = false;
    } else if (stepNumber === 3) {
      updatedNextStep[0] = false;
      updatedNextStep[1] = false;
      updatedNextStep[2] = false;
      updatedNextStep[3] = true;
      updatedNextStep[4] = false;
    } else if (stepNumber === 4) {
      updatedNextStep[0] = true;
      updatedNextStep[1] = false;
      updatedNextStep[2] = false;
      updatedNextStep[3] = false;
      updatedNextStep[4] = true;
    }
    setNextStep(updatedNextStep);
  };

  const handelsidebar = () => {
    setShowside(true);
  };

  const handleUpdatedData = async () => {
    setUpdateLoader(false);
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      {stopLoading == false && (
        <>
          <Helmet>
            <title>Healthinsurance plans</title>

            {/* <meta name="description" content="A brief description of your page for SEO." /> */}
            <meta
              name="keywords"
              content="Insurance Plan, Insurance Quotes, buy insurance"
            />
          </Helmet>

          <div className="container hight">
            <div className="backdivse">
              <button onClick={goBack} className="backbuttontwo">
                <IoMdArrowBack /> &nbsp; Back to Step4
              </button>
            </div>
            <div className="row">
              <div className="centerdiv ">
                <div className="edit">
                  <div className="d-flex justify-content-between showbarresult">
                    <p className="editp">
                      {" "}
                      {planCount} plan{planCount !== 1 ? "s" : ""} found for{" "}
                      {getFirstName(apiResponse.request.ProposalName)} at{" "}
                      {apiResponse.request.District
                        ? apiResponse.request.District
                        : ""}
                      {`(${
                        apiResponse.request.PinCode
                          ? apiResponse.request.PinCode
                          : ""
                      })`}
                    </p>
                    <button
                      onClick={() => {
                        handelsidebar();
                      }}
                      className="editb"
                    >
                      {" "}
                      <i
                        className="fa fa-pencil-square-o mx-2"
                        aria-hidden="true"
                      ></i>
                      Edit Details
                    </button>
                  </div>
                </div>
              </div>
              <Filter
                apiResponse={apiResponse}
                setApiResponse={setApiResponse}
                planData={planData}
                setPlanData={setPlanData}
                onloadingData={onloadingData}
              />
            </div>
          </div>

          <div className="container">
            <FetchPlan
              apiResponse={apiResponse}
              setApiResponse={setApiResponse}
              planData={planData}
              setPlanData={setPlanData}
            />
          </div>

          <div
            className={`container ${
              showside ? "fixedsidebar" : "fixedsidebar-off"
            }`}
          >
            <div className="row pt-5">
              <div className="col-10">
                <h1 className="h1"> Edit Your Search</h1>
              </div>

              <div className="col-2">
                {/* <h1 onClick={handelsidebar}><i class="fa fa-window-close" aria-hidden="true"></i></h1> */}
                <RxCross1
                  style={{ height: "30px", width: "30px" }}
                  onClick={() => {
                    setShowside(false);
                  }}
                />
              </div>
            </div>
            <hr className="edit-line"></hr>
            <div className={!nextstep[0] ? "d-none" : "d-block"}>
              <div
                className="row inscard align-items-center"
                onClick={() => {
                  handleClicknextstep(1);
                }}
              >
                <div className="col-2">
                  <div className=" text-center ">
                    <i class="fa fa-user-o inscardicon" aria-hidden="true"></i>
                  </div>
                </div>
                <div className="col-8">
                  <p className="m-0">
                    <span className="inscardp">Insured member</span> <br />
                    {Array.isArray(sidedata?.InsuredDetail) ? (
                      sidedata.InsuredDetail.map((detail, index) => (
                        <span key={index}>
                          {detail.InsuredRelation} ({detail.InsuredAge})
                        </span>
                      ))
                    ) : (
                      <span>
                        {sidedata?.InsuredDetail?.InsuredRelation} (
                        {sidedata?.InsuredDetail?.InsuredAge})
                      </span>
                    )}
                  </p>
                </div>
                <div className="col-2">
                  <i
                    class="fa fa-chevron-right faaroc10px"
                    aria-hidden="true"
                    style={{
                      fontSize: "15px",
                      fontWeight: "100",
                      color: "#0055a5",
                    }}
                  ></i>
                </div>
              </div>

              <div
                className="row inscard align-items-center"
                onClick={() => {
                  handleClicknextstep(2);
                }}
              >
                <div className="col-2">
                  <div className="insurmemicon text-center ">
                    <i
                      class="fa fa-map-marker inscardicon"
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>
                <div className="col-8">
                  <p className="m-0">
                    <span className="inscardp">City</span> <br />
                    {sidedata?.District &&
                      sidedata?.State &&
                      `${sidedata.District} , ${sidedata.State}`}
                  </p>
                </div>
                <div className="col-2">
                  <i
                    class="fa fa-chevron-right faaroc10px"
                    aria-hidden="true"
                    style={{
                      fontSize: "15px",
                      fontWeight: "100",
                      color: "#0055a5",
                    }}
                  ></i>
                </div>
              </div>

              <div
                className="row inscard align-items-center"
                onClick={() => {
                  handleClicknextstep(3);
                }}
              >
                <div className="col-2">
                  <div className=" text-center  ">
                    <i
                      class="fa fa-stethoscope inscardicon"
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>
                <div className="col-8">
                  <p className="m-0 ">
                    <span className="inscardp">Existing Illness</span>
                    <br />
                    <span>
                      {Array.isArray(sidedata?.PED) &&
                        sidedata.PED.map((item, index) => (
                          <span key={index}>{item} , </span>
                        ))}
                    </span>
                  </p>
                </div>
                <div className="col-2">
                  <i
                    class="fa fa-chevron-right faaroc10px"
                    aria-hidden="true"
                    style={{
                      fontSize: "15px",
                      fontWeight: "100",
                      color: "#0055a5",
                    }}
                  ></i>
                </div>
              </div>
              <div className="buttons-container">
                {/* <button
              className="updateplanbutton"
              onClick={() => handleUpdatedData()}
            >
              View Updated Plans
            </button> */}
              </div>
            </div>

            {/* nextstep 1st option */}

            {/* nextstep 1st option */}
            <div className={!nextstep[1] ? "d-none" : "d-block"}>
              <div className="row nextmember align-items-center">
                <EditDetails
                  handleUpdatedData={handleUpdatedData}
                  showSide={showside}
                  onBack={() => handleClicknextstep(4)}
                  setShowside={setShowside}
                  setApiResponse={setApiResponse}
                  setPlanData={setPlanData}
                  setSidedata={setSidedata}
                  apiResponse={apiResponse}
                />
              </div>
            </div>
            {/* nextstep 2nd option */}
            <div className={!nextstep[2] ? "d-none" : "d-block"}>
              <div className="row nextmember align-items-center">
                <EditCity
                  showSide={showside}
                  onBack={() => handleClicknextstep(4)}
                  setShowside={setShowside}
                  setPlanData={setPlanData}
                  setSidedata={setSidedata}
                  setApiResponse={setApiResponse}
                  apiResponse={apiResponse}
                />
              </div>
            </div>
            {/* nextstep 3rd option */}
            <div className={!nextstep[3] ? "d-none" : "d-block"}>
              <div className="row  align-items-center">
                <EditPED
                  onBack={() => handleClicknextstep(4)}
                  setApiResponse={setApiResponse}
                  apiResponse={apiResponse}
                  setSidedata={setSidedata}
                  setShowside={setShowside}
                  setPlanData={setPlanData}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Plans;
