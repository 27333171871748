import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Career.css";
import Swal from "sweetalert2";
import FileBase64 from "react-file-base64";
import Redirect from "../../AllPageComponents/Rederict";
import FastImage from "../../FastImage";
import { auto } from "@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core";
import { FaArrowRotateRight } from "react-icons/fa6";
import { Helmet } from "react-helmet-async";

const Career = () => {

  const [captcha, setCaptcha] = useState(generateCaptcha());
  const [firstErr, setFirstErr] = useState("");
  const [lastErr, setLastErr] = useState("");
  const [phoneErr, setPhoneErr] = useState("");
  const [mailErr, setMailErr] = useState("");
  const [captchaErr, setCaptchaErr] = useState("");


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [AllJobData, setAllJobData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}cms/get_career`
        );
        setAllJobData(response.data.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, []);

  // Base 64 Code starts



  const onFileSubmit = (file) => {
    const base64WithoutPrefix = file.base64.replace(
      /^data:application\/pdf;base64,/,
      ""
    );

    setFormData({
      ...formData,
      file: base64WithoutPrefix,
    });
  };

  // This is for Job Unic ID
  const JobID = (jobid) => {
    setFormData({
      ...formData,
      jobId: jobid,
    });
  };


  const [formData, setFormData] = useState({
    jobId: "",
    first_name: "",
    last_name: "",
    mobile_number: "",
    email: "",
    file: "",
    captchaInput: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };




  function generateCaptcha() {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < 6; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }


  const validateFirst = (e) => {
    let newErrors = "";

    if (!e.target.value.trim()) {
      newErrors = "First name is required";
    }

    setFirstErr(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateLast = (e) => {
    let newErrors = "";

    if (!e.target.value.trim()) {
      newErrors = "Last name is required";
    }

    setLastErr(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateMobile = (e) => {
    let newErrors = "";

    if (!/^[0-9]{10}$/.test(e.target.value)) {
      newErrors = "Valid 10-digit mobile number is required";
    }

    setPhoneErr(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateMail = (e) => {
    let newErrors = "";

    if (!/\S+@\S+\.\S+/.test(e.target.value)) {
      newErrors = "Valid email is required";
    }

    setMailErr(newErrors);
    return Object.keys(newErrors).length === 0;
  };









  const validateCaptcha = (e) => {
    let newErrors = "";

    if (e.target.value !== captcha) {
      newErrors = "Incorrect CAPTCHA, please try again";
    }

    setCaptchaErr(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateFields = () => {
    const newErrors = {};

    if (!formData.first_name.trim()) {
      newErrors.first_name = "First name is required";
      setFirstErr("First name is required");
    }

    if (!formData.last_name.trim()) {
      newErrors.last_name = "Last name is required";
      setLastErr("Last name is required");
    }

    if (!/^[0-9]{10}$/.test(formData.mobile_number)) {
      newErrors.mobile_number = "Valid 10-digit mobile number is required";
      setPhoneErr("Valid 10-digit mobile number is required");
    }

    if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Valid email is required";
      setMailErr("Valid email is required");
    }

    if (formData.captchaInput !== captcha.toString()) {
      newErrors.captchaInput = "Incorrect CAPTCHA, please try again";
      setCaptchaErr("Incorrect CAPTCHA, please try again");
    }




    return Object.keys(newErrors).length === 0;
  };


  const handleRegenerate = () => {
    setCaptcha(generateCaptcha());
    setFormData({ ...formData, captchaInput: "" }); 
  };




  const [isDescriptionOpen, setIsDescriptionOpen] = useState(''); 
  const [isApplyOpen, setisApplyOpen] = useState('')

  const descriptionToggle = (data) => {
    setIsDescriptionOpen(data);
  };

   const openApplyForm = (data) =>{
    setisApplyOpen(data)
   }
 

   const handleApplyChanges = async () => {
    if (validateFields()) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}cms/apply_job`,
          formData
        );
        if(response.data.status){
          
          openApplyForm(false)
          Swal.fire({
            title: "Sucess",
            text: "Job applied successfully",
            icon: "success",
            confirmButtonText: "Back",
          });
        }
      } catch (err) {
        console.error(err);
      }
    }
  };


  return (
    <div>
      
<Helmet>
  <title>Exciting Career Opportunities - Join Our Innovative Team Today!</title>
  <meta name="description" content="Discover exciting career opportunities and join our innovative team. Make an impact with us and grow your career with exciting roles. Apply now!" />
  <meta name="keywords" content="career opportunities, job openings, employment opportunities, join our team, work with us, job vacancies, hiring now, career growth, innovative roles" />
</Helmet>


      <section className="mainnargin-insurance"></section>
      <Redirect />
      <div className="carrermain container">
        <div className="textareacarrer">
          <h2>We Are Activly Hiring</h2>
          <p className="textformat">
            Join our dynamic team as we actively recruit individuals to embark
            on a fulfilling journey with us. Explore numerous opportunities to
            contribute to our mission of safeguarding families while propelling
            your career to new heights. Seize the chance to make a meaningful
            impact and unleash your potential with us!
          </p>
        </div>
        <div className="imagecarrer">
          <FastImage
            url={"/Website%20Img/jobopen.png?updatedAt=1728108658977"}
            width={auto}
            height={auto}
            alt={"ca-img"}
          />
        </div>
      </div>


      <div className="opening row justify-content-start container">
        {AllJobData.map((jobs, index) => {
          const letter =
            "IT professionals design, implement, and manage computer systems. Responsibilities include troubleshooting, security maintenance, and performance optimization.IT professionals design, implement, and manage computer systems. Responsibilities include troubleshooting, security maintenance, and performance optimizationIT professionals design, implement, and manage computer systems. Responsibilities include troubleshooting, security maintenance, and performance optimization";

          const cutLetter = letter.slice(0, 154);

          return (
            <>
              <div className="jobs-grid col-md-4">
                <div className="jobsdiv col-md-12">
                  <div className="rolls">
                    <span>{jobs.title}</span>
                  </div>
                  <div className="discrip">
                    <span>{cutLetter}</span>
                  </div>
                  <div className="salaryamount">
                    <span>Salary: &nbsp; {jobs.salary}</span>
                  </div>
                  <div className="Experince">
                    <span>Experince: &nbsp; {jobs.experience}</span>
                  </div>
                  <div className="Experince">
                    <span>Opening: &nbsp; {jobs.opening}</span>
                  </div>
                  <div className="Experince">
                    <span>Location: &nbsp; {jobs.location}</span>
                  </div>
                  <div className="Experince">
                    <span>Qulification: &nbsp; {jobs.qualification}</span>
                  </div>
                  <div className="Experince">
                    <span>Skills: &nbsp; {jobs.skill.length > 120 ? jobs.skill.slice(0, 120) + '...' : jobs.skill}</span>

                  </div>
                  <div className="applybuttons">
                    <button
                      onClick={()=>{descriptionToggle(true)}}
                    >
                      Details
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary"
                      data-toggle="modal"
                      data-target="#jobmodelCenter"
                      onClick={() => {
                        JobID(jobs.id);
                        handleApplyChanges();
                        openApplyForm(true)
                      }}
                    >
                      Apply
                    </button>
















 {
 isDescriptionOpen ? ( // Use a simple conditional check
  <div className="backgroundblock">
    <div className="overlay"></div>
  <div className="modal-overlay">
    <div className="modal-contentcarrer">
      <div className="modal-header">
        <h5 className="modal-title">Description</h5>
        <button className="modal-close-button" onClick={()=>{descriptionToggle(false)}}>
          &times;
        </button>
      </div>
      <hr />
      <div className="modal-bodycarrer">
        <h5 className="job-title">{jobs.title}</h5>
        <label className="attribute-label">
        <span className="specialprice">
          Attributes:-
        </span>
          <div className="inner-html" dangerouslySetInnerHTML={{ __html: jobs.attributes }} />
        </label>

        <label className="attribute-label">
          Description:-
          <div className="inner-html" dangerouslySetInnerHTML={{ __html: jobs.description }} />
        </label>

        <div className="job-details"><span className="specialprice">Responsibilities:</span>{jobs.responsibilities}</div>
        <div className="job-details"><span className="specialprice">Salary:</span> ₹{jobs.salary}</div>
        <div className="job-details"><span className="specialprice">Experience:</span> {jobs.experience} Years</div>
        <div className="job-details"><span className="specialprice">Location:</span> {jobs.location}</div>
        <div className="job-details"><span className="specialprice">Qualification:</span> {jobs.qualification}</div>
        <div className="job-details"><span className="specialprice">Opening:</span> {jobs.opening}</div>
        <div className="job-details"><span className="specialprice">Skills:</span> {jobs.skill}</div>

     
      </div>
      <div className="col-md-12 py-2">


      <button
          className="applybtn-carrer"
          onClick={() => {
            JobID(jobs.id);
            handleApplyChanges();
            descriptionToggle(false)
            openApplyForm(true)
          }}
        >
          Apply
        </button>
        </div>
    </div>
  </div>
  </div>
) : null
}
</div>
 </div>
 </div>


 {
  isApplyOpen ? (
  
    <div className="backgroundblock">

    <div className="overlay"></div>
    <div className="modal-overlay">


    <div className="applymeshcode-carrer">
          <FastImage
            url={"Website%20Img/pop-up_a.webp?updatedAt=1718775653542"}
            width="auto"
            height="auto"
            alt="ca-img"
            className="mashcordimg"
          />
          <div className="modal-header applyheadertopcss">
            <button
              type="button"
              className="applyformcsscarrer-close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setFirstErr("");
                setLastErr("");
                setMailErr("");
                setPhoneErr("");
                setCaptchaErr("");
                openApplyForm(false)
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <h5 className="modal-title applyformcsscarrer-title">
              Apply for Job
            </h5>

            <div className="career-pop-inp applyformcsscarrer-input-container">
              <div className="career-pop-inp-div applyformcsscarrer-input-div">
                <input
                  type="text"
                  name="first_name"
                  placeholder="First Name"
                  onChange={(e) => {
                    handleChange(e);
                    validateFirst(e);
                  }}
                  className="jobinput applyformcsscarrer-input"
                />
                {firstErr && <p className="error applyformcsscarrer-error">{firstErr}</p>}

                <input
                  type="text"
                  name="last_name"
                  placeholder="Last Name"
                  onChange={(e) => {
                    handleChange(e);
                    validateLast(e);
                  }}
                  className="jobinput applyformcsscarrer-input"
                />
                {lastErr && <p className="error applyformcsscarrer-error">{lastErr}</p>}
              </div>
              <div className="career-pop-inp-div applyformcsscarrer-input-div">
                <input
                  type="tel"
                  name="mobile_number"
                  placeholder="Phone Number"
                  onChange={(e) => {
                    handleChange(e);
                    validateMobile(e);
                  }}
                  className="jobinput applyformcsscarrer-input"
                />
                {phoneErr && <p className="error applyformcsscarrer-error">{phoneErr}</p>}

                <input
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  onChange={(e) => {
                    handleChange(e);
                    validateMail(e);
                  }}
                  className="jobinput applyformcsscarrer-input"
                />
                {mailErr && <p className="error applyformcsscarrer-error">{mailErr}</p>}
              </div>
            </div>

            <div className="captcha-div applyformcsscarrer-captcha-div">
              <p>{captcha}</p>
              <FaArrowRotateRight onClick={handleRegenerate} />
            </div>
            <input
              name="captchaInput"
              id="code"
              className="form-control applyformcsscarrer-captcha-input"
              placeholder="Enter CAPTCHA code"
              onChange={(e) => {
                handleChange(e);
                validateCaptcha(e);
              }}
            />
            {captchaErr && (
              <p className="error applyformcsscarrer-error" style={{ marginTop: "-10px" }}>
                {captchaErr}
              </p>
            )}

            {/* File Upload */}
            <div className="upload-cv-file applyformcsscarrer-file-upload">
              <label className="applyformcsscarrer-file-label">
                <FileBase64
                  multiple={false}
                  onDone={(file) => {
                    onFileSubmit(file);
                  }}
                  type="file"
                  name="file"
                  className="FileChange"
                />
                <h4>
                  {formData?.file ? (
                    <span className="applyformcsscarrer-upload-status">
                      Successfully Uploaded file
                    </span>
                  ) : (
                    <span>Upload CV</span>
                  )}
                  <span className="text-danger">*</span>
                </h4>
              </label>
            </div>

            <div className="modal-footer applyformcsscarrer-button-container">
              <button
                type="button"
                onClick={handleApplyChanges}
                className="applyformcsscarrer-button"
              >
                Apply
              </button>
            </div>
          </div>
        </div>


    </div>
    </div>

  ) : null
}

          





            </>
          );
        })}
      </div>
    </div>
  );
};

export default Career;
