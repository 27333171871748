import React, { useEffect, useState, useRef } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import locations from ".././locations.json";
import Termstepper from "./Termstepper";
import arrow from "../TermInsurence/TermImages/arrow-animation.gif";
import { useNavigate } from "react-router-dom";
import Redirect from "../AllPageComponents/Rederict";
import { quickQuoteApi } from "./TermApi/TermApi";
import Loader from "../AllPageComponents/Loader";
import { Helmet } from "react-helmet-async";

const Termstepper3 = () => {
  const theme = createTheme({
    typography: {
      fontFamily: "Poppins, sans-serif",
    },
  });

  const nav = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const incomeArray = [

    { income: "Less Than 2 Lac", id: 200000 },
    {
      income: "2 Lac to 2.9 Lac",
      id: 300000,
    },
    {
      income: "3 Lac to 4.9 Lac",
      id: 400000,
    },
    {
      income: "5 Lac to 6.9 Lac",
      id: 550000,
    },
    {
      income: "7 Lac to 9.9 Lac",
      id: 800000,
    },
    {
      income: "10 Lac to 20 Lac",
      id: 1000000,
    },
    {
      income: "20 Lac +",
      id: 2500000,
    },
  ];

  // Retrieve values from localStorage
  const Checkpincode = localStorage.getItem("PinCode");
  const Annualincome = localStorage.getItem("AnnualIncome");
  const LocalStorageState = localStorage.getItem("State");
  const LocalStorageDistrict = localStorage.getItem('District')

  useEffect(() => {
    if (Checkpincode) {
      checkPincode(Checkpincode);
      setPincode(Checkpincode);
    }
    if (Annualincome) {
      setAnnualIncome(Annualincome);
    } else {
      // Set a default value for AnnualIncome if not already set in localStorage
      setAnnualIncome(incomeArray[0].id); // Assuming the first item in incomeArray as default
      localStorage.setItem("AnnualIncome", incomeArray[0].income); // Save default to localStorage
    }
  }, []);

  const [pincode, setPincode] = useState(Checkpincode ? Checkpincode : '');
  const [district, setDistrict] = useState(LocalStorageDistrict ? LocalStorageDistrict : "");
  const [state, setState] = useState(LocalStorageState ? LocalStorageState : "");
  const [AnnualIncome, setAnnualIncome] = useState(Annualincome ? Annualincome : "Less Than 2 Lac");
  const [loading, setLoading] = useState(false);

console.log("Annual Income",AnnualIncome)

const checkPincode = (pin) => {
  console.log(locations); // Check the structure of locations
  const location = locations.find((location) => {
    return location.Pincode && location.Pincode.toString() === pin;
  });
  if (location) {
    setDistrict(location.District);
    setState(location.StateName);
  }
};


  const handlePincodeChange = (e) => {
    const { value } = e.target;
    setPincode(value);
    checkPincode(value);
  };
  const inputRef = useRef(null);

  const concatenatedValue = `${district}`;

  const handleKeyDown = (e) => {
    if (e.key === "Backspace") {
      setDistrict("");
      setPincode("");
    }
  };

  if (inputRef.current) {
    inputRef.current.selectionStart = inputRef.current.selectionEnd =
      inputRef.current.value.length;
  }





 const GenderSelct = localStorage.getItem('Gender');
 const userName = localStorage.getItem('ProposalName');
 const userPhone = localStorage.getItem('Proposalphonenumber');
 const userDateofBirth = localStorage.getItem('dateofbirth');
 const usereducation = localStorage.getItem('Education');
 const userOccupation = localStorage.getItem('Occupation');
 const AnnaulIncome = localStorage.getItem('AnnualIncome');
 const userPincode = localStorage.getItem('PinCode');
 const userstate = localStorage.getItem('State');
 const userdistrict = localStorage.getItem('District');
 const userSmoke = localStorage.getItem('Smoke');
 const userMarratialstatus = localStorage.getItem('MarratialStatus');

 
 
 
 const userAgeFilter = reverseDateFormat(userDateofBirth)
 
 function reverseDateFormat(dateStr) {
  const [year, month, day] = dateStr.split('-');
    return `${day}-${month}-${year}`;
  }

  const GenderRes = (responce) =>{
    if(responce === "M"){
      return 'Male'
    }else{
      return 'Female'
    }
  }

  const validGender = GenderRes(GenderSelct)

  const apiData = {
    gender: validGender ? validGender : '',
    name: userName ? userName : '',
    phone_no: userPhone ? userPhone : '',
    dob: userAgeFilter ? userAgeFilter : '',
    education: usereducation ? usereducation : "",
    occupation: userOccupation? userOccupation : "",
    annual_income: AnnaulIncome ? AnnaulIncome : '',
    pin_code: userPincode ? userPincode : '',
    state: userstate ? userstate : '',
    district: userdistrict ? userdistrict : userdistrict,
    smoke: userSmoke ? userSmoke : '',
    marital_status: userMarratialstatus ? userMarratialstatus : '',
    total_cover_amount: 50,
    policy_term: 27,
    payment_frequency: "Yearly",
    plan_type: "Base Plan",
    premium_paying_term: 5,
    plan_code: "",
    plan_name: "",
  };


  const [PincodeError, setPincodeError] = useState('')
  const [AnnualIncomeError, setAnnualIncomeError] = useState('')
  const handleDataCheck = () => {

    if(pincode == "" || null){
      setPincodeError("Plese Select a valid Pincode")
      return
    }
   if(AnnualIncome == "" || null){
    setPincodeError("Plese Select a valid Pincode")
    return
   }


    if (district) {
      localStorage.setItem("District", district);
      localStorage.setItem("PinCode", pincode);
      localStorage.setItem("State", state);
    }
    if (AnnualIncome) {
      localStorage.setItem("AnnualIncome", AnnualIncome);
    }

    if (apiData) {
      setLoading(true); // Show the loader

      quickQuoteApi(apiData)
        .then(response => {
          const uniqueId = response.data.unique_id;
          if (uniqueId) {
nav(`/term-insurance/termfetchplanes?req_id=${uniqueId}`);

          }
          return uniqueId; // Optionally return or use it elsewhere
        })
        .catch(error => {
          console.error("Error during API call:", error);
        })
        .finally(() => {
          setLoading(false); // Hide the loader
        });
    }
  };

  return (
    <>
                <Helmet>
        <title>Termstep3</title>
        {/* <meta name="description" content="A brief description of your page for SEO." /> */}
        <meta name="keywords" content="Insurance Plan, Insurance Quotes, buy insurance" />
      </Helmet>
    {loading ? <Loader/> : null}
      <Redirect />
      <ThemeProvider theme={theme}>
        <h1
          style={{
            textAlign: "center",
            padding: 20,
            color: "rgb(0, 85, 165)",
            marginTop: 20,
            fontFamily: "Poppins",
          }}
        >
          Find top plans for you with{" "}
          <span style={{ color: "rgb(229, 3, 24)" }}>upto 25% </span>
          <span style={{ color: "rgb(253, 146, 2)" }}>discount</span>
          <sup>**</sup>
        </h1>

        <Termstepper activeStep={3} />
        <div className="underterminsurence">
          <div className="undertermform">
            <h3>
              Term insurance is a crucial financial tool that provides
              protection for your loved ones in the event of your untimely
              death. Here are several steps to help you understand and secure
              your family with term insurance:{" "}
            </h3>
            <div className="professiondiv py-3">
              <FormControl
                sx={{
                  width: "100%",
                  border: "1px solid #ccc",
                  borderRadius: 2,
                }}
              >
                <InputLabel id="occupation-label" sx={{ bgcolor: "white" }}>
                  Annual Income
                </InputLabel>
                <Select
                  labelId="occupation-label"
                  id="occupation"
                  value={AnnualIncome}
                  onChange={(e) => setAnnualIncome(e.target.value)}
                  input={<OutlinedInput label="Occupation Type" />}
                  sx={{ borderRadius: "inherit" }}
                >
                  {incomeArray.map((data) => (
                    <MenuItem key={data.id} value={data.id}>
                      {data.income}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
  <span className="termformerror">{AnnualIncomeError}</span>
            <div className="pincodesearch py-2">
              <label htmlFor="phone" className="Labelform">
                Pincode
              </label>
              <input
                type="text"
                className="form-control txtOnly"
                placeholder="Pincode/Zip Code"
                onChange={handlePincodeChange}
                value={
                  concatenatedValue
                    ? `${concatenatedValue} ${pincode}`
                    : pincode
                }
                onKeyDown={handleKeyDown}
                ref={inputRef}
              />
            </div>
            <span className="termformerror">{PincodeError}</span>

            <div className="underbutton py-2">
              <button onClick={handleDataCheck}>
                Continue
                <img src={arrow} alt="" />
              </button>
            </div>
          </div>
        </div>
      </ThemeProvider>
    </>
  );
};

export default Termstepper3;
