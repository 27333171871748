import React, { useEffect, useState } from "react";
import "./About.css";
import FastImage from "../../FastImage";
import { auto } from '@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core'
import { Link, Element, Events,scrollSpy } from 'react-scroll';
import { Helmet } from "react-helmet-async";

const About = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [activeTag, setactiveTag] = useState(1)

  const arraynav = [
    {
      id:1,
      to:"aboutpage",
      name:'Comapny'
    },
    {
      id:2,
      to:"visionabout",
      name:'Our Vision'
    },
    {
      id:3,
      to:"missionabout",
      name:'Our Mission'
    },
    {
      id:4,
      to:"function",
      name:'Function'
    }
  ]
  



  useEffect(() => {
    Events.scrollEvent.register("begin", function () {
    });

    Events.scrollEvent.register("end", function () {
    });

    scrollSpy.update();

    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
    };
  }, []);



  

  return (
    
    <>
  <Helmet>
  <title>Learn More About Beema1 - Your Trusted Insurance Aggregator</title>
  <meta name="description" content="Discover Beema1, your trusted insurance web aggregator, offering the best deals on health, term life, car, and bike insurance. Compare policies from top insurers like Policybazaar and Insurancedekho." />
  <meta name="keywords" content="Beema1, insurance aggregator, policy comparison, insurance broker, Policybazaar, Insurancedekho, health insurance, term life insurance, car insurance, bike insurance" />
</Helmet>

    <div className="mainnargin-insurance"></div>
  <div className="fixwidthDivtext">

  <div className="ourvision">
    <div className="container">
    <div className="ourvision-content">
      <div className="undercontainertextabout">
        <h2 className="bluback">#We Don't Sell <span style={{color:"#F96766"}}>We Guide <span style={{color:"#FBA71B"}}>Since</span></span></h2>
        <div className="age">2022</div>
        <div className="tabulerabout">
        {
  arraynav.map((data) => {
    return (
      <Link
        key={data.id} // Move key to Link instead of li
        to={data.to}
        spy={true}
        smooth={true}
        duration={500}
        offset={-90} // Adjust this value to your navbar height
        onSetActive={(section) => setactiveTag(section)}
      >
        <li className={activeTag === data.to ? "tababout" : "tababoutdeactive"}>
          {data.name}
        </li>
      </Link>
    );
  })
}
  
        </div>
      </div>
    </div>
    </div>
</div>
    </div>


    <div className="fixwidthDivtext">
<div className="container headingbeemaabout">

<h1>know more about Beema1</h1>
</div>
    <div className="aboutpage container">
<div className="row">

<div className="abouttext col-md">
<div className="undertext">
<div className="visionabout">

<h2>About <b>Us</b>_</h2>
</div>
 <p className="normaltext">
   Beema1 bringing transparency in insurance business through a
   vast online insurance market place with the best insurance plans
   from the top insurers in the country, making it easy to compare
   , buy & save. Our objective is to simplify insurance by
   defining, comparing and clarifying the jargon in an easy way,
   which provides transparency and offers instant simplified tools
   to help our users to make correct choice in a very user friendly
   manner. Along with the guidance to buy a proper insurance plan,
   Beema1.com has a dedicated professional team who assist our
   clients to settle their Insurance Claims.
 </p>
 {/* Start */}
 <div className="fetures">
   <div className="onefeturetop">
     <div className="onefeture">
    
          <FastImage
url={"Website%20Img/about2.png?updatedAt=1718791783889"}
width={auto}
height={auto}
alt={"ca-img"}
/>
     </div>
     <p className="publication">Years of experience</p>
   </div>
   <div className="onefeturetop">
     <div className="onefeture">
     <FastImage
url={"Website%20Img/about3.png?updatedAt=1718791805495"}
width={auto}
height={auto}
alt={"ca-img"}
/>
     </div>
     <p className="publication">Happy Customers</p>
   </div>
   <div className="onefeturetop">
     <div className="onefeture">
     <FastImage
url={"Website%20Img/about4.png?updatedAt=1718791823328"}
width={auto}
height={auto}
alt={"ca-img"}
/>
     </div>
     <p className="publication">Satisfaction</p>
   </div>
 </div>
 {/* End  */}
</div>
</div>


<div className="modeldiv col-md">
<FastImage
url={"Website%20Img/about1.png?updatedAt=1718791766508"}
width={auto}
height={auto}
alt={"ca-img"}
/>
</div>

</div>



</div>
    </div>





    
<div className="fixwidthDivtext">
<Element name="visionabout">
<div className="description1">
  <div className="container">
    <div className="row">
      <div className="col-md-6 d-flex justify-content-center align-items-center">
        <div className="visionabout">
        <h2>What Our <b>Vision</b>_</h2>
        <p className="normaltext textbox">  To educate and simplify the concept of insurance and make the
             platform accessible to all. Along with the vision to bring
             evolving technologies and technical innovations to the field, we
             also aspire to build a network to increase penetration of
             insurance in rural India. We aspire to create the awareness
             insurance in rural India. As due to lack of awareness insurance
             is one of the least appreciated financial tools as people
             realize its importance when things go wrong.</p>
      </div>
        </div>
      <div className="col-md-6 d-flex justify-content-center align-items-center">
        <div className="containimage">
          <FastImage width={auto} height={auto} url="/Website%20Img/pensive-indian-business-trainer-listening-audience-questions-after-presentation.jpg?updatedAt=1728895514088"/>
       
        </div>
      </div>
    </div>
  </div>
</div>
</Element>
</div>
<Element name="missionabout">
<div className="fixwidthDivtext">
<div className="description1">
  <div className="container">
    <div className="row">
    <div className="col-md-6 d-flex justify-content-center align-items-center">
        <div className="containimage">
          <FastImage width={auto} height={auto} url="/Website%20Img/punjabi.webp?updatedAt=1728895129408"/>
      
        </div>
      </div>
      <div className="col-md-6 d-flex justify-content-center align-items-center">
        <div className="visionabout">
        <h2>What Our <b>Mission</b>_</h2>
        <p className="normaltext textbox">  By clarifying the insurance jargons we want to make the
         insurance simplified which will help everyone to take informed
         decision. Our team of highly qualified, trained and experienced
         professionals insurance advisors available on phone and on chat
         to help our users with the entire process from comparing, taking
         through the steps of need analysis to purchasing the policies
         which suits their requirements. If any incident of claim occurs,
         our experienced claim settlement team assists the clients till
         the time claim is finally settled.</p>
      </div>
        </div>
 
    </div>
  </div>
</div>
</div>
</Element>

<Element name="function">
<div className="fixwidthDivtext">
<div className="description1">
  <div className="container">
    <div className="row">
    <div className="col-md-6 d-flex justify-content-center align-items-center">
        <div className="visionabout">
        <h2>How We <b>Function</b>_</h2>
        <p className="normaltext textbox">Beema1 is the marketplace to fulfil all your insurance needs. We provide Life insurance, Health Insurance, Motor Insurance, Property Insurance, Travel Insurance, Fire insurance, Group Insurance and etc, best insurance plans from the major leading Insurance providers. Our Platform is designed in such a user-friendly way that the visitors can easily analyze and compare insurance plans on their own and buy the most relevant ones as per their requirements.</p>
      </div>
        </div>
    <div className="col-md-6 d-flex justify-content-center align-items-center">
        <div className="containimage">
          <FastImage width={auto} height={auto} url="/Website%20Img/modern-equipped-computer-lab.jpg?updatedAt=1728894459428"/>
        </div>
      </div>
  
 
    </div>
  </div>
</div>
</div>
</Element>


    </>
  );
};

export default About;
