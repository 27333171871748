import React, { useState, useEffect } from "react";
import { MdOutlineAutoGraph } from "react-icons/md";
import { AiOutlineFileProtect } from "react-icons/ai";
import { LuAlarmClock } from "react-icons/lu";
import { MdHealthAndSafety } from "react-icons/md";
import { IoIosLogOut } from "react-icons/io";
import { Link } from "react-router-dom";
import { FcAlarmClock } from "react-icons/fc";
import { IoMdDoneAll } from "react-icons/io";
import { FiAlertCircle } from "react-icons/fi";
import axios from "axios";
import "../Set_Reminder/Setreminder.css";
import { Pagination } from '@mui/material';
import Redirect from "../../../AllPageComponents/Rederict";
import { RxHamburgerMenu } from "react-icons/rx";
import { RxCross2 } from "react-icons/rx";
import FastImage from "../../../FastImage";
import { auto } from '@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core'



import { MdMenuOpen } from "react-icons/md";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { Helmet } from "react-helmet-async";



const Setreminder = () => {
  const [isactiveDashbord, setisactiveDashbord] = useState("Dashbord");

  // Api calling Start Hear
  const userToken = localStorage.getItem("userToken");
  const [formData, setFormData] = useState({
    name: "",
    phone_number: "",
    email: "",
    policy_number: "",
    policy_type: "health-insurance",
    expiry_date: "",
    token: userToken,
  });

  const [ShowResult, setShowResult] = useState(3);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //console.log(formData);
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}user/dashboard/set_reminder`,
        formData,
      )
      .then((res) => {
        //console.log("Successfully Submitted", res.data.status);
        if (res.data.status === true) {
          setShowResult(1);
          setTimeout(() => {
            document.getElementById("submitButton").click();
          }, 3000);
        } else {
          setShowResult(2);
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  // Call the Get Api in this Section
  const [GetallData, setGetallData] = useState([]);
  const getAllData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}user/dashboard/reminder_list`,
        {
          params: { token: userToken },
        },
      );

      setGetallData(response.data.data);
    } catch (err) {
      //console.log(err);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);
  //console.log("Get all Data is", GetallData);

  // Pagenation Logic

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(7); 

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = GetallData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalItems = GetallData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);


  const LogoutData = () =>{
  localStorage.clear();
  window.location.href = '/';
}

// //console.log("pagenation data",currentItems)

const [ShowSideBar, setShowSideBar] = useState(true)

const ShowMenuBar = () =>{
  setShowSideBar(!ShowSideBar)
}

const [open, setOpen] = React.useState(false);

const toggleDrawer = (open) => (event) => {
  if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    return;
  }

  setOpen(open);
};

const list = () => (
  <Box
  sx={{ width: 250 }}
  role="presentation"
  onClick={toggleDrawer(false)}
  onKeyDown={toggleDrawer(false)}
>
<div className='crossdiv'>
<button><RxCross2/></button>
</div>
  <ul className="underullist">

    <li className="activeDashbord">
      <Link to="/dashbord">
        <MdOutlineAutoGraph /> &nbsp; Dashbord
      </Link>
    </li>
    <li className="deactiveDashbord">
      <Link to="/dashbord-policy">
        <AiOutlineFileProtect /> &nbsp; Policy
      </Link>
    </li>
    <li className="deactiveDashbord">
      <Link to="/setup-reminder">
        <LuAlarmClock /> &nbsp; Setup Reminders
      </Link>
    </li>
    <li className="deactiveDashbord">
      <Link to="/dashbord-Claim">
        <MdHealthAndSafety /> &nbsp; Clame Mangement
      </Link>
    </li>
    <li className="deactiveDashbord" onClick={LogoutData}>
      <IoIosLogOut /> &nbsp; Logout
    </li>
  </ul>
</Box>
);


  return (
    <div>
       <Helmet>
        <title>Reminder</title>
        {/* <meta name="description" content="A brief description of your page for SEO." /> */}
        {/* <meta name="keywords" content="best term insurance in india , buy term life insurance, compare and purchase term insurance" /> */}
      </Helmet>
      <div className="dashbordmargin"></div>
      <div className="maincontainer">
        {/* Sidebar Container Start Hear */}
        <div className={ShowSideBar ? "sidebarse" : ''}>
        <div className="subsidebar">
            <ul>
              <Link to={"/dashbord"}>
                {" "}
                <li className="deactiveDashbord">
                  {" "}
                  <MdOutlineAutoGraph /> Dashbord
                </li>
              </Link>
              <Link to={"/dashbord-policy"}>
                {" "}
                <li className="deactiveDashbord">
                  {" "}
                  <AiOutlineFileProtect /> Policy
                </li>
              </Link>
              <Link to={"/setup-reminder"}>
                <li className="activeDashbord">
                  <LuAlarmClock /> Setup Reminders
                </li>
              </Link>
              <Link to={"/dashbord-Claim"}>
                {" "}
                <li className="deactiveDashbord">
                  {" "}
                  <MdHealthAndSafety /> Clame Mangement
                </li>
              </Link>
              <li className="deactiveDashbord" onClick={LogoutData}>
                <a>
                  <IoIosLogOut /> Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
        {/* Sidebar Container End Hear */}

        {/* Dashbord Container Start Hear */}
        <div className="dashbordcontainer">
          {isactiveDashbord === "Dashbord" && (
            <>
              <div
                class="modal fade"
                id="exampleModalCenterCustom"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitleCustom"
                aria-hidden="true"
              >
                <div
                  class="modal-dialog modal-dialog-centered modalwidth"
                  role="document"
                >
                  <div class="modal-content modalwidth">
                 
                  <FastImage
            url={"Website%20Img/pop-up_a.webp?updatedAt=1718775653542"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            className='motalbody'
            />
                    <div class="modal-header">
                      <h4
                        class="modal-title text-center"
                        id="exampleModalLongTitleCustom"
                        style={{ textAlign: "center", color: "#2957B6" }}
                      >
                        {" "}
                        <span style={{ color: "#FCA84C" }}>
                          Setup
                        </span> Reminder <FcAlarmClock />
                      </h4>

                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        id="submitButton"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>

                    <div className="modal-body">
                      <div className="container">
                        <div className="row">
                          <form>
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-6 mt-2">
                                  <label
                                    htmlFor="name"
                                    style={{
                                      color: "gray",
                                      position: "relative",
                                      top: "5px",
                                    }}
                                  >
                                    Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    placeholder="Name"
                                    required
                                  />
                                </div>
                                <div className="col-md-6 mt-2">
                                  <label
                                    htmlFor="phone_number"
                                    style={{
                                      color: "gray",
                                      position: "relative",
                                      top: "5px",
                                    }}
                                  >
                                    Mobile number
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="phone_number"
                                    name="phone_number"
                                    value={formData.phone_number}
                                    onChange={handleChange}
                                    placeholder="Mobile number"
                                    required
                                  />
                                </div>
                                <div className="col-md-6 mt-2">
                                  <label
                                    htmlFor="email"
                                    style={{
                                      color: "gray",
                                      position: "relative",
                                      top: "5px",
                                    }}
                                  >
                                    Email
                                  </label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    placeholder="Email"
                                    required
                                  />
                                </div>
                                <div className="col-md-6 mt-2">
                                  <label
                                    htmlFor="policy_number"
                                    style={{
                                      color: "gray",
                                      position: "relative",
                                      top: "5px",
                                    }}
                                  >
                                    Policy Number
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="policy_number"
                                    name="policy_number"
                                    value={formData.policy_number}
                                    onChange={handleChange}
                                    placeholder="Policy Number"
                                    required
                                  />
                                </div>
                                <div className="col-md-6 mt-2">
                                  <label
                                    htmlFor="policy_type"
                                    style={{
                                      color: "gray",
                                      position: "relative",
                                      top: "5px",
                                    }}
                                  >
                                    Policy Type
                                  </label>
                                  <select
                                    className="form-control"
                                    id="policy_type"
                                    name="policy_type"
                                    value={formData.policy_type}
                                    onChange={handleChange}
                                  >
                                    <option>Select Insurence Type</option>
                                    <option value="health-insurance">
                                      Health Insurance
                                    </option>
                                    <option value="general-insurance">
                                      General Insurance
                                    </option>
                                    <option value="car-insurance">
                                      Car Insurance
                                    </option>
                                    <option value="bike-insurance">
                                      Bike Insurance
                                    </option>
                                    <option value="term-insurance">
                                      Term Insurance
                                    </option>
                                    <option value="travel-insurance">
                                      Travel Insurance
                                    </option>
                                    <option value="life-insurance">
                                      Life Insurance
                                    </option>
                                  </select>
                                </div>
                                <div className="col-md-6 mt-2">
                                  <label
                                    htmlFor="expiry_date"
                                    style={{
                                      color: "gray",
                                      position: "relative",
                                      top: "5px",
                                    }}
                                  >
                                    Expiry Date
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    id="expiry_date"
                                    name="expiry_date"
                                    value={formData.expiry_date}
                                    onChange={handleChange}
                                    placeholder="Expiry Date"
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>

                    <div class="modal-footer popupfooter">
                      <button
                        type="button"
                        class="popupsubmitfooter"
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </div>

                    {/* sucess Component */}

                    {ShowResult === 1 ? (
                      <div className="submitafter">
                        <span>
                          <IoMdDoneAll
                            fontSize={22}
                            style={{ marginLeft: 5 }}
                          />
                          Reminder Set Successfully
                        </span>
                      </div>
                    ) : ShowResult === 2 ? (
                      <div className="submitafterfail">
                        <span>
                          <FiAlertCircle
                            fontSize={22}
                            style={{ marginLeft: 5 }}
                          />
                          Oops! Something went wrong!
                        </span>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              {/*Modal end hear and maincode start hear*/}

              <div className="underdashbordreminder">
                <div className="buttonadd">

         
    
    
    <Button onClick={toggleDrawer(true)} className="navigationbar"><MdMenuOpen style={{fontSize:"15pt"}}/></Button>
      <Drawer
        anchor="left"
        open={open}
        onClose={toggleDrawer(false)}
      >
        {list()}
      </Drawer>
    
    
                
             

                  <button
                    type="button"
                    data-toggle="modal"
                    data-target="#exampleModalCenterCustom"
                    className="setreminder"
                  >
                 Set Reminder
                  </button>
                </div>

                {/* Tabil Data Start Hear */}






                <div className="table-container">
  <table className="policy-table">
    <thead>
      <tr>
        <th>Name</th>
        <th>Phone Number</th>
        <th>Email</th>
        <th>Policy Type</th>
        <th>Policy Number</th>
        <th>Expiry Date</th>
      </tr>
    </thead>
    <tbody>
      {currentItems.length === 0 ? (
        <tr>
          <td colSpan="6" style={{ color: "gray", fontSize: 15, fontWeight: 400, marginTop: 15 }}>Data not found</td>
        </tr>
      ) : (
        currentItems.map((data, index) => (
          <tr key={index}>
            <td>{data.name}</td>
            <td>{data.phone_number}</td>
            <td>{data.email}</td>
            <td>{data.policy_type}</td>
            <td>{data.policy_number}</td>
            <td>{data.expiry_date}</td>
          </tr>
        ))
      )}
      {/* Add more rows as needed */}
    </tbody>
  </table>
</div>









              <div className="padenationdiv" hidden={GetallData.length < 7}>
                <Pagination 
                count={totalPages}  // Pass total number of pages
                page={currentPage}
                onChange={(event, page) => setCurrentPage(page)} 
                color="primary" 
              />
              </div>
                {/* Tabil Data end Hear */}
              </div>
            </>
          )}
        </div>

        {/* Dashbord Container End Hear */}
      </div>
    </div>
  );
};

export default Setreminder;