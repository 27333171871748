import React, { useState, useEffect } from "react";
import PartnerCompany from "../AllPageComponents/PartnerCompany/PartnerCompany";
import FAQ from "../AllPageComponents/FAQ/FAQ";
import { LuAlarmClock } from "react-icons/lu";
import BikeInsuranceForm from "./BikeInsuranceForm";
import Redirect from "../AllPageComponents/Rederict";
import FastImage from "../FastImage";
import { auto } from '@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core'
import { Helmet } from "react-helmet-async";
import '../BikeInsurence/BikeInsurence.css';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { MdOutlineExpandMore } from "react-icons/md";
import { IoMdAddCircle } from "react-icons/io";
import { FaChevronDown } from "react-icons/fa";
import { FaChevronUp } from "react-icons/fa";


const BikeInsurence = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);





  
  

  const benifittwowhillerinsurence = [
    {id:0,q:'Coverage Against Accidental Injuries',a:'Two-wheeler insurance not only covers the damage caused to the vehicle but at the same time it offers coverage against the injuries caused due to accidents, that the insured individual has suffered.'},
    {id:1,q:'Damage of The Insured Vehicle Is Covered',a:'With a potent two-wheeler insurance, you can avail coverage against the damage or loss caused to an individual’s two-wheeler due to an accident, self-ignition or fire.'},
    {id:2,q:'Third-Party Coverage ',a:'Any kind of damage or injury caused to the third-party individual or to the individual’s property, by the insured is entirely covered under the policy.'},
    {id:3,q:'Rides Minus Risks',a:'Two-Wheeler insurance helps you to enjoy risk-free rides in India.'}
  ]


  const [expanded, setExpanded] = React.useState(false);

  const handleChanges = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };




  const WhySouldeYouPercheseTwowhellerinsurence = [
    {id:0,q:'Less Analysis and Less Documentation',a:'Your insurance policy can be renewed easily and hassle free. Submitting few relevant details of your two-wheeler is important and the existing policy if available.'},
    {id:1,q:'Convenient Online Support ',a:'The expertise that you receive at Beema1 ensures you to provide needful guidance whenever required.'},
    {id:2,q:'Nil Extra Charges',a:'You do not need to pay extra charges if you buy two-wheeler insurance policy online. Hence, online two-wheeler insurance plans are comparatively more pocket-friendly than the offline purchase.'},
    {id:3,q:'Hassle free Claim Settlement Process',a:'You can connect with team Beema1 through the website and get your queries resolved.'},
    {id:4,q:'Fast Issuance',a:'An online purchase of a two-wheeler insurance policy can be within couple of minutes only. It’s that quick.'}
  ]

  const WhySouldeYouPercheseTwowhellerinsurence2 = [
    {id:5,q:'Much Consumer-Affable Platform ',a:' Beema1 is a user-friendly online platform that assists the customers to maneuver around the website without getting confused.'},
    {id:6,q:'Online Comparison for Free',a:'There are numerous insurance companies in India providing two-wheeler insurance plans. You would require a deep study with comparisons in detail, there’s where Beema1 comes into play. This option provides a hassle-free renewal and a platform for comparison where people can plan, compare and select the final one that’s most suitable for you.'},
    {id:7,q:'Accessibility',a:'You have the complete access of Beema1 website from any location. You need your electronic device with you and a strong internet connection. Devices such as (Mobile, Laptop or Tablet)'},
 
  ]



const bikestepper = [
  {
    id:1,
    name:'two-wheeler insurance quotes',
    description:'Go to the ‘compare two-wheeler insurance quotes’'
  },
  {
    id:2,
    name:'Fill in the details',
    description:'Fill in the details of your two-wheeler and click on ‘continue’ for buying/renewing your policy'
  },
  {
    id:3,
    name:'Compare the insurance quotes',
    description:'Compare the insurance quotes based on various features and factors of the available insurance plans, such as, coverage, premium, benefits, add-ons and different provisions'
  },
  {
    id:4,
    name:'Make payment',
    description:'Make the payment towards your insurance policy'
  },
  {
    id:5,
    name:'successfully pay',
    description:'The Moment you successfully pay the premium, the very moment you are insured'
  },
  {
    id:6,
    name:'A soft copy ',
    description:'A soft copy of the policy will be sent to your registered email address'
  },
]
const documents = [
  {
    id:7,
    name:'Identity Proof',
    description:'Your Aadhaar card, pan card, driving license, etc. are your valid identity proofs’',
    num:1,
  },
  {
    id:8,
    name:'Address Proof',
    description:'You can provide your passport, utility bills or rental agreements as your valid address proofs. An address proof is used for verification.',
    num:2,
  },
  {
    id:9,
    name:'Income Proof ',
    description:'To show your earnings, your valid income proofs could be your last three month’s salary slip, your latest Form 16, or ITR to prove your income.',
    num:3,
  },
  {
    id:10,
    name:'Nationality Proof',
    description:'Certain cases would demand to verify your nationality proof. You may submit your passport, your valid Voter’s Identity Card, etc.',
    num:4,
  }
]

const [openstepper, setopenstepper] = useState(0)
const [biketab, setbiketab] = useState(1)


  return (
    <div>
<Helmet>
  <title>Easy Bike Insurance Renewal Online - No Inspection Needed</title>
  <meta name="description" content="Renew your bike insurance online easily with no inspection required. Get affordable coverage instantly with a few clicks!" />
  <meta name="keywords" content="buy bike insurance online, renew bike insurance, bike insurance, hassle-free bike insurance renewal, affordable motorcycle insurance, instant bike insurance quotes" />
</Helmet>







  
      <section className="car-insurance insurance-all bike-main mainnargin-insurance">



              <div className="container">
    <Redirect/>
         
            <div className="policy-upper-flexarea">
            <div className="col-sm-12 col-md-4 col-lg-4 mian-one">
              <div className="healthdiscription">
    
                <h1>Two wheeler insurance policy</h1>
                <span>
                  Bike insurance, also known as motorcycle insurance, provides
                  financial protection for individuals who own motorcycles or
                  other two-wheeled vehicles. Similar to car insurance, bike
                  insurance typically includes coverage for various risks
                  associated with owning and riding a motorcycle.
                </span>
              </div>
              <div className="insurance-btn-set">
                <button
                  type="button"
                  className="reminderbutton"
                  data-toggle="modal"
                  data-target="#reminderpopup"
                >
                  {" "}
                  <LuAlarmClock
                    fontSize={"15pt"}
                    style={{ margin: "0 5px", color: "#FBA71B" }}
                  />{" "}
                  Set Reminder
                </button>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 side-image">
            <FastImage
            url={"Website%20Img/BikeInsurence.png?updatedAt=1718779269495"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            />
            </div>
            <style
              dangerouslySetInnerHTML={{
                __html: "\n    .hide{\n        display: none;\n    }\n",
              }}
            />
            <BikeInsuranceForm/>
            </div>
            </div>
            <div className="inbulitmargine"></div>




            <section className="insurance-popup popup-all">
              <div className="insurance-popup-inner container">
                <div className="insurance-popup-sub">
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="modal-header">
                      <button
  type="button"
  className="trigger-back nextprevious"
  data-activediv="make"

>
  Click Me
</button>

                        <button className="item-4 modal-close modal-toggle" />
                      </div>
             
                      <div className="insurance-btn-set">
                        <button
                          className="set-reminder reminder-btn"
                          data-toggle="modal"
                          data-target="#SetReminder"
                        >
                     
                          <span>Set reminder</span>
                          <div
                            type="button"
                            className="btn btn-lg btn-secondary btn-toggle new-active"
                            data-toggle="button"
                            aria-pressed="false"
                            autoComplete="off"
                          >
                            <div className="handle" />
                          </div>
                        </button>
                    
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-4 side-image">
                  <FastImage
            url={"Website%20Img/BikeInsurence.png?updatedAt=1718779269495"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            />
                  </div>
                  <style
                    dangerouslySetInnerHTML={{
                      __html: "\n    .hide{\n        display: none;\n    }\n",
                    }}
                  />
             
       
                 
                </div>
              </div>
              <div
                className="modal fade new-popup-widht"
                id="SetReminder"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="exampleModalLongTitle"
                aria-hidden="true"
                data-backdrop="static"
                data-keyboard="false"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <button
                        type="button"
                        className="close reminder_close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="reminder-modal">
                      <div className="modal-body">
                        <div className="modal-content">
                          <div className="modle-title">
                            <h6>Set Reminder</h6>
                          </div>
         
                        </div>
                      </div>
                    </div>
                  </div>
                 
                </div>
              </div>
            </section>

            {/*Bike Insurence-sectionone start*/}


<div className="fixwidthDivtext">
         
              <div className="container">
           
                  <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12 read_more">
                      <h4 className='healthHeading'>Two-wheeler insurance policy</h4>
                      <div className="yellowline"></div>
                      <p  className='textformat'>  
                      If you are among the bike lovers or a daily commuter, you will understand the significance of safeguarding your two-wheeler by buying the best two-wheeler insurance in India. Bike insurance is like a safety net for your motorcycle. Just like car insurance, it helps you financially if in case your bike gets in an accident, gets stolen, or get damaged by something else. Two-wheeler insurance policy is to protect you from arising unexpected expenses. Beema1 offers two-wheeler insurance to secure your bike against accidents, theft, and other unforeseen events. Buy two-wheeler insurance from Beema1 and ride with confidence.
                      </p>
                  </div>
                  </div>


            
              </div>
         
            </div>
            {/* 3rd Section */}

            <div className="fixwidthDivtext">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h4 className='healthHeading'>What is Two-Wheeler Insurance?</h4>
          <div className="yellowline"></div>
          <p className='textformat' style={{textAlign:'justify'}}>
          Let's face it, even the safest riders can encounter unexpected mishaps. Whether it's a rogue pothole or an inattentive driver, damage to your two-wheeler is a possibility we all face. While minor scratches might be a breeze to fix, major repairs can leave a serious dent in your wallet. This is where a two-wheeler insurance policy comes in as your financial guardian angel. Owning the right insurance policy is just not a legal necessity but also a cautious way of safeguarding your bike from unpredictable incidents or mishaps. Think of it as a safety net you create with your insurance company. You pay a regular premium, and in return you receive hassle free renewal of bike insurance annually, they've got your back if your bike gets damaged in an accident. It's a simple exchange that can provide immense peace of mind. Don't wait until it's too late! Buy two-wheeler insurance from Beema1 and safeguard yourself financially in case of an accident. 
          </p>
          <p className='textformat' style={{textAlign:'justify'}}>
         <span className="specialprice">For example:-</span>   John loves riding his new mountain bike. He gets basic bike insurance that covers damage (up to a certain amount) in case of accidents. One day, while riding on the trails, John crashes and bends his bike's frame. Thankfully, his insurance helps pay for repairs, getting his bike back on the road quickly. In the following comprehensive guide, we shall delve deep into the world of two-wheeler insurance in India, covering the best bike insurance companies and guiding you to make an informed choice. Beema1 offers cheap motorcycle insurance. Get a free quote today!
          So basically, you tend to buy two-wheeler insurance online, that will help you in many aspects. Two-wheeler insurance ammunition provides an all-inclusive cover, best to prefer buying comprehensive protection over third-party insurance. Buying two-wheeler insurance should also be a well-researched conclusion after addressing and comparing 
          </p>
          <p className='textformat' style={{textAlign:'justify'}}>
          different insurance policies from various insurance providers on offer. Beema1 would suggest some good ideas and valued points to choose the best two-wheeler insurance for you as per your requirements and budget:
          </p>
        </div>
      </div>
      <div className="row">
        <div  className="col-md-6 d-flex justify-content-center align-items-center">
          <div className="bikestepbox">
            <ul>

            <li>1 &nbsp;Know the Requirement of Your Coverage</li>
            <li>2 &nbsp;Premium Variation (costs)</li>
            <li>3 &nbsp;Understanding Insurance Declared Value (IDV) is very important</li>
            <li>4 &nbsp;Riders to Extend Your Insurance Cover</li>
            <li>5 &nbsp;Choose a top-rated insurance provider</li>
            </ul>
          </div>
        </div>
        <div  className="col-md-6 d-flex justify-content-center align-items-center">
     <div className="bikestepboxtwo">
      <ul>

            <li>6 &nbsp;Compare Two-wheeler Insurance Online</li>
            <li>7 &nbsp;Don’t miss to read online reviews</li>
            <li>8 &nbsp;Claim Settlement Ratio</li>
            <li>9 &nbsp;Customer Service</li>
            <li>10 &nbsp;User-friendly Online Convenience</li>
            <li>11 &nbsp;Policy Features</li>
      </ul>
          </div>
        </div>
      </div>
    </div>


            </div>

            <div className="fixwidthDivtext">
            <div className="container">
            <div className="row">
            <div className="col-md-12">
             <h4 className='healthHeading'>The Need Behind Having A Two-Wheeler Insurance</h4>
             <div className="yellowline"></div>
             <p className='textformat'>
             It was in the year 1988, when the Motor Vehicles Act emphasized the concept of two-wheeler insurance. The purpose behind it is quite transparent. Purchasing at least one third-party insurance policy for your two-wheeler is mandatory. It supports you to ride stress-free in India. If you drive in India without the third-party insurance, you will have to pay hefty fines as per law and will have to face the strictness of the Indian judicial system. Aligned with the new amendments of the Motor Vehicles Act 2019, there is a higher penalty for people driving without insurance or driving with an expired driving license. Therefore, availing a third-party motor insurance would be the wisest decision to safeguard your two-wheeler without putting further pressure on your wallet.
             </p>
            </div>
            </div>
            </div>
            </div>

            <div className="fixwidthDivtext">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h4 className="healthHeading">Types of Two-wheeler Insurance</h4>
                  <div className="yellowline"></div>




                   <div className="boxtabuler">
                    <div className="overflowbikeinsurence">
                    <div className="scrollingtabulerbox">
                    <div className= {biketab === 1 ?"activetabsbike" : "deactivetabsbike"} onClick={()=>{setbiketab(1)}}>Comprehensive Bike Insurance</div>
                    <div className= {biketab === 2 ?"activetabsbike" : "deactivetabsbike"} onClick={()=>{setbiketab(2)}}>Third-Party Bike Insurance</div>
                    <div className= {biketab === 3 ?"activetabsbike" : "deactivetabsbike"} onClick={()=>{setbiketab(3)}}> Own-Damage Bike Insurance</div>
                    <div className= {biketab === 4 ?"activetabsbike" : "deactivetabsbike"} onClick={()=>{setbiketab(4)}}>Long Term Insurance</div>
                    </div>
                    </div>

<div className="tabpaddingbike">
         {
  biketab === 1 ? (
    <p className='textformat' style={{textAlign:'justify'}}>
      Imagine that your bike is your trusty steed and bike comprehensive insurance is its armor. It protects your bike against many dangers, unlike basic insurance, which only cares about other people's issues. It stretches out financial security to you or to your two-wheeler along with third-party liabilities. One can also elaborately dwell in with optional add-ons providing extending benefits with enhanced coverage to the bike insurance owner. So, if you accidentally hit a car (accidents happen!), comprehensive insurance will cover the repair of your bike. It's like a knight who saves your horse from being beaten! But that's not all. This insurance also protects your bike if it is stolen, burned in a fire or even damaged in a bad storm. Basically, for anything that can happen to your bike, comprehensive insurance will help you financially to get it back on the road. So, you can ride worry-free, knowing that your two-wheeled partner is well protected.
    </p>
  ) : biketab === 2 ? (
    <p className='textformat' style={{textAlign:'justify'}}>
      As you can understand from the name, it will only cover financial liabilities emanating out of the damages caused to the third party or the individual’s property by the policyholder. Moreover, as per the Indian Motor Vehicles Act, 1988, it is compulsory for every two-wheeler owner to own a valid third-party two-wheeler insurance, and the authority has the complete right to penalize if they don’t comply with the rules and regulations. 
      Third-party bike insurance is a financial safety net if you accidentally injure someone or roll over your bike. Imagine you are riding a motorcycle and accidentally crash into a parked car. In this scenario, the car is the "third party". Third-party bike insurance would cover the cost of repairing dents and scratches on the car, so you don't have to pay for it yourself. This insurance also applies to people. If you accidentally hit a pedestrian, your third-party insurance will cover a certain amount of your medical expenses. This type of insurance is required by law and is quite affordable compared to other insurance options. However, remember that this does not cover damage to your bike. So, if your bike is hit in the same car accident, you will need another insurance policy to cover the repairs. Beema1 specializes in finding cheap motorcycle insurance for all types of riders.
    </p>
  ) : biketab === 3 ? (
    <p className='textformat' style={{textAlign:'justify'}}>
    Bike accident insurance is an umbrella that protects your motorcycle if something unexpected happens. Imagine you're riding your bike down the street (hopefully wearing a helmet!) and you accidentally hit a car. Yes! Only standard bike insurance (which is mandatory) only covers injury or damage to the car and its occupants. What about your bike? Left out in the cold (or heat depending on the weather). This is where personal injury insurance comes into play in your knight's shining armor. It covers the repair or even replacement of your bike if it is broken in an accident, stolen or damaged in a hail storm. It's like a superhero sidekick on his bike, ready to jump in and save the day (or rather your trip) from financial trouble. Understanding Own-Damage Bike Insurance is crucial, but don't forget to buy bike insurance online with Beema1 for a quick and secure quote.
   </p>
  ):
   biketab === 4 ? (
    <p className='textformat' style={{textAlign:'justify'}}>
    With such option of insurance, the policyholder does not need to be anxious about policy’s annual renewals. You get financial security against damage or loss to your two-wheeler for an extended period.
    </p>
  ): null
}
</div>




                   </div>

                  
                 
             
                 
                
   






                </div>
              </div>
            </div>
            </div>
            <div className="fixwidthDivtext">
              <div className="container">
              <h4 className='healthHeading'>What is The Best Comprehensive or Third-party Cover or Separate Excess?</h4>
              <div className="yellowline"></div>
                <div className="row">
                  <div className="col-md-12">
                    <p className='textformat' style={{textAlign:'justify'}}>
                    Bike insurance comes in several flavors, each suited for different needs. Full coverage, or comprehensive insurance, covers everything: damage to other people's property (third-party), damage to your bike, and even theft. Basic coverage, known as third-party insurance, is the most affordable option but only covers damage to other people's property or injuries if you cause an accident; it won't pay to fix your bike. Own damage-only insurance covers repairs for your bike if it's damaged in an accident, stolen, or burned in a fire, but it doesn't cover anything else. Once you've decided which type of coverage is best for you, you can easily buy bike insurance online in a matter of minutes, allowing you to compare quotes and choose the right excess level for your budget.
                    </p>
                              
              <div className="table-container">
                <div className="tableoverflow">

              
      <table className="bikeinsurancetable">
        <thead>
          <tr>
            <th>Coverage Type</th>
            <th>Comprehensive Two-Wheeler Insurance</th>
            <th>Standalone Own-Damage Two-Wheeler Insurance</th>
            <th>Third-Party Liability Two-Wheeler Insurance</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Third-party Liabilities</td>
            <td>Yes</td>
            <td>No</td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>Own Damages</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>No</td>
          </tr>
          <tr>
            <td>Personal Accident Cover</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>No</td>
          </tr>
          <tr>
            <td>Protection Against Fire</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>No</td>
          </tr>
          <tr>
            <td>Cover Against Theft</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>No</td>
          </tr>
        </tbody>
      </table>
      </div>
    </div>
           
                  </div>

  

                </div>
              </div>
            </div>
            <div className="fixwidthDivtext">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <h4 className='healthHeading'> E-bike Insurance in India.</h4>
                    <div className="yellowline"></div>
                    <p className='textformat' style={{textAlign:'justify'}}>
                    E-bike insurance in India is like having a safety net for your electric bike, similar to one you might have for your finances. The mandatory third-party insurance acts as a basic safety net; for instance, if you accidentally bump into someone's scooter while riding your e-bike, this insurance will cover the repairs for their scooter, but not yours. On the other hand, optional comprehensive insurance offers a more complete safety net. It includes the coverage of third-party insurance but also protects your e-bike. So, if your e-bike gets stolen or damaged in an accident, this insurance would help pay for repairs or even replace your stolen e-bike. Beema1 does appear to be one of the established providers yet, you can still explore your options to buy two-wheeler insurance for your e-bike in India.
                    </p>
                    <div className="table-container">
                    <div className="tableoverflow">

       
                    <table className="bikeinsurancetable">
        <thead>
          <tr>
            <th>Motor Capacity</th>
            <th>1-Year Insurance Price (Approx.)</th>
            <th>5-Year Insurance Price (Approx.)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Up to 3kW</td>
            <td>₹457</td>
            <td>₹2,466</td>
          </tr>
          <tr>
            <td>3-7kW</td>
            <td>₹607</td>
            <td>₹3,273</td>
          </tr>
          <tr>
            <td>7-16kW</td>
            <td>₹1,161</td>
            <td>₹6,260</td>
          </tr>
          <tr>
            <td>More than 16kW</td>
            <td>₹2,383</td>
            <td>₹12,849</td>
          </tr>
        </tbody>
      </table>
      </div>
                    </div>
                    <div style={{textAlign:'end',marginTop:'5px'}}>

                    <i>*Data as per IRDA.</i>
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>
            <div className="fixwidthDivtext">
              <div className="container">
              <h4 className='healthHeading'>What Does My Two-wheeler Insurance Protect Me From?</h4>
              <div className="yellowline"></div>
                <div className="row">

                  <div  className="col-md-12 d-flex justify-content-center align-items-center">
                    <p className='textformat' style={{textAlign:'justify'}}>
                    Two-wheeler insurance provides comprehensive protection against various risks and disasters. It covers natural disasters like earthquakes, floods, lightning, typhoons, storms, and hurricanes. Additionally, it offers protection against man-made disasters such as riots and theft. The third-party warranty covers any monetary damages resulting from lawsuits involving injuries to third parties, including death or bodily injury. In the event of a fire, explosion, or spontaneous combustion, the insurance covers the loss or damage to your bike.
                    Personal Accident Insurance offers up to 15 lakhs in case of injuries to the rider or owner that cause temporary or permanent disability. Moreover, accident insurance covers all damages to the insured vehicle caused by fire, explosion, natural calamities, man-made calamities, or theft. While prioritizing protection, be sure to explore options for cheap motorcycle insurance from Beema1 to find a policy that fits your budget without compromising coverage.
                    </p>
                  </div>
                  


                </div>
              </div>
            </div>










            <div className="fixwidthDivtext">
              <div className="container">
              <h4 className='healthHeading'>What Are The Benefits of Owning A Two-Wheeler Insurance?</h4>
              <div className="yellowline"></div>
              <p className='textformat'>Significant benefits are listed below:</p>
              <div className="row">
              <div className="col-md-6 d-flex justify-content-center align-items-center">

           <div>

       
           {
            benifittwowhillerinsurence.map((data,index)=>{
              return(
                <Accordion
                expanded={expanded === data.id}
                onChange={handleChanges(data.id)}
              
              >
                <AccordionSummary
                  expandIcon={<MdOutlineExpandMore />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  className="accordingback"
                >
                  <IoMdAddCircle style={{ color: '#2959B8', marginLeft: '5px', marginRight: '5px' }} fontSize={'15pt'} />
                  <Typography>
                    <li className='listcol'>
                      {data.q}
                    </li>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className='AnwsarSpan'>
                  <p className='textformat'>{data.a}</p>  
                  </Typography>
                </AccordionDetails>
              </Accordion>
              )
            })
           }  
               </div>
                   </div>    
           <div  className="col-md-6 d-flex justify-content-center align-items-center">
                    <FastImage width={auto} height={auto} url="/Website%20Img/motor-insurance_15540384.png?updatedAt=1729493582222" className="fmailimagegifs"/>
                  </div>  
                 
                
                </div>
              </div>
            </div>






















            <div className="fixwidthDivtext">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <h4 className='healthHeading'>What Are the Add-ons Included to Your Two-Wheeler Insurance?</h4>
                    <div className="yellowline"></div>
                    <p className='textformat'>
                    Even though a comprehensive insurance provides optimum financial security, still certain objective lacks on certain grounds like extending its coverage. Hence, the insurance companies provide a list of add-ons to cover their blotches. Some of the add-ons are listed below for your better understanding:
                    </p>
                    <p className='textformat'>
                   <span className='specialprice'> Passenger Cover:–</span> When an individual ride a two-wheeler, both the rider and the co-passenger bear an equal amount of risk in case any accident happens. A bike insurance policy provides coverage only to the rider. To get the passenger covered, add-on covers are required as a support. Compensation is provided to the pillion in case of unfortunate incidents like death and disability.
                    </p>
                    <p className='textformat'>
                    <span className='specialprice'>Medical Cover:-</span>
                    This add-on cover supports you with the required financial cover against medical treatments related to the accident.
                    </p>
                    <p className='textformat'>
                    <span className='specialprice'>Accessories Cover:-</span>
                    If your two-wheeler is furnished with exorbitantly priced electrical and non-electrical accessories or retrofits, you will definitely want to restore them with proper insurance coverage. This add-on cover provides the required coverage against damage occurred to the expensive accessories. 
                    </p>
                    <p className='textformat'>
                    <span className='specialprice'>Nil Depreciation or Zero Depreciation Cover:-</span>
                    Under this option, the insurance provider shall provide the entire valued services (including depreciation charges) of two-wheeler to the insured during a claim.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="fixwidthDivtext">
              <div className="container">
                <div className="row">
                   <div className="col-md-12">
                    <h4 className='healthHeading'>How A Two-Wheeler Insurance Premium Is Calculated?</h4>
                    <div className="yellowline"></div>
                    <p className='textformat'>
                    Insurance Companies consider various factors while calculating the premium of an insurance plan. Below are the listed factors based on which a two-wheeler insurance premium is determined:
                    </p>
                    <p className='textformat'>
                    <span className='specialprice'>
                    Age:-
                      </span>    
                      The premium of an old two-wheeler is comparatively much higher if at all compared to the new ones because they bear much higher risks of malfunctioning. 
                    </p>
                    <p className='textformat'>
                    <span className='specialprice'>Insured Declared Value (IDV):-</span>
                    In case your two-wheeler is stolen or gets destroyed in any accident, your insurer will provide you the maximum sum assured called IDV, and that amount is also considered as the current market value of the two-wheeler. Therefore, one has to pay higher premium in case one has to avail the IDV or vice versa.
                    </p>
                    <p className='textformat'>
                    <span className='specialprice'>
                    Cubic Capacity:-
                    </span>
                    It is referred to the power of the engine. Because of higher fuel consumptions, a two-wheeler with a larger cubic capacity has higher insurance premiums.
                    </p>
                    <p className='textformat'>
                    <span className='specialprice'>
                    Depreciation:-
                    </span>
                    It is the depletion in the value of an asset over time because of continuous use, wear and tear. New bikes have no depreciation amount, whereas old bikes have higher depreciation amount. Hence, premium scales up along with increasing depreciation.
                    </p>
                    <p className='textformat'>
                    <span className='specialprice'>
                    No Claim Bonus (NCB):-
                    </span>
                    t is a type of discount that could be availed by making no claims for your two-wheeler during one policy year. No Claim Bonus is deducted from the premium amount you pay, and any deduction will only lead to a lower premium.
                    </p>
                    <p className='textformat'>
                    <span className='specialprice'>
                    Anti-Theft Devices:-
                    </span>
                    By establishing or installing anti-theft devices in the two-wheeler or being a part of the Automobile Association of India can get you discounts, which are deducted from the premiums.
                    </p>
                   </div>
                </div>
              </div>
            </div>

            <div className="fixwidthDivtext">
              <div className="container">
              <h4 className='healthHeading'>Why Should You Purchase Two-Wheeler Insurance Online?</h4>
              <div className="yellowline"></div>
              <p className='textformat'>
                    There are many additional benefits when you purchase a two-wheeler insurance through an online platform which you might not receive through the offline mode of purchase:
                    </p>
                <div className="row">
              
                  
                 
                 
                  <div className="col-md-12">
              
               
                
                  <>
                    {
                      WhySouldeYouPercheseTwowhellerinsurence.map((data,index)=>{
                        return(
                          <Accordion
                          expanded={expanded === data.id}
                          onChange={handleChanges(data.id)}
                          
                          >
                <AccordionSummary
                  expandIcon={<MdOutlineExpandMore />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  className="accordingback"
                  >
                  <IoMdAddCircle style={{ color: '#2959B8', marginLeft: '5px', marginRight: '5px' }} fontSize={'15pt'} />
                  <Typography>
                    <li className='listcol'>
                    {data.q}
                    </li>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className='AnwsarSpan'>
              
                   <p className='textformat'>{data.a}</p>  
                  </Typography>
                </AccordionDetails>
              </Accordion>
              )
            })
          }      
          </>
          <div className=" py-2">
 <p className='textformat'>There are many insurance providers who provide two-wheeler insurance to the consumers. Too much of browsing and analyzing may confuse you at the end of the day. But, Beema1 offers you an effortless and a continuous experience to purchase your required insurance plan without paying off too much. Below listed are few noteworthy factors that makes Beema1 one of the most notable and an admirable insurance web aggregator for buying your most desirable two-wheeler insurance policy.</p>
          </div>
          </div>


                
                </div>
              </div>
            </div>















<div className="fixwidthDivtext">
  <div className="container">
    <h4 className='healthHeading'>Why Select Beema1 For Two-Wheeler Insurance Purchase?</h4>
    <div className="yellowline"></div>
    <p className='textformat' style={{textAlign:'justify'}}>
    There are many insurance providers who provide two-wheeler insurance to the 
consumers. Too much of browsing and analyzing may confuse you at the end of the 
day. But, Beema1 offers you an effortless and a continuous experience to purchase 
your required insurance plan without paying off too much. Below listed are few 
noteworthy factors that makes Beema1 one of the most notable and an admirable 
insurance web aggregator for buying your most desirable two-wheeler insurance policy
    </p>
    <div className="row">
      <div className="col-md-12">


    {/* {
            WhySouldeYouPercheseTwowhellerinsurence2.map((data,index)=>{
              return(
                <Accordion
                expanded={expanded === data.id}
                onChange={handleChanges(data.id)}
              
              >
                <AccordionSummary
                  expandIcon={<MdOutlineExpandMore />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  className="accordingback"
                >
                  <IoMdAddCircle style={{ color: '#2959B8', marginLeft: '5px', marginRight: '5px' }} fontSize={'15pt'} />
                  <Typography>
                    <li className='listcol'>
                      {data.q}
                    </li>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className='AnwsarSpan'>
                  <p className='textformat'>{data.a}</p>  
                  </Typography>
                </AccordionDetails>
              </Accordion>
              )
            })
           }      */}
           {
            WhySouldeYouPercheseTwowhellerinsurence2.map((data)=>{
              return(
                <p className='textformat'>
                  <span className="specialprice">{data.q}</span>
                  {data.a}
                </p>
              )
            })
           }
      </div>

    </div>
  </div>
</div>

         


 















            <div className="fixwidthDivtext">
              <div className="container">
              <h4 className='healthHeading'>What’s The Process For Buying/Renewing Bike Insurance Policy From Beema1?</h4>
                <div className="yellowline"></div>
                <p className='textformat'>Insurance will protect your two-wheeler against any nature of obstacles and odds whether your two-wheeler is used for personal or commercial purpose.
                Beema1 will assist and guide you with free insurance quotes online that will help you in comparing different two-wheeler policies from all the top-rated insurance providers in India.</p>
                <div className="row">
                <div className="col-md-6 d-flex justify-content-center align-items-center">
                    <div className="bikestepperdivnew">

                       {
                        bikestepper.map((data)=>{
                          return(
                            <>
                            <div className={ openstepper === data.id ? "bikestepperwholedivactive" : "bikestepperwholediv"} onClick={()=>{  setopenstepper(openstepper === data.id ? null : data.id);}}>
                             <div className="numberdivbikeinsurence">{data.id}</div>
                             <div className={ openstepper === data.id ? "bikestepnameactive" : "bikestepname"}>{data.name} {openstepper === data.id ? <FaChevronUp/> : <FaChevronDown />}   </div>
                             
                            </div>
                            {
                              openstepper === data.id ? (
                                <div className="descriptiondivbikestepper">
                                <p className='textformat'>{data.description}</p>
                              </div>
                              ) : null
                            }
                        
                              </>
                          )
                        })
                       }
                          
                       


                      {/* <div className="bikestepperwholediv">
                       <div className="numberdivbikeinsurence">2</div>
                       <div className="bikestepname">compare two-wheeler insurance quotes’</div>
                      </div>
                      <div className="bikestepperwholediv">
                       <div className="numberdivbikeinsurence">3</div>
                       <div className="bikestepname">compare two-wheeler insurance quotes’</div>
                      </div>
                      <div className="bikestepperwholediv">
                       <div className="numberdivbikeinsurence">4</div>
                       <div className="bikestepname">compare two-wheeler insurance quotes’</div>
                      </div> */}
                  
                    </div>
                  </div>
                  <div className="col-md-6 d-flex justify-content-center align-items-center">
                     <FastImage width={auto} height={auto} className="fmailimagegifs" url={'/Website%20Img/motorbike_4491901.png?updatedAt=1729493309095'}/>
                  </div>
                </div>
              </div>
            </div>

            <div className="fixwidthDivtext">
              <div className="container">
              <h4 className='healthHeading'>Know Your Documentations for Your Two-Wheeler Insurance</h4>
                <div className="yellowline"></div>
                <p className='textformat'>Below mentioned are the list of documents for further reference: </p>
                <div className="row">
                <div className="col-md-6 d-flex justify-content-center align-items-center">
                     <FastImage width={auto} height={auto} className="fmailimagegifs" url={'/Website%20Img/document_17389553.png?updatedAt=1729490883395'}/>
                  </div>
                <div className="col-md-6 d-flex justify-content-center align-items-center">
                    <div className="bikestepperdivnew">

                       {
                        documents.map((data)=>{
                          return(
                            <>
                            <div className={ openstepper === data.id ? "bikestepperwholedivactive" : "bikestepperwholediv"} onClick={()=>{  setopenstepper(openstepper === data.id ? null : data.id);}}>
                             <div className="numberdivbikeinsurence">{data.num}</div>
                             <div className={ openstepper === data.id ? "bikestepnameactive" : "bikestepname"}>{data.name} {openstepper === data.id ? <FaChevronUp/> : <FaChevronDown />}   </div>
                             
                            </div>
                            {
                              openstepper === data.id ? (
                                <div className="descriptiondivbikestepper">
                                <p className='textformat'>{data.description}</p>
                              </div>
                              ) : null
                            }
                        
                              </>
                          )
                        })
                       }
                          
                      
                  
                    </div>
                  </div>
           
                </div>
              </div>
            </div>

            <div className="fixwidthDivtext">
              <div className="container">
                <h4 className="healthHeading">What Are Covered And What Are Not Covered Under Your Two-Wheeler Insurance Policy</h4>
                <div className="yellowline"></div>
                <p className='textformat'>Even the two-wheeler insurance plans have certain inclusions and exclusions. We have below mentioned about the most common things that generally all the insurance companies follow</p>
                <div className="row">
                  <div className="col-md-6">
                     <h5 className="specialprice">What is Covered ?</h5>
                     <br />
                     <p className='textformat'>
                     (1) It covers all the damages and losses caused to your two-wheeler by accident, theft or any man-made issues like, terrorism, riots, fire or strikes.
                     </p>
                     <p className='textformat'>
                    (2) Damages and losses caused to the two-wheeler by earthquake, fire or any other natural disasters.
                     </p>
                     <p className='textformat'>
                   (3)  A third-party liability insurance policy safeguards you (policyholder) against the claims raised by a third-party.
                     </p>
                     <p className='textformat'>
                   (4)  In case of any permanent disability or death, Personal Accident Cover is applicable.
                     </p>


                     </div>



<div className="col-md-6">

                     <h5 className="specialprice">What is Not Covered ?</h5>
                     <br />
                     <p className='textformat'>
                    (1) Damages caused to the two-wheeler while riding under the influence of drugs and alcohol or without carrying a valid driving license.
                     </p>
                     <p className='textformat'>
                    (2) Any type of damages occurred due to your two-wheeler while performing any illegal activities.
                     </p>
                     <p className='textformat'>
                    (3) The costs of the mechanical services due to continuous wear and tear round the year.
                     </p>
                     <p className='textformat'>
                    (4) Damages occurred due to any kind of nuclear risks.
                     </p>
</div>
                
                </div>
              </div>
            </div>

            <div className="fixwidthDivtext">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                  <h4 className='healthHeading'>Steps Taken for Filing A Two-Wheeler Insurance Claim</h4>
                  <div className="yellowline"></div>
                  <p className='textformat'>Every insurance company has determined a specific process for the customers to file a claim for their respective two-wheeler insurance. It is difficult to delve deep into the processes of claiming for two-wheeler insurance for all the insurance providers in the market, still we have stated some of the basic steps that one should follow at the time of making a claim</p>
                  <div className="row">

                  <div className="col-md-12">
                    <p className='textformat'><span className="specialprice">1.</span>Update your insurance provider by notifying them through a phone call or an email regarding the sudden incident and submit the essential details, such as the policy number, time and date of the mis happening. If any collision occurs, then the colliding vehicle’s number has to be noted down.</p>
                    <p className='textformat'><span className="specialprice">2.</span>in case of any accident or theft, a FIR needs to be registered to the nearest police station and keeping a copy of FIR is important.</p>
                    <p className='textformat'><span className="specialprice">3.</span>One inspection officer could be assigned at the site of the accident. If an inspection officer is not assigned, the process has to be carried on by proceeding with the two-wheeler repair.</p>
                    <p className='textformat'><span className="specialprice">4.</span>One can visit any network garage or any repair shop.</p>
                    <p className='textformat'><span className="specialprice">5.</span>You can raise a claim regarding cashless claim in case of a network garage. The amount is resolved and established between the supervisor of the network garage and your insurance provider.</p>
                    <p className='textformat'><span className="specialprice">6.</span>In case the repairing has to be done from any non-network garage, you can file for reimbursement by submitting the essential documents along with claim form to your insurance provider. The insurance company will definitely examine the details in such a case. Post approval, the insurance company will share the reimbursement amount directly in the registered bank account.</p>
                  </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="fixwidthDivtext">
            <section className="adv-dis-of-insurance">
              <div className="container add-data">
                <div className="row adv-dis-row">
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <div className="adv-dis-title">
                      <h4>Advantage</h4>
                    </div>
                    <div className="left-sid-adv adv-dis-box">
                      <div className="adv-dis-boxs">
                        <div className="adv-number">1</div>
                        <div className="adv-dis-text-box">
                          <h6>Legal Requirement</h6>
                          <p  className='textformat'>
                              Owner of the Bike&nbsp;needs liability assurance
                              for the Bike&nbsp;that he has recently purchased
                              Bike, procuring the right insurance policy and
                              protecting you from legal clauses.
                       
                          </p>
                        </div>
                      </div>
                      <div className="adv-dis-boxs">
                        <div className="adv-number">2</div>
                        <div className="adv-dis-text-box">
                          <h6>Secures The Driver And Passenger Liability</h6>
                          <p  className='textformat'>
                          
                              In case of any damage to drivers and passengers in
                              the events of accidents, the damage expenses are
                              caused by the insurance policy. You can enjoy
                              stress-less drive with all the benefits and the
                              facilities bundled in your insurance policy. There
                              are quite notable advantages of
                              Bike&nbsp;insurance premium.
                  
                            
                          </p>
                        </div>
                      </div>
                      <div className="adv-dis-boxs">
                        <div className="adv-number">3</div>
                        <div className="adv-dis-text-box">
                          <h6>No Claim Bonus (NCB)</h6>
                          <p  className='textformat'>
                       
                              No claim bonus is proposed to auto insurance
                              customers at the time of renewing their car
                              insurance plans, only if there is absolutely no
                              claim during the previous policy period. This perk
                              is a discount offered to the customers for not
                              making a claim in the previous policy period.
                          
                            
                          </p>
                        </div>
                      </div>
                      <div className="adv-dis-boxs">
                        <div className="adv-number">4</div>
                        <div className="adv-dis-text-box">
                          <h6>Personal Accident Cover</h6>
                          <p  className='textformat'>
              
                              This personal accident cover secures you from
                              death due to accident, permanent disability, and
                              the maximum amount paid under the
                              Bike&nbsp;insurance policy.
                           
                            
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <div className="adv-dis-title">
                      <h4>Disadvantage</h4>
                    </div>
                    <div className="right-sid-dis adv-dis-box">
                      <div className="adv-dis-boxs">
                        <div className="adv-number">1</div>
                        <div className="adv-dis-text-box">
                          <h6>Failures On Coverage</h6>
                          <p  className='textformat'>
                          
                              The major disadvantage of Bike&nbsp;insurance is
                              that your policy does not cover your entire
                              vehicle. Only the specific parts of the
                              Bike&nbsp;are damaged and the coverage works for
                              those specific parts of the vehicle, and the
                              policyholder needs to accurately verify the hidden
                              clauses in the document before purchasing the
                              policy.
                           
                            
                          </p>
                        </div>
                      </div>
                      <div className="adv-dis-boxs">
                        <div className="adv-number">2</div>
                        <div className="adv-dis-text-box">
                          <h6>Time Consuming Process</h6>
                          <p  className='textformat'>
          
                              Most of the insurance companies buy time to settle
                              the claim amount, this is where most of the
                              policyholders are suffer.
                         
                            
                          </p>
                        </div>
                      </div>
                      <div className="adv-dis-boxs">
                        <div className="adv-number">3</div>
                        <div className="adv-dis-text-box">
                          <h6>Hassle-Free Claim Settlement Process</h6>
                          <p  className='textformat'>
                      
                              A Bike&nbsp;insurance plan is compulsory to
                              protect your vehicle from financial burden in case
                              of any damages. Be wise, comparative while
                              evaluating and selecting auto insurance that
                              enhances the benefits in terms of excellent claim
                              settlement. Please verify the ratio of transparent
                              claim settlement for every insurance company and
                              get the best insurance policy.
                       
                        
                          </p>
                        </div>
                      </div>
                      <div className="adv-dis-boxs">
                        <div className="adv-number">4</div>
                        <div className="adv-dis-text-box">
                          <h6>Poor Customer Support</h6>
                          <p  className='textformat'>
     
                              We at Beema1 have a devoted team of agents that
                              will guide you with a thorough claim process for
                              the Bike&nbsp;insurance policy.
                  
                            
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            </div>

            <div className="fixwidthDivtext">
            <section className="claim-reiambursement-process">
              <div className="container">
                <div className="row first-section-of-fifth justify-content-center wci-row reia-process-row-one">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="tile-text-with-text-shadow">
                      <h6>Claim Reimbursement Processes</h6>
                    </div>
                 
                    <p className="textformat">
                     
            
                        In relation to motorcycle insurance, the procedure for
                        claiming reimbursements involves policyholders providing
                        documented evidence of damages or accidents involving
                        their bikes. Insurance companies evaluate the legitimacy
                        of these claims, taking into account factors like the
                        level of damage and coverage provided by the policy.
                        Upon approval, the agreed-upon amount of reimbursement
                        is then paid to the policyholder by the insurer. Digital
                        platforms are frequently employed to streamline and
                        accelerate the claim process, guaranteeing a prompt
                        resolution for motorcycle owners. Effective
                        communication and comprehensive documentation play a
                        pivotal role in ensuring a smooth and hassle-free
                        experience when it comes to claiming reimbursements in
                        the context of motorcycle insurance. A claim is settled
                        within a time period of 15 days to maximum 1 month. But
                        in case, the insurer feels the requirement for any
                        investigation, the time period for settlement could take
                        up to 6 months.
          
                    </p>
                    <p />
                  </div>
                </div>
              </div>
            </section>
            </div>
             <BikeStepper/> */}







            
            <PartnerCompany />
            <FAQ />
         










      </section>

    </div>
  );
};

export default BikeInsurence;
