import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Filter from "../PlansPage/Filter";
import Summary from "../../commonComponents/Summary";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import "../../CommonCssFiles/planstyle.css";
import Loader from "../../../AllPageComponents/Loader";
import "./AfterPlanFatch.css";
import { GoPlus } from "react-icons/go";
import { FaPlus } from "react-icons/fa6";
import { updateData } from "../../redux/Slice";
import { getMaxAge } from "../../helper/common";
import Redirect from "../../../AllPageComponents/Rederict";
import Swal from "sweetalert2";
import {
  afterPlanFetchApi,
  updateQuickQuoteCompanywise,
  AddonApi,
  updateAddonApi,
  updateDatabase,
} from "../../Api/Api";
import { filterCompany } from "../../helper/common";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Helmet } from "react-helmet-async";
import { formatToINR } from "../../../HomePage/MoneyHealper/Moneyhelper";
import { formatAmount } from "../PlansPage/HelperMoneyconverter/MoneyConverter";

const Afterplanfatch = () => {
  const [apiResponse, setApiResponse] = useState(null);
  const [loading, setLoading] = useState(false);

  const [oneData, setOneData] = useState();
  const [twoData, setTwoData] = useState();
  const [threeData, setThreeData] = useState();
  const [addonlist, setAddonList] = useState();

  const [addonArr, setAddonArr] = useState([]);

  const [deductibleVal, setDeductibleVal] = useState(25000);
  const [hdcVal, setHdcVal] = useState(1000);

  const [coverVal, setCoverVal] = useState("");

  const [stopLoading, setStopLoading] = useState(false);

  const [CoverAmountHandelChnage, setCoverAmountHandelChnage] = useState([]);

  const [showhdc, setShowHdc] = useState(false);
  const [maxAge, setMaxAge] = useState("");
  const [beforeAddon, setBeforeAddon] = useState();
  const [addonPrev, setAddonPrev] = useState([]);
  const [addonDataList, setAddonDataList] = useState([]);
  const [relDeductible, setRelDeductible] = useState(25000);

  const [hosDeductible, setHosDeductible] = useState(1000);
  const [pedDeduct, setPedDeduct] = useState("12 Months");
  const [redIll, setRedIll] = useState("6 Months");
  const [roomtype, setRoomType] = useState("Twin Sharing");

  const [planChoose , setPlanChoose] = useState("select plan");
  const [siChoose , setSiChoose] = useState("");

  const [smartData , setSmartData] = useState("Option 1");

  const handleError = (message) => {
    Swal.fire({
      title: "Error!",
      text: message,
      icon: "error",
      confirmButtonText: "OK",
    }).then((result) => {
      if (result.isConfirmed) {
        // Reload the page when user clicks "OK"
        window.location.reload();
      }
    });
    setStopLoading(true);
  };

  const dispatch = useDispatch();

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const unique_id = params.get("req_id");
  const plan_id = params.get("plan_id");
  const c_id = params.get("c_id");

  // let maxAge = "";

  let smartDataArr = [
    {
      option:"Option 1",
      des:"Lorem Ipsum is simply dummy ."
    },
    {
        option:"Option 2",
      des:"Lorem ipsum dolor sit amet "
    }
  ]

  const countMember = (response) => {
    try {
      if (response?.data?.request?.InsuredDetail && plan_id === "Family") {
        let adultPerson = 0;
        let childPerson = 0;
        let parentPerson = 0;
        const adultRelations = [
          "Self",
          "Wife",
          "Father",
          "Mother",
          "Grand Father",
          "Grand Mother",
          "Husband",
          "Brother",
          "Sister",
        ];
        const parentRelations = [
          "Father",
          "Mother",
          "Father In Law",
          "Mother In Law",
        ];
        const childRelations = ["Son", "Daughter"];
        response.data.request.InsuredDetail.forEach((item) => {
          if (adultRelations.includes(item.InsuredRelation)) {
            if (parentRelations.includes(item.InsuredRelation)) {
              parentPerson += 1;
            } else {
              adultPerson += 1;
            }
          } else if (childRelations.includes(item.InsuredRelation)) {
            childPerson += 1;
          }
        });
        if (
          (adultPerson === 2 && parentPerson === 0 && childPerson <= 6) ||
          (adultPerson === 2 && parentPerson === 2 && childPerson <= 6) ||
          (adultPerson === 2 && parentPerson === 2 && childPerson <= 6) ||
          (adultPerson === 2 && childPerson <= 6 && parentPerson === 0) ||
          (adultPerson === 2 && childPerson <= 6 && parentPerson === 1) ||
          (adultPerson === 1 && childPerson <= 6 && parentPerson === 0)
        ) {
          setShowHdc(true);
        } else {
          setShowHdc(false);
        }
      } else if (plan_id == "Individual") {
        setShowHdc(true);
      } else {
        setShowHdc(false);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const fetchPlan = async () => {
    try {
      setLoading(true); // Start loading
      const response = await afterPlanFetchApi(unique_id, c_id, plan_id);
      setCoverAmountHandelChnage(response.data.response.si);

      // console.log("Plan xxxxxx Data:", coverAmountSi);
      // console.log("the response in afterfetchplan", response.data.response);

      if (response.data.response == null) {
        setLoading(false);
        setStopLoading(true);

        handleError("Opps there is an Error");
        return;
      }

      if (response?.data?.request) {
        countMember(response);
      }

      if (response) {
        let maxage = getMaxAge(response?.data?.request?.InsuredDetail);
        setMaxAge(maxage);
      }
      setBeforeAddon(response.data.response);
      setApiResponse(response.data);
      setDeductibleVal(
        response.data.request.deductible
          ? response.data.request.deductible
          : 25000
      );
      setHdcVal(
        response.data.request.HDCRiderSI
          ? response.data.request.HDCRiderSI
          : 1000
      );

    

      if (plan_id == 2868) {
        setPedDeduct(
          response.data.request.reductioninwaitingperiodlimit &&
            response.data.request.reductioninwaitingperiodlimit != null
            ? response.data.request.reductioninwaitingperiodlimit
            : "12 Months"
        );

        setRelDeductible(
          response.data.request.voluntarydeductiblelimit &&
            response.data.request.voluntarydeductiblelimit != null
            ? response.data.request.voluntarydeductiblelimit
            : 25000
        );
        setRoomType(
          response?.data?.request?.roomrent_type &&
            response?.data?.request?.roomrent_type != null
            ? response?.data?.request?.roomrent_type
            : [3, 5].includes(response.data.request.sum_insured)
            ? "Twin Sharing"
            : [10, 15, 20].includes(response.data.request.sum_insured)
            ? "Actuals"
            : [25, 30, 50, 100].includes(response.data.request.sum_insured)
            ? "Single AC Private Room"
            : "Single AC Private Room"
        );
        setHosDeductible(
          response.data.request.cdb_limit &&
            response.data.request.cdb_limit != null
            ? response.data.request.cdb_limit
            : 1000
        );
      } else {
        setRelDeductible(
          response.data.request.voluntarydeductiblelimit &&
            response.data.request.voluntarydeductiblelimit != null
            ? response.data.request.voluntarydeductiblelimit
            : 25000
        );
        setPedDeduct(
          response.data.request.ped_waiting_period &&
            response.data.request.ped_waiting_period != null
            ? response.data.request.ped_waiting_period
            : "12 Months"
        );
        setRoomType(
          response?.data?.request?.roomrent_type &&
            response?.data?.request?.roomrent_type != null
            ? response?.data?.request?.roomrent_type
            : "Twin Sharing"
        );

        setRedIll(
          response?.data?.request?.reductioninwaitingperiodlimit &&
            response?.data?.request?.reductioninwaitingperiodlimit != null
            ? response?.data?.request?.reductioninwaitingperiodlimit
            : "6 Months"
        );

    

        setSiChoose(
          response?.data?.request?.opd_sum_insured &&
          response?.data?.request?.opd_sum_insured != null
          ? response?.data?.request?.opd_sum_insured
          : ""
        )

        setPlanChoose(
          response?.data?.request?.opd_plan &&
          response?.data?.request?.opd_plan != null
          ? response?.data?.request?.opd_plan
          : "select plan"
        )

        setSmartData(
          response?.data?.request?.super_charger_option &&
          response?.data?.request?.super_charger_option != null
          ? response?.data?.request?.super_charger_option
          : "Option 1"
        )

      
      }

      setCoverVal(response.data.request.sum_insured);

      if (response.data.request.addons) {
        let currentAddons = response.data.request.addons
          ? response.data.request.addons.split(",")
          : [];

        setAddonArr(currentAddons);
        setAddonPrev(currentAddons);
      }
      if (response.data.request) {
        setAddonDataList(response.data.request.addonPrice);
      }

      if (response.data.request.tenure == "1 Year") {
        setOneData(response.data.response.premiumBreakup.totalPremium);
      } else if (response.data.request.tenure == "2 Year") {
        setTwoData(response.data.response.premiumBreakup.totalPremium);
      } else if (response.data.request.tenure == "3 Year") {
        setThreeData(response.data.response.premiumBreakup.totalPremium);
      }
    } catch (error) {
      console.log("the error in afterfetchplan", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    fetchPlan();

    getAddon();
  }, []);

  const updatePerTenure = async (tenuredata) => {
    try {
      setLoading(true);

      let payload = {
        ...apiResponse.request,
        tenure: tenuredata,
        PlanCode: plan_id,
        CompanyName: c_id,
        addons: "",
        addonPrice: [],
        voluntarydeductiblelimit: null,
        roomrent_type: null,
        cdb_limit: null,
        ped_waiting_period: null,
        reductioninwaitingperiodlimit: null,
        opd_sum_insured:null,
        opd_plan:null,
        super_charger_option:null
      };

      const response = await updateQuickQuoteCompanywise(payload, unique_id);
      // console.log("come Companydata", apiResponse);

      if (response.data.response == null) {
        setStopLoading(true);
        handleError("Oops there is an error !");
      }

      // let filteredResponse = filterCompany(
      //   response.data.response,
      //   c_id,
      //   plan_id
      // );

      if (plan_id == 2868) {

     
          setPedDeduct(
            response.data.request.reductioninwaitingperiodlimit &&
              response.data.request.reductioninwaitingperiodlimit != null
              ? response.data.request.reductioninwaitingperiodlimit
              : "12 Months"
          );
      
        setRelDeductible(
          response.data.request.voluntarydeductiblelimit &&
            response.data.request.voluntarydeductiblelimit != null
            ? response.data.request.voluntarydeductiblelimit
            : 25000
        );
        setRoomType(
          response?.data?.request?.roomrent_type &&
            response?.data?.request?.roomrent_type != null
            ? response?.data?.request?.roomrent_type
            : [3, 5].includes(response.data.request.sum_insured)
            ? "Twin Sharing"
            : [10, 15, 20].includes(response.data.request.sum_insured)
            ? "Actuals"
            : [25, 30, 50, 100].includes(response.data.request.sum_insured)
            ? "Single AC Private Room"
            : "Single AC Private Room"
        );

        setHosDeductible(
          response?.data?.request?.cdb_limit &&
            response?.data?.request?.cdb_limit != null
            ? response.data.request.cdb_limit
            : 1000
        );
      } else {
        setRelDeductible(
          response.data.request.voluntarydeductiblelimit &&
            response.data.request.voluntarydeductiblelimit != null
            ? response.data.request.voluntarydeductiblelimit
            : 25000
        );
        setRoomType("Twin Sharing");
     
        if(plan_id != 2868 && c_id=="Reliance"){
          setPedDeduct(
            response.data.request.ped_waiting_period &&
              response.data.request.ped_waiting_period != null
              ? response.data.request.ped_waiting_period
              : "12 Months"
          );
        }

        setRedIll(
          response?.data?.request?.reductioninwaitingperiodlimit &&
            response?.data?.request?.reductioninwaitingperiodlimit != null
            ? response?.data?.request?.reductioninwaitingperiodlimit
            : "6 Months"
        );
        setSiChoose(
          response?.data?.request?.opd_sum_insured &&
          response?.data?.request?.opd_sum_insured != null
          ? response?.data?.request?.opd_sum_insured
          : ""
        )
        setPlanChoose(
          response?.data?.request?.opd_plan &&
          response?.data?.request?.opd_plan != null
          ? response?.data?.request?.opd_plan
          : "select plan"
        )
        setSmartData(
          response?.data?.request?.super_charger_option &&
          response?.data?.request?.super_charger_option != null
          ? response?.data?.request?.super_charger_option
          : "Option 1"
        )
      }

      let newData = {
        ...apiResponse,
        request: response.data.request,
        response: response.data.response,
      };

      setApiResponse(newData);
      if (response.data.response) {
        setAddonDataList([]);
        setAddonPrev([]);
        setBeforeAddon(response.data.response);
      }

      // console.log("the filteredCompany", filteredResponse);

      if (response.data.request.tenure == "1 Year") {
        setOneData(response.data.response.premiumBreakup.totalPremium);
      } else if (response.data.request.tenure == "2 Year") {
        setTwoData(response.data.response.premiumBreakup.totalPremium);
      } else if (response.data.request.tenure == "3 Year") {
        setThreeData(response.data.response.premiumBreakup.totalPremium);
      }
    } catch (error) {
      console.log("the error ", error);
      setStopLoading(true);
      handleError("Oops there is an error !");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const updateCoverAmount = async (coverData) => {
    try {
      setLoading(true);

      let payload = {
        ...apiResponse.request,
        sum_insured: coverData,
        PlanCode: plan_id,
        CompanyName: c_id,
        addons: "",
        addonPrice: [],
        roomrent_type: null,
        voluntarydeductiblelimit: null,
        cdb_limit: null,
        ped_waiting_period: null,
        reductioninwaitingperiodlimit: null,
        opd_sum_insured:null,
        opd_plan:null,
        super_charger_option:null
      };

      const response = await updateQuickQuoteCompanywise(payload, unique_id);
      if (response.data.response == null) {
        setLoading(true);
        handleError("Oops there isan error !");
      }
      setCoverVal(response.data.request.sum_insured);

      let newData = {
        ...apiResponse,
        response: response.data.response,
      };

      setAddonDataList([]);
      setAddonPrev([]);
      setBeforeAddon(response.data.response);

      if (plan_id == 2868) {
        setRelDeductible(
          response.data.request.voluntarydeductiblelimit &&
            response.data.request.voluntarydeductiblelimit != null
            ? response.data.request.voluntarydeductiblelimit
            : 25000
        );
        setRoomType(
          response?.data?.request?.roomrent_type &&
            response?.data?.request?.roomrent_type != null
            ? response?.data?.request?.roomrent_type
            : [3, 5].includes(response.data.request.sum_insured)
            ? "Twin Sharing"
            : [10, 15, 20].includes(response.data.request.sum_insured)
            ? "Actuals"
            : [25, 30, 50, 100].includes(response.data.request.sum_insured)
            ? "Single AC Private Room"
            : "Single AC Private Room"
        );

        setHosDeductible(
          response.data.request.cdb_limit &&
            response.data.request.cdb_limit != null
            ? response.data.request.cdb_limit
            : 1000
        );

        setPedDeduct(
          response.data.request.reductioninwaitingperiodlimit &&
            response.data.request.reductioninwaitingperiodlimit != null
            ? response.data.request.reductioninwaitingperiodlimit
            : "12 Months"
        );
      } else {
        setRelDeductible(
          response.data.request.voluntarydeductiblelimit &&
            response.data.request.voluntarydeductiblelimit != null
            ? response.data.request.voluntarydeductiblelimit
            : 25000
        );
        setRedIll(
          response?.data?.request?.reductioninwaitingperiodlimit &&
            response?.data?.request?.reductioninwaitingperiodlimit != null
            ? response?.data?.request?.reductioninwaitingperiodlimit
            : "6 Months"
        );
        setRoomType("Twin Sharing");
       
        if(plan_id != 2868 && c_id=="Reliance"){
          setPedDeduct(
            response.data.request.ped_waiting_period &&
              response.data.request.ped_waiting_period != null
              ? response.data.request.ped_waiting_period
              : "12 Months"
          );
        }
       
        setPlanChoose(
          response?.data?.request?.opd_plan &&
          response?.data?.request?.opd_plan != null
          ? response?.data?.request?.opd_plan
          : "select plan"
        );
        setSiChoose(
          response?.data?.request?.opd_sum_insured &&
          response?.data?.request?.opd_sum_insured != null
          ? response?.data?.request?.opd_sum_insured
          : ""
        )
        setSmartData(
          response?.data?.request?.super_charger_option &&
          response?.data?.request?.super_charger_option != null
          ? response?.data?.request?.super_charger_option
          : "Option 1"
        )
      }
      // console.log("the newData", newData);
      setApiResponse(response.data);

      if (response.data.request.tenure == "1 Year") {
        setOneData(response.data.response.premiumBreakup.totalPremium);
      } else if (response.data.request.tenure == "2 Year") {
        setTwoData(response.data.response.premiumBreakup.totalPremium);
      } else if (response.data.request.tenure == "3 Year") {
        setThreeData(response.data.response.premiumBreakup.totalPremium);
      }
    } catch (error) {
      setStopLoading(true);
      handleError("Oops there is an error !");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getAddon = async () => {
    try {
      const response = await AddonApi(c_id, plan_id).then((response) => {
        // console.log("the response in addon", response);
        setAddonList(response.addon_data.data);
      });
    } catch (error) {
      // console.log("the error in addonlist");
      setStopLoading(true);
      handleError("Oops there is an error !");
    }
  };

  const getPriceAddon = (responseValue) => {


    let prevData = beforeAddon.premiumBreakup.totalPremium;
    let newData = responseValue.response.premiumBreakup.totalPremium;
    // if (prevData.includes(",")) {
    //   prevData = prevData.replace(",", "");
    // }
    if (String(prevData).includes(",")) {
      prevData = String(prevData).replace(",", "");
    }
    // if (newData.includes(",")) {
    //   newData = newData.replace(",", "");
    // }
    if (String(newData).includes(",")) {
      newData = String(newData).replace(",", "");
    }

    let diff = Number(newData) - Number(prevData);

 

    let arr = addonDataList ? addonDataList : [];


    let addonlistArr = responseValue.request.addons;
    let arrayData;
    let arrayPrevData;
    if (addonlistArr) {
      arrayData = addonlistArr.split(",");
    }

    if (addonPrev && Array.isArray(addonPrev) == false) {
      arrayPrevData = addonPrev.split(",");
    } else {
      arrayPrevData = addonPrev || [];
    }

    let newAddons;

    if (addonPrev) {
      newAddons = arrayData.filter((addon) => !arrayPrevData.includes(addon));
    } else if (arrayPrevData.length == 0 && arrayData.length == 1) {
      newAddons = arrayData[0];
    }


    if (addonlistArr) {
   

      arrayData.map((item, index) => {
        if (item == newAddons) {
          arr.push({
            addonName: item,
            amount: diff,
          });
        }
      });
    }

 
    setAddonDataList(arr);

    setBeforeAddon(responseValue.response);

    if (addonlistArr) {
      setAddonPrev(addonlistArr);
    }

    return arr;
  };

  const putAddon = async (addoncode) => {
    try {
      setLoading(true);

      let currentAddons = apiResponse.request.addons
        ? apiResponse.request.addons.split(",")
        : [];

      // console.log("the current addons", currentAddons);

      if (!currentAddons.includes(addoncode)) {
        currentAddons.push(addoncode);
      }

      let updatedAddons = currentAddons.join(",");

      let payload = {};

      if (addoncode == "deductible") {
        payload = {
          ...apiResponse.request,
          addons: updatedAddons,
          // addonPrice : addonPrice ? addonPrice : '',
          CompanyName: c_id,
          PlanCode: plan_id,
          HDCRiderSI: apiResponse.request.HDCRiderSI
            ? apiResponse.request.HDCRiderSI
            : 0,
          deductible: Number(deductibleVal),
        };
      } else if (addoncode == "HDCRiderSI") {
        payload = {
          ...apiResponse.request,
          addons: updatedAddons,
          // addonPrice : addonPrice ? addonPrice : '',

          CompanyName: c_id,
          PlanCode: plan_id,
          HDCRiderSI: Number(hdcVal),
          deductible: apiResponse.request.deductible
            ? apiResponse.request.deductible
            : 0,
        };
      } else if (addoncode == "AD05" && c_id == "Reliance" && plan_id == 2868) {
        payload = {
          ...apiResponse.request,
          addons: updatedAddons,
          // addonPrice : addonPrice ? addonPrice : '',

          CompanyName: c_id,
          PlanCode: plan_id,
          HDCRiderSI: 0,
          deductible: 0,
          voluntarydeductiblelimit: relDeductible,
        };
      } else if (addoncode == "AD03" && c_id == "Reliance" && plan_id == 2868) {
        payload = {
          ...apiResponse.request,
          addons: updatedAddons,
          // addonPrice : addonPrice ? addonPrice : '',

          CompanyName: c_id,
          PlanCode: plan_id,
          HDCRiderSI: 0,
          deductible: 0,
          roomrent_type: roomtype,
        };
      } else if (
        addoncode == "AD10" &&
        c_id == "Reliance" &&
        ["28241", "28242", "28243"].includes(plan_id)
      ) {
        payload = {
          ...apiResponse.request,
          addons: updatedAddons,
          // addonPrice : addonPrice ? addonPrice : '',

          CompanyName: c_id,
          PlanCode: plan_id,
          HDCRiderSI: 0,
          deductible: 0,
          voluntarydeductiblelimit: relDeductible,
        };
      } else if (
        addoncode == "AD11" &&
        c_id == "Reliance" &&
        ["28241", "28242", "28243"].includes(plan_id)
      ) {
        payload = {
          ...apiResponse.request,
          addons: updatedAddons,
          // addonPrice : addonPrice ? addonPrice : '',

          CompanyName: c_id,
          PlanCode: plan_id,
          HDCRiderSI: 0,
          deductible: 0,
          roomrent_type: roomtype,
        };
      } else if (addoncode == "AD07" && c_id == "Reliance" && plan_id == 2868) {
        payload = {
          ...apiResponse.request,
          addons: updatedAddons,
          // addonPrice : addonPrice ? addonPrice : '',

          CompanyName: c_id,
          PlanCode: plan_id,
          HDCRiderSI: 0,
          deductible: 0,
          cdb_limit: hosDeductible,
        };
      } else if (addoncode == "AD08" && c_id == "Reliance" && plan_id != 2868) {
        payload = {
          ...apiResponse.request,
          addons: updatedAddons,
          // addonPrice : addonPrice ? addonPrice : '',

          CompanyName: c_id,
          PlanCode: plan_id,
          HDCRiderSI: 0,
          deductible: 0,
          ped_waiting_period: pedDeduct,
        };
      } 
      else if (addoncode == "AD04" && c_id == "Reliance" && plan_id == 2868) {
        payload = {
          ...apiResponse.request,
          addons: updatedAddons,
          // addonPrice : addonPrice ? addonPrice : '',

          CompanyName: c_id,
          PlanCode: plan_id,
          HDCRiderSI: 0,
          deductible: 0,
          reductioninwaitingperiodlimit: pedDeduct.split(" ")[0],
        };
      }
      else if (addoncode == "AD09" && c_id == "Reliance" && plan_id != 2868) {
        payload = {
          ...apiResponse.request,
          addons: updatedAddons,
          // addonPrice : addonPrice ? addonPrice : '',

          CompanyName: c_id,
          PlanCode: plan_id,
          HDCRiderSI: 0,
          deductible: 0,
          reductioninwaitingperiodlimit: redIll,
        };
      } 
      else if (addoncode == "AD04" && c_id == "Reliance" && plan_id != 2868) {
        payload = {
          ...apiResponse.request,
          addons: updatedAddons,
          // addonPrice : addonPrice ? addonPrice : '',

          CompanyName: c_id,
          PlanCode: plan_id,
          HDCRiderSI: 0,
          deductible: 0,
          opd_sum_insured: siChoose,
          opd_plan:planChoose
        };
      }
      else if (addoncode == "AD02" && c_id == "Reliance" && plan_id != 2868) {
        payload = {
          ...apiResponse.request,
          addons: updatedAddons,
          // addonPrice : addonPrice ? addonPrice : '',

          CompanyName: c_id,
          PlanCode: plan_id,
          HDCRiderSI: 0,
          deductible: 0,
          super_charger_option:smartData
        };
      }
      
      else {
        payload = {
          ...apiResponse.request,
          addons: updatedAddons,
          // addonPrice : addonPrice ? addonPrice : '',

          CompanyName: c_id,
          PlanCode: plan_id,
          HDCRiderSI: 0,
          deductible: 0,
        };
      }

      await updateAddonApi(unique_id, payload).then(async (responseData) => {
        setLoading(false);
        if (responseData.response == null) {
          setStopLoading(true);
        }

        setAddonArr(currentAddons);
        setApiResponse(responseData);

        if (responseData.response == null) {
          // alert("error")
          handleError("Oops there is an error !");
        }

        if (responseData.request.tenure == "1 Year") {
          setOneData(responseData.response.premiumBreakup.totalPremium);
        } else if (responseData.request.tenure == "2 Year") {
          setTwoData(responseData.response.premiumBreakup.totalPremium);
        } else if (responseData.request.tenure == "3 Year") {
          setThreeData(responseData.response.premiumBreakup.totalPremium);
        }
        if (responseData.response != null) {
          let addonPrice = getPriceAddon(responseData);

          let payloadData = {
            ...payload,
            addonPrice: addonPrice,
          };
          await updateDatabase(unique_id, payloadData);
        }
      });
    } catch (error) {
      // console.log("Error in putAddon function:", error);
      setStopLoading(true);
      handleError("Oops there is an error !");
    }
  };

  // if (!apiResponse && stopLoading == false) {
  //   return (
  //     <div>
  //       <Loader />
  //     </div>
  //   );
  // }
  // else if(apiResponse ? apiResponse.response == null : ""){
  //   setStopLoading(true)

  //   handleError("Oops there is an Error ")
  // }
  useEffect(() => {
    if (apiResponse && !apiResponse.response && !stopLoading) {
      setStopLoading(true);
      handleError("Oops, there is an error.");
    }
  }, [apiResponse, stopLoading]); // Depend on apiResponse and stopLoading

  if (!apiResponse && !stopLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Healthinsurance fetchplans</title>

        {/* <meta name="description" content="A brief description of your page for SEO." /> */}
        <meta
          name="keywords"
          content="Insurance Plan, Insurance Quotes, buy insurance"
        />
      </Helmet>

      {loading && <Loader />}

      {stopLoading == false && (
        //  maxAge &&
        <>
          <Redirect />{" "}
          <div className="container">
            <div className="row">
              <div className="col-lg-8 ">
                <div className="nextfatchtop ">
                  <div className="row text-center py-0">
                    <div className="col align-self-center">
                    
                      <div className="nextimg ">
                        <img
                          src={
                            apiResponse.response.insurerLogo ||
                            apiResponse.request.insurerLogo
                          }
                          alt="Image"
                          height={60}
                          width={70}
                        />
                      </div>
                    </div>
                    <div className="col align-self-center nexcont">
                      <div className="nexthead text-left">
                        <h1 className="nfh1 afterlogo">
                          {apiResponse.response.planName}
                          <br />
                          {/* <span
                          className="viewalloffers"
                          style={{
                            fontSize: 13,
                            top: 12,
                            marginLeft: "-10px",
                            padding: 7,
                            borderRadius: 5,
                          }}
                        >
                          view all features{" "}
                          <i
                            className="fa fa-chevron-right faaroc10px"
                            aria-hidden="true"
                          />
                        </span> */}
                        </h1>
                      </div>
                    </div>
                    <div className="col align-self-center">
                      <div className="nexthead ">
                        <p className="nfh1 cash">
                          <i className="fa fa-building" aria-hidden="true" />{" "}
                          <span className="hospitalnum">
                            {" "}
                            {c_id == "ICICI"
                              ? "9,700"
                              : c_id == "Care"
                              ? "10,632"
                              : c_id == "GoDigit"
                              ? "16,334"
                              : ""}
                          </span>
                          Cashless hospitals in India
                        </p>
                      </div>
                    </div>
                    <div className="col align-self-center">
                      <div className="nextcover text-center">
                        <p className="nfh1 colortexdesktop">Cover Amount</p>

                        <FormControl sx={{ m: 1, minWidth: "60%" }}>
                          <Select
                            value={coverVal}
                            onChange={(e) => {
                              updateCoverAmount(e.target.value);
                            }}
                            sx={{
                              boxShadow: "none",
                              height: "20px", // Adjust height
                              padding: "0px", // Adjust padding
                              ".MuiOutlinedInput-notchedOutline": { border: 0 },
                              "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                  border: 0,
                                },
                              "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  border: 0,
                                },
                            }}
                          >
                            {CoverAmountHandelChnage.map((data) => {
                              return (
                                <MenuItem value={data}>{data} lac</MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="nextfatchtopmobile">
                  <div className="blockdivmobilefetchplane">
                    <div className="nextfatchtopmobileplanename">
                      <div className="nexthead text-left">
                        <h1 className="nfh1 afterlogo">
                          {apiResponse.response.planName}
                        </h1>
                      </div>
                    </div>
                    <div className="flexdisplayafterfetchplane">
                      <div className="nextfatchtopmobileimg">
                      

                        <img
                          src={
                            apiResponse.response.insurerLogo ||
                            apiResponse.request.insurerLogo
                          }
                          alt="Image"
                          height={60}
                          width={70}
                        />
                      </div>
                      <div className="col align-self-center">
                        <div className="nexthead ">
                          <p className="nfh1 cash">
                            <i className="fa fa-building" aria-hidden="true" />{" "}
                            <span className="hospitalnum">
                              {" "}
                              {c_id == "ICICI"
                                ? "9,700"
                                : c_id == "Care"
                                ? "10,632"
                                : c_id == "GoDigit"
                                ? "16,334"
                                : ""}
                            </span>
                            Cashless hospitals in India
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="flexdisplayafterfetchplane">
                      <div className="col mobilecoverafterfetch">
                        <div className="nextcover text-center">
                          <p className="nfh1 colortex">Cover Amount</p>

                          <FormControl sx={{ m: 1, minWidth: "60%" }}>
                            <Select
                              value={coverVal}
                              onChange={(e) => {
                                updateCoverAmount(e.target.value);
                              }}
                              sx={{
                                boxShadow: "none",
                                height: "20px", // Adjust height
                                padding: "0px", // Adjust padding
                                ".MuiOutlinedInput-notchedOutline": {
                                  border: 0,
                                },
                                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                  {
                                    border: 0,
                                  },
                                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    border: 0,
                                  },
                              }}
                            >
                              {CoverAmountHandelChnage.map((data) => {
                                return (
                                  <MenuItem value={data}>{data} lac</MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="nextsection">
                  <h1 className="nfh1 colortex">Policy and Period</h1>
                  {/* <p className="para">
                  Easy EMI options starting from ₹2,454/month.{" "}
                  <span className="after-view">View Details</span>
                </p> */}

                  {maxAge < 96 ? (
                    <>
                      <div className="row text-center py-0">
                        <div className="col align-self-center">
                          <div
                            className={`form-group form-check ppriod ${
                              apiResponse.request.tenure === "1 Year"
                                ? "oneshowdata"
                                : ""
                            }`}
                            onClick={() => {
                              updatePerTenure("1 Year");
                            }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input"
                              defaultChecked=""
                              style={{ visibility: "hidden" }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleCheck1"
                            >
                              <strong className="afterplan-strong">
                                {" "}
                                1 Year {oneData ? formatToINR(oneData) : ""}
                              </strong>
                            </label>
                          </div>
                        </div>
                        <div className="col align-self-center">
                          <div
                            className={`form-group form-check ppriod ${
                              apiResponse.request.tenure === "2 Year"
                                ? "oneshowdata"
                                : ""
                            }`}
                            onClick={() => {
                              updatePerTenure("2 Year");
                            }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input"
                              style={{ visibility: "hidden" }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleCheck1"
                            >
                              <strong className="afterplan-strong">
                                {" "}
                                <>
                                  2 Year {twoData ? formatToINR(twoData) : ""}
                                </>
                              </strong>
                            </label>
                          </div>
                        </div>
                        <div className="col align-self-center">
                          <div
                            className={`form-group form-check ppriod ${
                              apiResponse.request.tenure === "3 Year"
                                ? "oneshowdata"
                                : ""
                            }`}
                            onClick={() => {
                              updatePerTenure("3 Year");
                            }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input"
                              style={{ visibility: "hidden" }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleCheck1"
                            >
                              <strong className="afterplan-strong">
                                {" "}
                                3 Year {threeData ? formatToINR(threeData) : ""}
                              </strong>
                            </label>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>

                {apiResponse.response.insurerName == "HDFC" && (
                  <div className="nextsection ">
                    {addonlist &&
                      addonlist.map((element) => {
                        if (element.code == "deductible") {
                          return maxAge < element.age ? null : (
                            <div className="deductible-section">
                              <h1 className="nfh1 colortex">
                                Deductible Discount
                              </h1>
                              <p className="para">
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry. Lorem Ipsum has been
                                the industry
                              </p>
                              <div className="reccard" key={element.code}>
                                <div className="row text-center py-0 reccardline">
                                  <div className="col align-self-center reccard-img">
                                    <div className="nextimg">
                                      <img
                                        src={apiResponse.response.insurerLogo}
                                        alt="Image"
                                        className="recimg"
                                      />
                                    </div>
                                  </div>
                                  <div className="col align-self-center">
                                    <div
                                      className="nexthead"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <h1 className="nfh1 addonname">
                                        {element.name}
                                      </h1>
                                      {element.code === "deductible" && (
                                        <div
                                          className="discriptionse"
                                          style={{ marginRight: "-23px" }}
                                        >
                                          <span className="insurtext">
                                            {loading && <Loader />}

                                            {/* <select
                                              onChange={(e) => {
                                                setDeductibleVal(
                                                  e.target.value
                                                );
                                              }}
                                              value={deductibleVal}
                                            >
                                              <option value="25000">
                                                25000
                                              </option>
                                              <option value="50000">
                                                50000
                                              </option>
                                              <option value="100000">
                                                100000
                                              </option>
                                            </select> */}

                                            <FormControl
                                              variant="standard"
                                              sx={{ minWidth: "100%" }}
                                            >
                                              <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                onChange={(e) => {
                                                  setDeductibleVal(
                                                    e.target.value
                                                  );
                                                }}
                                                value={deductibleVal}
                                              >
                                                <MenuItem value={25000}>
                                                  25000
                                                </MenuItem>
                                                <MenuItem value={50000}>
                                                  50000
                                                </MenuItem>
                                                <MenuItem value={100000}>
                                                  100000
                                                </MenuItem>
                                              </Select>
                                            </FormControl>
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    className="col align-self-center"
                                    style={{
                                      padding: "0px",
                                      maxWidth: "162px",
                                    }}
                                  >
                                    {addonDataList &&
                                    addonDataList.some(
                                      (data) => data.addonName == element.code
                                    ) ? (
                                      <button className="recbtn">Added</button>
                                    ) : (
                                      <button
                                        className="recbtn"
                                        onClick={() => {
                                          putAddon(element.code);
                                        }}
                                      >
                                        <FaPlus
                                          style={{
                                            marginTop: "-2px",
                                            height: "13px",
                                          }}
                                        />
                                        Add
                                      </button>
                                    )}
                                  </div>
                                </div>
                                <div className="addon-extra-hdfc">
                                  <p className="reccard-des">
                                    Description:
                                    <span className="des-span">
                                      {element.description}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })}
                  </div>
                )}
                <div className="nextsection ">
                  <h1 className="nfh1 colortex">Recomended Add-Ons</h1>
                  {/* <p className="para">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry
                </p> */}

                  {/* {console.log("the addonDataList in after", addonDataList)} */}

                  {addonlist &&
                    addonlist.map((item, index) => {
                      if (
                        item.code != "deductible" &&
                        item.code != "HDCRiderSI" &&
                        c_id != "Reliance"
                      ) {
                        // console.log("item", item);
                        // alert(maxAge)
                        return maxAge < item.age ||
                          item.code === "WBS1146" ||
                          item.code === "AACS1147" ||
                          // (item.code == "AD03" &&
                          //   apiResponse.request.tenure != "3 Year") ||
                          (item.code == "1" && plan_id == "28241") ||
                          (item.code == "2" && plan_id == "28242") ||
                          (item.code == "3" && plan_id == "28243") ? null : (
                          <div className="reccard">
                            <div className="reccardline">
                              <div className="col reccard-img">
                                <div className="nextimg">
                                  <img
                                    src={apiResponse.response.insurerLogo}
                                    alt="Image"
                                    className="recimg"
                                  />
                                </div>
                              </div>
                              <div className="col textaddonname">
                                <div className="nexthead">
                                  <h1 className="nfh1 addonname">
                                    {item.name}
                                  </h1>
                                </div>
                              </div>
                              <div
                                className="col buttonaddon"
                                style={{ padding: "0px", maxWidth: "162px" }}
                              >
                               
                                {addonDataList &&
                                addonDataList.some(
                                  (data) => data.addonName == item.code
                                ) ? (
                                  <button className="recbtn" disabled={true}>
                                    Added
                                  </button>
                                ) : (
                                  <button
                                    className="recbtn"
                                    onClick={() => {
                                      putAddon(item.code);
                                    }}
                                  >
                                    <svg
                                      stroke="currentColor"
                                      fill="currentColor"
                                      strokeWidth={0}
                                      viewBox="0 0 448 512"
                                      height="1em"
                                      width="1em"
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{ marginTop: "-2px", height: 13 }}
                                    >
                                      <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                                    </svg>
                                    Add
                                  </button>
                                )}
                              </div>
                            </div>
                            <div className="reccardlinemobile">
                              <div className="col textaddonnamemobile">
                                <div className="nexthead">
                                  <h1 className="nfh1 addonname">
                                    {item.name}
                                  </h1>
                                </div>
                              </div>

                              <div className="mobileflexaddonsection">
                                <div className="col reccard-imgmobile">
                                  <div className="nextimg">
                                    <img
                                      src={apiResponse.response.insurerLogo}
                                      alt="Image"
                                      className="recimg"
                                    />
                                  </div>
                                </div>

                                <div
                                  className="col buttonaddonmobile"
                                  style={{ padding: "0px", maxWidth: "162px" }}
                                >
                                  {addonDataList &&
                                  addonDataList.some(
                                    (data) => data.addonName == item.code
                                  ) ? (
                                    <button className="recbtn" disabled={true}>
                                      Added
                                    </button>
                                  ) : (
                                    <button
                                      className="recbtn"
                                      onClick={() => {
                                        putAddon(item.code);
                                      }}
                                    >
                                      <svg
                                        stroke="currentColor"
                                        fill="currentColor"
                                        strokeWidth={0}
                                        viewBox="0 0 448 512"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                        style={{
                                          marginTop: "-2px",
                                          height: 13,
                                        }}
                                      >
                                        <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                                      </svg>
                                      Add
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="addon-extra-hdfc">
                              <p className="reccard-des">
                                Description:{" "}
                                <span className="des-span">
                                  {item.description}
                                </span>
                              </p>
                            </div>
                          </div>
                        );
                      }
                    })}

                  {addonlist &&
                    addonlist.map((item, index) => {
                      if (
                        item.code != "deductible" &&
                        item.code != "HDCRiderSI" &&
                        c_id == "Reliance"
                      ) {
                   

                        return maxAge < item.age ||
                          (item.code == "AD06" && maxAge < 60) ||
                          // (item.code == "AD03" &&
                          //   apiResponse.request.tenure != "3 Year") ||
                          (item.code == "1" && plan_id == "28241") ||
                          (item.code == "2" && plan_id == "28242") ||
                          (item.code == "3" && plan_id == "28243") ? null : (
                          <div className="reccard">
                            <div className="row text-center py-0 reccardline">
                              <div className="col align-self-center reccard-img">
                                <div className="nextimg">
                                  <img
                                    src={apiResponse.response.insurerLogo}
                                    alt="Image"
                                    className="recimg"
                                  />
                                </div>
                              </div>

                              <div
                                className="col nexthead"
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "0px",
                                  justifyContent: "left",
                                  alignItems: "center",
                                }}
                              >
                                <h1 className="nfh1 addonname">{item.name}</h1>

                                {/* {item.name ==
                                  "Voluntary Aggregate Deductible" && */}
                                  {item.code == "AD05" &&
                                  plan_id == 2868 && (
                                    <FormControl
                                      variant="standard"
                                      sx={{
                                        minWidth: "118px",
                                        // marginTop: "15px",
                                        marginLeft: "9px",
                                        position: "relative",
                                        right: "0px",
                                      }}
                                    >
                                      <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        onChange={(e) => {
                                          setRelDeductible(e.target.value);
                                        }}
                                        value={relDeductible}
                                      >
                                        <MenuItem value={25000}>25000</MenuItem>
                                        <MenuItem value={50000}>50000</MenuItem>
                                        <MenuItem value={100000}>
                                          100000
                                        </MenuItem>
                                      </Select>
                                    </FormControl>
                                  )}

                                    {/* ------------------------------ */}
                                { 
                                  
                                  item.code == "AD08" &&  plan_id != 2868 && c_id == "Reliance" && (
                                    <FormControl
                                      variant="standard"
                                      sx={{
                                        minWidth: "118px",
                                        // marginTop: "15px",
                                        marginLeft: "9px",
                                        position: "relative",
                                        right: "0px",
                                      }}
                                    >
                                      <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        onChange={(e) => {
                                          setPedDeduct(e.target.value);
                                        }}
                                        value={pedDeduct}
                                      >
                                        <MenuItem value={"12 Months"}>
                                          12 Months
                                        </MenuItem>
                                        <MenuItem value={"24 Months"}>
                                          24 Months
                                        </MenuItem>
                                        {/* <MenuItem value={"48 Months"}>
                                          48 Months
                                        </MenuItem> */}
                                      </Select>
                                    </FormControl>
                                  )}


                                { 
                                 item.code == "AD04" && plan_id == 2868 &&
                                  
                                  c_id == "Reliance" && (
                                    <FormControl
                                      variant="standard"
                                      sx={{
                                        minWidth: "118px",
                                        // marginTop: "15px",
                                        marginLeft: "9px",
                                        position: "relative",
                                        right: "0px",
                                      }}
                                    >
                                      <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        onChange={(e) => {
                                          setPedDeduct(e.target.value);
                                        }}
                                        value={pedDeduct}
                                      >
                                        <MenuItem value={"12 Months"}>
                                          12 Months
                                        </MenuItem>
                                        <MenuItem value={"24 Months"}>
                                          24 Months
                                        </MenuItem>
                                        {/* <MenuItem value={"48 Months"}>
                                          48 Months
                                        </MenuItem> */}
                                      </Select>
                                    </FormControl>
                                  )}
                                    
                                  {/* ------------------------------ */}
                                {
                                  item.code == "AD10" &&
                                  plan_id != 2868 && (
                                    <FormControl
                                      variant="standard"
                                      sx={{
                                        minWidth: "118px",
                                        // marginTop: "15px",
                                        marginLeft: "9px",
                                        position: "relative",
                                        right: "0px",
                                      }}
                                    >
                                      <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        onChange={(e) => {
                                          setRelDeductible(e.target.value);
                                        }}
                                        value={relDeductible}
                                      >
                                        <MenuItem value={10000}>10000</MenuItem>
                                        <MenuItem value={25000}>25000</MenuItem>
                                        <MenuItem value={50000}>50000</MenuItem>
                                        <MenuItem value={100000}>
                                          100000
                                        </MenuItem>
                                      </Select>
                                    </FormControl>
                                  )}

                                  {/* ------------------------------ */}
                                {item.code == "AD11" && plan_id != 2868 && (
                                  <FormControl
                                    variant="standard"
                                    sx={{
                                      minWidth: "118px",
                                      // marginTop: "15px",
                                      marginLeft: "9px",
                                      position: "relative",
                                      right: "0px",
                                    }}
                                  >
                                    <Select
                                      labelId="demo-simple-select-standard-label"
                                      id="demo-simple-select-standard"
                                      onChange={(e) => {
                                        setRoomType(e.target.value);
                                      }}
                                      value={roomtype}
                                    >
                                      <MenuItem value={"Twin Sharing"}>
                                        Twin Sharing
                                      </MenuItem>
                                      <MenuItem
                                        value={"Single AC Private Room"}
                                      >
                                        Single AC Private Room
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                )}

                                  {/* ------------------------------ */}

                                { item.code == "AD03" && plan_id == 2868 && (
                                  <FormControl
                                    variant="standard"
                                    sx={{
                                      minWidth: "118px",
                                      // marginTop: "15px",
                                      marginLeft: "9px",
                                      position: "relative",
                                      right: "0px",
                                    }}
                                  >
                                    {/* <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                onChange={(e) => {
                                                  setRoomType(
                                                    e.target.value
                                                  );
                                                }}
                                                value={roomtype}
                                              >
                                                {
                                                  apiResponse.request.sum_insured == (3 || 5) &&
                                                  <MenuItem value={"Single AC Private Room"}>
                                                Single AC Private Room
                                                </MenuItem>}


                                               {apiResponse.request.sum_insured ==(3 || 5 ||10 ||15 || 20) && <MenuItem value={"Twin Sharing"}>
                                                Twin Sharing	
                                                </MenuItem>}
                                               {apiResponse.request.sum_insured ==(3 || 5 ||10 ||15 || 20 || 25 ||30 ||50||100) && 
                                               <MenuItem value={"Actuals"}>
                                                Actuals
                                                </MenuItem>}
                                              </Select> */}

                                    {apiResponse &&
                                      ([3, 5].includes(
                                        apiResponse.request.sum_insured
                                      ) ? (
                                        <Select
                                          labelId="demo-simple-select-standard-label"
                                          id="demo-simple-select-standard"
                                          onChange={(e) => {
                                            setRoomType(e.target.value);
                                          }}
                                          value={roomtype}
                                        >
                                          <MenuItem value={"Twin Sharing"}>
                                            Twin Sharing
                                          </MenuItem>
                                        </Select>
                                      ) : [10, 15, 20].includes(
                                          apiResponse.request.sum_insured
                                        ) ? (
                                        <Select
                                          labelId="demo-simple-select-standard-label"
                                          id="demo-simple-select-standard"
                                          onChange={(e) => {
                                            setRoomType(e.target.value);
                                          }}
                                          value={roomtype}
                                        >
                                          <MenuItem value={"Twin Sharing"}>
                                            Twin Sharing
                                          </MenuItem>
                                          <MenuItem value={"Actuals"}>
                                            Actuals
                                          </MenuItem>
                                        </Select>
                                      ) : [25, 30, 50, 100].includes(
                                          apiResponse.request.sum_insured
                                        ) ? (
                                        <Select
                                          labelId="demo-simple-select-standard-label"
                                          id="demo-simple-select-standard"
                                          onChange={(e) => {
                                            setRoomType(e.target.value);
                                          }}
                                          value={roomtype}
                                        >
                                          <MenuItem
                                            value={"Single AC Private Room"}
                                          >
                                            Single AC Private Room
                                          </MenuItem>
                                        </Select>
                                      ) : null)}
                                  </FormControl>
                                )}

                                {/* {item.name == "Hospital Cash Limit" && */}
                                {item.code == "AD07"&&
                                  plan_id == 2868 && (
                                    <FormControl
                                      variant="standard"
                                      sx={{
                                        minWidth: "118px",
                                        // marginTop: "15px",
                                        marginLeft: "9px",
                                        position: "relative",
                                        right: "0px",
                                      }}
                                    >
                                      <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        onChange={(e) => {
                                          setHosDeductible(e.target.value);
                                        }}
                                        value={hosDeductible}
                                      >
                                        <MenuItem value={1000}>1000</MenuItem>
                                        <MenuItem value={1500}>1500</MenuItem>
                                        <MenuItem value={2000}>2000</MenuItem>
                                        <MenuItem value={2500}>2500</MenuItem>
                                      </Select>
                                    </FormControl>
                                  )}

                                { item.code== "AD09"&&
                                  plan_id != 2868 && (
                                    <FormControl
                                      variant="standard"
                                      sx={{
                                        minWidth: "118px",
                                        // marginTop: "15px",
                                        marginLeft: "9px",
                                        position: "relative",
                                        right: "0px",
                                      }}
                                    >
                                      <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        onChange={(e) => {
                                          setRedIll(e.target.value);
                                        }}
                                        value={redIll}
                                      >
                                        <MenuItem value={"6 Months"}>
                                          6 Months
                                        </MenuItem>
                                        <MenuItem value={"12 Months"}>
                                          12 Months
                                        </MenuItem>
                                      </Select>
                                    </FormControl>
                                  )}

                                {item.code == "AD04" && 
                                  plan_id != 2868 && (
                                    <FormControl
                                      variant="standard"
                                      sx={{
                                        minWidth: "118px",
                                        // marginTop: "15px",
                                        marginLeft: "9px",
                                        position: "relative",
                                        right: "0px",
                                      }}
                                    >
                                     {
                                      apiResponse.request.sum_insured < 10 && 
                                      <Select
                                      labelId="demo-simple-select-standard-label"
                                      id="demo-simple-select-standard"
                                      onChange={(e) => {
                                        setPlanChoose(e.target.value);
                                        if(e.target.value == "Plan A" ){
                                          setSiChoose(10000)

                                        }
                                        else if(e.target.value == "Plan B"){
                                          setSiChoose(25000)

                                        }
                                      }}
                                      value={planChoose}
                                    >
                                      <MenuItem value={"select plan"}>
                                      select plan
                                      </MenuItem>
                                      <MenuItem value={"Plan A"}>
                                       Plan A
                                      </MenuItem>
                                      <MenuItem value={"Plan B"}>
                                        Plan B
                                      </MenuItem>
                                    </Select>
                                     }
                                     {
                                      apiResponse.request.sum_insured >= 10 && 
                                      <Select
                                      labelId="demo-simple-select-standard-label"
                                      id="demo-simple-select-standard"
                                      onChange={(e) => {
                                        setPlanChoose(e.target.value);
                                        if(e.target.value == "Plan A" ){
                                          setSiChoose(10000)

                                        }
                                        else if(e.target.value == "Plan B"){
                                          setSiChoose(25000)

                                        }
                                      }}
                                      value={planChoose}
                                    >
                                      <MenuItem value={"select plan"}>
                                      select plan
                                      </MenuItem>
                                      <MenuItem value={"Plan A"}>
                                       Plan A
                                      </MenuItem>
                                      <MenuItem value={"Plan B"}>
                                        Plan B
                                      </MenuItem>
                                    </Select>
                                     }
                                     
                                    </FormControl>
                                  )} 

                              {item.code == "AD04" && 
                                  plan_id != 2868 && planChoose != "select plan" && (
                                    <FormControl
                                      variant="standard"
                                      sx={{
                                        minWidth: "118px",
                                        // marginTop: "15px",
                                        marginLeft: "9px",
                                        position: "relative",
                                        right: "0px",
                                      }}
                                    >
                                       {
                                        planChoose == "Plan A" &&
                                        <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        onChange={(e) => {
                                          setSiChoose(e.target.value);
                                        }}
                                        value={siChoose}
                                      >
                                        <MenuItem value={10000}>
                                         10000
                                        </MenuItem>
                                        <MenuItem value={20000}>
                                          20000
                                        </MenuItem>
                                      </Select>
                                      }
                                      {
                                        planChoose == "Plan B" && 
                                        <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        onChange={(e) => {
                                          setSiChoose(e.target.value);
                                        }}
                                        value={siChoose}
                                      >
                                        
                                        <MenuItem value={25000}>
                                          25000
                                        </MenuItem>
                                        <MenuItem value={50000}>
                                          50000
                                        </MenuItem>
                                      </Select>
                                      }
                                     
                                    </FormControl>
                                  )} 

                                {item.code == "AD02" &&
                                  plan_id != 2868 && (
                                    <FormControl
                                      variant="standard"
                                      sx={{
                                        minWidth: "118px",
                                        // marginTop: "15px",
                                        marginLeft: "9px",
                                        position: "relative",
                                        right: "0px",
                                      }}
                                    >
                                     
                                        <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        onChange={(e) => {
                                          setSmartData(e.target.value);
                                        }}
                                        value={smartData}
                                      >
                                        <MenuItem value={"Option 1"}>
                                          Option 1
                                        </MenuItem>
                                        <MenuItem value={"Option 2"}>
                                          Option 2
                                        </MenuItem>
                                      </Select>
                                  
                                    
                                     
                                    </FormControl>
                                  )} 
                                  
                              </div>

                              <div
                                className="col align-self-center"
                                style={{ padding: "0px", maxWidth: "162px" }}
                              >
                                {addonDataList &&
                                addonDataList.some(
                                  (data) => data.addonName == item.code
                                ) ? (
                                  <button className="recbtn" disabled={true}>
                                    Added
                                  </button>
                                ) : (
                                  <button
                                    className="recbtn"
                                    onClick={() => {
                                      if(c_id == "Reliance" && plan_id != 2868 ){
                                        if( item.code == "AD04" &&
                                          planChoose != "select plan"  ){
                                            putAddon(item.code);
        
                                          }
                                         else if(item.code != "AD04" ){
                                            putAddon(item.code);
        
        
                                         }
                                      }
                                      else{
                                        putAddon(item.code);

                                      }
                                  
                                
                                    }}
                                  >
                                    <svg
                                      stroke="currentColor"
                                      fill="currentColor"
                                      strokeWidth={0}
                                      viewBox="0 0 448 512"
                                      height="1em"
                                      width="1em"
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{ marginTop: "-2px", height: 13 }}
                                    >
                                      <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                                    </svg>
                                    Add
                                  </button>
                                )}
                              </div>
                            </div>
                            <div className="addon-extra-hdfc">
                              <p className="reccard-des">
                                Description:{" "}
                                <span className="des-span">
                                  {
                                  (item.code == "AD02" && c_id == "Reliance" && plan_id != 2868 && smartData )?

                                    
                                    smartDataArr.map((val,index)=>{
                                     if(val.option == smartData){
                                      return val.des
                                     }
                                     
                                    })
                                    : ""
                             

                                  }
                                  {!(item.code == "AD02" && c_id == "Reliance" && plan_id != 2868 && smartData )?
                                  item.description:""}
                                     
                                </span>
                              </p>
                            </div>
                          </div>
                        );
                      }
                    })}

                  {addonlist &&
                    addonlist.map((item, index) => {
                      if (item.code == "HDCRiderSI" && showhdc == true) {
                        return maxAge < item.age ? null : (
                          <div className="reccard" key={item.code}>
                            <div className="row text-center py-0 reccardline">
                              <div className="col align-self-center reccard-img">
                                <div className="nextimg">
                                  <img
                                    src={apiResponse.response.insurerLogo}
                                    alt="Image"
                                    className="recimg"
                                  />
                                </div>
                              </div>
                              <div className="col align-self-center">
                                <div
                                  className="nexthead"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <h1 className="nfh1 addonname">
                                    {item.name}
                                  </h1>
                                  {item.code === "HDCRiderSI" && (
                                    <div
                                      className="discriptionse"
                                      style={{ marginRight: "-23px" }}
                                    >
                                      <span className="insurtext">
                                        {loading && <Loader />}

                                        {/* <select
                                      onChange={(e) => {
                                        setHdcVal(e.target.value);
                                      }}
                                      value={hdcVal}
                                    >
                                      <option value="1000">1000</option>
                                      <option value="2000">2000</option>
                                    </select> */}

                                        <FormControl
                                          variant="standard"
                                          sx={{ minWidth: "100%" }}
                                        >
                                          <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            onChange={(e) => {
                                              setHdcVal(e.target.value);
                                            }}
                                            value={hdcVal}
                                          >
                                            <MenuItem value={1000}>
                                              1000
                                            </MenuItem>
                                            <MenuItem value={2000}>
                                              2000
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div
                                className="col align-self-center"
                                style={{ padding: "0px", maxWidth: "162px" }}
                              >
                              
                                {addonDataList &&
                                addonDataList.some(
                                  (data) => data.addonName == item.code
                                ) ? (
                                  <button className="recbtn">Added</button>
                                ) : (
                                  <button
                                    className="recbtn"
                                    onClick={() => {
                                      putAddon(item.code);
                                    }}
                                  >
                                    <FaPlus
                                      style={{
                                        marginTop: "-2px",
                                        height: "13px",
                                      }}
                                    />
                                    Add
                                  </button>
                                )}
                              </div>
                            </div>
                            <div className="addon-extra-hdfc">
                              <p className="reccard-des">
                                Description:{" "}
                                <span className="des-span">
                                  {item.description}
                                </span>
                              </p>
                            </div>
                          </div>
                        );
                      }
                    })}
                </div>
              </div>
              <div className="col-lg-4">
                <Summary
                  nextPage="proposal"
                  addonlist={addonlist}
                  setOneData={setOneData}
                  setTwoData={setTwoData}
                  setThreeData={setThreeData}
                  planInfo={apiResponse}
                  addonsArray={addonArr}
                  setAddonArr={setAddonArr}
                  setApiResponse={setApiResponse}
                  apiResponse={apiResponse}
                  hdcVal={hdcVal}
                  deductibleVal={deductibleVal}
                  beforeAddon={beforeAddon}
                  setBeforeAddon={setBeforeAddon}
                  addonPrev={addonPrev}
                  setAddonPrev={setAddonPrev}
                  addondataList={addonDataList}
                  setAddonDataList={setAddonDataList}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Afterplanfatch;
