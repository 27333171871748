import React,{useEffect,useState} from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./AllPageComponents/Footer";
import HealthStep1 from "./Health/pages/HealthStep1Page/HealthStep1";
import HealthStep2 from "./Health/pages/HealthStep2Page/HealthStep2";
import HealthStep3 from "./Health/pages/HealthStep3Page/HealthStep3";
import HealthStep4 from "./Health/pages/HealthStep4Page/HealthStep4";
import Floater from "./AllPageComponents/Floater";
import Plans from "./Health/pages/PlansPage/Plans";
import Afterplanfatch from "./Health/pages/AfterPlanFetchPage/Afterplanfatch";
import Proposalfrom from "./Health/pages/ProposalPage/Proposalfrom";
import MedicalDetails from "./Health/pages/MedicalDetailsPage/MedicalDetails";
import KYCfrom from "./Health/pages/KycPage/KYCfrom";
import AfterPayment from "./CommonPages/AfterPayment";
import Header from "./CommonPages/Header/Header";
import About from "./CommonPages/AboutPage/About";
import PaymentSuccess from "./CommonPages/Payment/PaymentSuccess";
import PaymentFiled from "./CommonPages/Payment/PaymentFiled";
import Career from "./CommonPages/CareerPage/Career";
import Termsandcondition from "./CommonPages/TermsAndCondition/Termsandcondition";
import Beemablog from "./CommonPages/BlogPage/Beemablog";
import ContractUs from "./CommonPages/ContractUs/ContractUs";
import Privacypolicy from "./CommonPages/PrivacyPolicy/Privacypolicy";
import Homepage from "./HomePage/Homepage";
import Healthinsurence from "./Health/pages/HealthInsurePage/Healthinsurence";
import Singelblog from "./CommonPages/BlogPage/SingleBlog/Singelblog";
import TermInsurence from "./TermInsurence/TermInsurence";
import Dashbord from "./CommonPages/Dashbord/Dashbord";
import BikeInsurence from "./BikeInsurence/BikeInsurence";
import Carinsurence from "./CarInsurnce/Carinsurence";
import Dashbordpolicy from "./CommonPages/Dashbord/Dashbordpolicy";
import Clamemanagement from "./CommonPages/Dashbord/Clamemanagement";
import Reminder from "./CommonPages/Dashbord/Reminder";
import SIP from "./CommonPages/Calculators/SIP/SIP";
import Lumpsum from "./CommonPages/Calculators/Lumpsum/Lumpsum";
import BMI from "./CommonPages/Calculators/BMI/BMI";
import CompareQuotes from "./CarInsurnce/CompareQuotes";
import CashlessGarage from "./CarInsurnce/CashlessGarage";
import PremiumCalculator from "./CarInsurnce/PremiumCalculator";
import Renewal from "./CarInsurnce/Renewal";
import AddonCover from "./CarInsurnce/AddonCover";
import BikeCompareQuotes from "./BikeInsurence/BikeCompareQuotes";
import BikeCompanies from "./BikeInsurence/BikeCompanies";
import BikeCashlessGarages from "./BikeInsurence/BikeCashlessGarages";
import BikeStandAlone from "./BikeInsurence/BikeStandAlone";
import BikeRenewal from "./BikeInsurence/BikeRenewal";
import BikeComprehensive from "./BikeInsurence/BikeComprehensive";
import BikeZeroDepreciation from "./BikeInsurence/BikeZeroDepreciation";
import BikeThirdParty from "./BikeInsurence/BikeThirdParty";
import BikeAddonCovers from "./BikeInsurence/BikeAddonCovers";
import BikeClaimSettlement from "./BikeInsurence/BikeClaimSettlement";
import HealthCompareQuotes from "./Health/pages/HealthSubPage/HealthInsurance/HealthCompareQuotes";
import FamilyHealthInsurance from "./Health/pages/HealthSubPage/HealthInsurance/FamilyHealthInsurance";
import CriticalillnessInsurance from "./Health/pages/HealthSubPage/HealthInsurance/CriticalillnessInsurance";
import HealthTopupPlan from "./Health/pages/HealthSubPage/HealthInsurance/HealthTopupPlan";
import SeniorCitizenInsurance from "./Health/pages/HealthSubPage/HealthInsurance/SeniorCitizenInsurance";
import IndividualHealthInsurance from "./Health/pages/HealthSubPage/HealthInsurance/IndividualHealthInsurance";
import TermCompareQuotes from "./TermInsurence/TermCompareQuotes";
import TermCompareInsurance from "./TermInsurence/TermCompareInsurance";
import TermNriPlans from "./TermInsurence/TermNriPlans";
import TermInsuranceInner from "./TermInsurence/TermInsuranceInner";
import TermTaxBenefits from "./TermInsurence/TermTaxBenefits";
import TermFamilyHealthInsurance from "./TermInsurence/TermFamilyHealthInsurance";
import ClaimSettlement from "./CarInsurnce/ClaimSettlement";
import SSY from "./CommonPages/Calculators/SSY/SSY";
import FDcalculator from "./CommonPages/Calculators/FD/FDcalculator";
import HomeLoan from "./CommonPages/Calculators/HomeLoan/HomeLoan";
import CarLoan from "./CommonPages/Calculators/CarLoan/CarLoan";
import EMI from "./CommonPages/Calculators/EMI/EMI";
import Simpleinterest from "./CommonPages/Calculators/Simpleinterest/Simpleinterest";
import RD from "./CommonPages/Calculators/RD/RD";
import Calculatorchart from "./CommonPages/Calculators/Calculator-Charat/Calculatorchart";
import Incometax from "./CommonPages/Calculators/IncomeTax/Incometax";
import SWP from "./CommonPages/Calculators/SWP/SWP";
import NSP from "./CommonPages/Calculators/NPS/NSP";
import Setreminder from "./CommonPages/Dashbord/Set_Reminder/Setreminder";
import CompairPolicyPage from "./Health/pages/CompairPolicy/CompairPolicyPage";
import HLV from "./CommonPages/Calculators/HuamnLifeCalculator/HLV";
import Retairment from "./CommonPages/Calculators/RetairmentCalculator/Retairment";
import Penson from "./CommonPages/Calculators/PensionCalculator/Penson";
import ChildEducationPlanner from "./CommonPages/Calculators/ChildEducationPlanner/ChildEducationPlanner";
import Marrige from "./CommonPages/Calculators/MarrigeCalculator/Marrige";
import ULIPCalculator from "./CommonPages/Calculators/ULIPCalculator/ULIPCalculator";
import Editprofile from "./CommonPages/EditProfile/Editprofile";
import HealthBenifits from "./Health/pages/HealthInsurePage/HealthBenifits/HealthBenifits";
import BeemaAdvantage from "./CommonPages/BeemaAdvantage/BeemaAdvantage";
import Howbeemaone from "./CommonPages/HowBeemaoneWorks/Howbeemaone";
import Typeofpolicy from "./AllPageComponents/TypeofPolicy/Typeofpolicy";
import Understandpolicy from "./HomePage/UnderStandpolicy/Understandpolicy";
import Demo from "./Health/pages/Demo";
import Healthinsure from "./HomePage/UnderStandpolicy/Healthinsure";
import Companypolicypage from "./HomePage/UnderStandpolicy/Companypolicypage";
import Stepper from "./HomePage/Stepper/Stepper";
import Homepagecalculator from "./HomePage/HomepageCalculator/Homepagecalculator";
import ErrorBoundary from "./CommonPages/ErrorBoundary/ErrorBoundary";
import BondCalculator from "./CommonPages/Calculators/Bond/BondCalculator";
import Termstepper1 from "./TermInsurence/Termstepper1";
import Termstepper2 from "./TermInsurence/Termstepper2";
import Termstepper3 from "./TermInsurence/Termstepper3";
import TermfetchPlanes from "./TermInsurence/TermfetchPlanes/TermfetchPlanes";
import TermsummeryPage from "./TermInsurence/TermSummeryPage/TermsummeryPage";
import ProgressStepper from "./Health/pages/PlansPage/ProgressStepper";
import Test from "./Health/commonComponents/Test";


import HealthInsureClamesttelment from "./Health/pages/HealthSubPage/HealthInsureClamesttelment";
import HealthInsurenceforperents from "./Health/pages/HealthSubPage/HealthInsurance/HealthInsurenceforperents";
import CashlessHealthTreatment from "./Health/pages/HealthSubPage/HealthInsurance/CashlessHealthTreatment";
import HealthCoPayment from "./Health/pages/HealthSubPage/HealthInsurance/HealthCoPayment";
import CapitalGain from "./CommonPages/Calculators/CapitalGainCalculator/CapitalGain";
import ShortTermCapital from "./CommonPages/Calculators/CapitalGainCalculator/ShortTermCapital";
import ErrorDisplay from "./CommonPages/ErrorBoundary/ErrorDisplay";
import PaymentProcess from "./CommonPages/Payment/PaymentProcess";
import Headersticky from "./CommonPages/Header/Headersticky";
import ProctedRoute from "./CommonPages/Dashbord/ProctedRouts/ProctedRoute";


function App() {
  const shouldShowFooter = () => {
    const hiddenFooterRoutes = ["/health/payment/success"];
    const hiddenFooterRoutese = ["/health/payment/failed"]; // Change this route as needed
    return (
      !hiddenFooterRoutes.includes(window.location.pathname) &&
      !hiddenFooterRoutese.includes(window.location.pathname)
    );
  };






 
  return (
    <>
      <ErrorBoundary>
        <Router>
      <Header />
          <Floater />
          <Routes>
            <Route path="/" element={<Homepage />} />

            <Route path="/health-insurance/*">

              <Route path="" element={<Healthinsurence />} />
              <Route path="health-compare-quotes" element={<HealthCompareQuotes />}/>
                 <Route
                path="health-clame-settelment"
                element={<HealthInsureClamesttelment />}
              />
                      <Route
                path="health-co-payment"
                element={<HealthCoPayment />}
              />
              <Route
                path="family-health-insurance"
                element={<FamilyHealthInsurance />}
              />
              <Route
                path="critical-illness-insurance"
                element={<CriticalillnessInsurance />}
              />
              <Route path="health-topup-plan" element={<HealthTopupPlan />} />
              <Route
                path="senior-citizen-insurance"
                element={<SeniorCitizenInsurance />}
              />
              <Route
                path="individual-health-insurance"
                element={<IndividualHealthInsurance />}
              />
                <Route
                path="cashless-health-insurance"
                element={<CashlessHealthTreatment/>}
              />

<Route
                path="parents-health-insurance"
                element={<HealthInsurenceforperents />}
              />

</Route>



          {/*Sub Rout For making Sub component and Adds in  main pages ithis not adding addtinal Routh or Path*/}

          <Route path="beema-advantage" element={<BeemaAdvantage />} />
          <Route path="howbeemawork" element={<Howbeemaone />} />   
          <Route path="typeofpolicy" element={<Typeofpolicy />} />   
          <Route path="stepper" element={<Stepper />} />  
          <Route path="Homepagecalculator" element={<Homepagecalculator />} />





          <Route path="understandpolicy" element={<Understandpolicy />} />  
          <Route path="/health-insurance/:selectInsurance/:selectPolicy" element={<Healthinsure />} />
          <Route path="/health-insurance/:selectInsurance/" element={<Companypolicypage />} />


          <Route path="demo" element={<Demo />} />  
          {/*hear is last one of sub Component path*/}


          <Route path="/" element={<Homepage/>}/>
          <Route path="/term-insurance" element={<TermInsurence/>}/>
          <Route path="/healthStep1" element={<HealthStep1/>}/>
          <Route path="/healthStep2" element={<HealthStep2/>}/>
          <Route path="/healthStep3" element={<HealthStep3/>}/>
          <Route path="/healthStep4" element={<HealthStep4/>}/>
          <Route path="/plans" element={<Plans/>}/>
          <Route path="/afterplanfatch" element={<Afterplanfatch/>}/>
          <Route path="/proposal" element={<Proposalfrom/>}/>
          <Route path="/medicaldetails" element={<MedicalDetails/>}/>
          <Route path="/kyc" element={<KYCfrom/>}/>
          <Route path="/afterPayment" element={<AfterPayment/>}/>
          <Route path="/about-us" element={<About/>}/>
          <Route path="/career" element={<Career/>}/>
          <Route path="/termsandcondition" element={<Termsandcondition/>}/>
          <Route path="/bloges" element={<Beemablog/>}/>
          <Route path="/bloges/:slug/:id" element={<Singelblog/>}/>
          <Route path="/contractus" element={<ContractUs/>}/>

          <Route element={<ProctedRoute/>}>
          <Route path="/user-profile" element={<Editprofile/>}/>
          <Route path="/dashbord" element={<Dashbord/>}/>
          <Route path="/dashbord-policy" element={<Dashbordpolicy/>}/>
          <Route path="/dashbord-Claim" element={<Clamemanagement/>}/>
          </Route>


   
          <Route path="/setup-reminder" element={<Setreminder/>}/>
          <Route path="/Bike-insurance" element={<BikeInsurence/>}/>
          <Route path="/car-insurance" element={<Carinsurence/>}/>
          <Route path="/travel-insurance" element={<Reminder/>}/>


          <Route path="calculator/*">
          <Route path="" element={<Calculatorchart/>}/>
          <Route path="sip-calculator" element={<SIP />} />
          <Route path="lumpsum-calculator" element={<Lumpsum />} />
          <Route path="fd-calculator" element={<FDcalculator/>}/>
          <Route path="emi-calculator" element={<EMI/>}/>
          <Route path="car-loan-calculator" element={<CarLoan/>}/>
          <Route path="home-loan-calculator" element={<HomeLoan/>}/>
          <Route path="rd-calculator" element={<RD/>}/>
          <Route path="simple-interest-calculator" element={<Simpleinterest/>}/>
          <Route path="swp-calculator" element={<SWP/>}/>
          <Route path="nps-calculator" element={<NSP/>}/>
          <Route path="bmi-calculator" element={<BMI />} />
          <Route path="income-tax-calculator" element={<Incometax/>}/>
          <Route path="ssy-calculator" element={<SSY/>}/>
          <Route path="bond-calculator" element={<BondCalculator/>}/>
          <Route path="child-marrige-calculator" element={<Marrige/>}/>
          <Route path="pension-calculator" element={<Penson/>}/>
          <Route path="human-life-vlue-calculator" element={<HLV/>}/>
          <Route path="child-education-calculator" element={<ChildEducationPlanner/>}/>
          <Route path="retairment-calculator" element={<Retairment/>}/>
          <Route path="ulip-calculator" element={<ULIPCalculator/>}/>
          </Route>




    


          

          <Route path="/term-insurance/*">
          <Route path="" element={<TermInsurence />}/>
          <Route path="term-compare-quotes" element={<TermCompareQuotes />}/>
          <Route path="term-compare-insurance" element={<TermCompareInsurance />}/>
          <Route path="term-nri-plans" element={<TermNriPlans />}/>
          <Route path="term-insurance-inner" element={<TermInsuranceInner />}/>
          <Route path="term-tax-benefits" element={<TermTaxBenefits />}/>
          <Route path="family-insurance" element={<TermFamilyHealthInsurance />}/>
          <Route path="termstep1" element={<Termstepper1/>}/> 
          <Route path="termstep2" element={<Termstepper2/>}/> 
          <Route path="termstep3" element={<Termstepper3/>}/> 
          <Route path="termfetchplanes" element={<TermfetchPlanes/>}/> 
          <Route path="termsummery" element={<TermsummeryPage/>}/> 



          </Route>
          <Route path="/plans" element={<Plans />} />
          <Route path="/afterplanfatch" element={<Afterplanfatch />} />
          <Route path="/proposal" element={<Proposalfrom />} />
          <Route path="/medicaldetails" element={<MedicalDetails />} />
          <Route path="/kyc" element={<KYCfrom />} />
          <Route path="/afterPayment" element={<AfterPayment />} />
          <Route path="/careers" element={<Career />} />
          <Route path="/termsandcondition" element={<Termsandcondition />} />
          <Route path="/blogs" element={<Beemablog />} />
          <Route path="/blogs/:slugs/:id" element={<Singelblog />} />
          <Route path="/contractus" element={<ContractUs />} />
          <Route path="/privacy-policy" element={<Privacypolicy />} />


          <Route path="/bike-insurance/*"  >
          <Route path="" element={<BikeInsurence />}/>

          <Route path="bike-compare-quotes" element={<BikeCompareQuotes/>}/>
            <Route path="bike-companies" element={<BikeCompanies/>}/>
            <Route path="bike-cashless-garages" element={<BikeCashlessGarages/>}/>
            <Route path="bike-stand-alone-garages" element={<BikeStandAlone/>}/>
            <Route path="bike-renewal" element={<BikeRenewal/>}/>
            <Route path="bike-comprehensive" element={<BikeComprehensive/>}/>
            <Route path="bike-zero-depreciation" element={<BikeZeroDepreciation/>}/>
            <Route path="bike-third-party" element={<BikeThirdParty/>}/>
            <Route path="bike-addon-covers" element={<BikeAddonCovers/>}/>
            <Route path="bike-claimn-settlement" element={<BikeClaimSettlement/>}/>
            </Route>

            {/*Sub Rout For making Sub component and Adds in  main pages ithis not adding addtinal Routh or Path*/}

            <Route path="beema-advantage" element={<BeemaAdvantage />} />
            <Route path="testing" element={<ProgressStepper/>} />
           
            <Route path="howbeemawork" element={<Howbeemaone />} />
            <Route path="typeofpolicy" element={<Typeofpolicy />} />
            <Route path="stepper" element={<Stepper />} />
            <Route path="Homepagecalculator" element={<Homepagecalculator />} />

            <Route path="understandpolicy" element={<Understandpolicy />} />
            <Route
              path="/health-insurance/:selectInsurance/:selectPolicy"
              element={<Healthinsure />}
            />
            <Route
              path="/health-insurance/:selectInsurance/"
              element={<Companypolicypage />}
            />

            <Route path="demo" element={<Demo />} />
            {/*hear is last one of sub Component path*/}

            <Route path="/" element={<Homepage />} />
            {/* <Route path="/term-insurence" element={<TermInsurence />} /> */}
            <Route path="/test" element={""} />
            <Route path="/plans" element={<Plans />} />
            <Route path="/afterplanfatch" element={<Afterplanfatch />} />
            <Route path="/proposal" element={<Proposalfrom />} />
            <Route path="/medicaldetails" element={<MedicalDetails />} />
            <Route path="/kyc" element={<KYCfrom />} />
            <Route path="/afterPayment" element={<AfterPayment />} />

            <Route path="/career" element={<Career />} />
            <Route path="/termsandcondition" element={<Termsandcondition />} />
            <Route path="/bloges" element={<Beemablog />} />
            <Route path="/bloges/:slug/:id" element={<Singelblog />} />
            <Route path="/contractus" element={<ContractUs />} />
            {/* <Route path="/user-profile" element={<Editprofile />} /> */}


            <Route path="/setup-reminder" element={<Setreminder />} />
            <Route path="/Bike-insurance" element={<BikeInsurence />} />
            <Route path="/car-insurance" element={<Carinsurence />} />
            <Route path="/travel-insurance" element={<Reminder />} />

            <Route path="calculator/*">
              <Route path="" element={<Calculatorchart />} />
              <Route path="sip-calculator" element={<SIP />} />
              <Route path="lumpsum-calculator" element={<Lumpsum />} />
              <Route path="fd-calculator" element={<FDcalculator />} />
              <Route path="emi-calculator" element={<EMI />} />
              <Route path="car-loan-calculator" element={<CarLoan />} />
              <Route path="home-loan-calculator" element={<HomeLoan />} />
              <Route path="rd-calculator" element={<RD />} />
              <Route
                path="simple-interest-calculator"
                element={<Simpleinterest />}
              />
              <Route path="swp-calculator" element={<SWP />} />
              <Route path="nps-calculator" element={<NSP />} />
              <Route path="bmi-calculator" element={<BMI />} />
              <Route path="income-tax-calculator" element={<Incometax />} />
              <Route path="ssy-calculator" element={<SSY />} />
              <Route path="bond-calculator" element={<BondCalculator />} />
              <Route path="child-marrige-calculator" element={<Marrige />} />
              <Route path="pension-calculator" element={<Penson />} />
              <Route path="human-life-vlue-calculator" element={<HLV />} />
              <Route
                path="child-education-calculator"
                element={<ChildEducationPlanner />}
              />


               <Route path="longtermcapitalgain" element={<CapitalGain/>} />
               <Route path="shorttermcapitalgain" element={<ShortTermCapital/>} />

              <Route path="retairment-calculator" element={<Retairment />} />
              <Route path="ulip-calculator" element={<ULIPCalculator />} />
            </Route>

            <Route path="/term-insurance/*">
              <Route path="" element={<TermInsurence />} />
              <Route
                path="term-compare-quotes"
                element={<TermCompareQuotes />}
              />
              <Route
                path="term-compare-insurance"
                element={<TermCompareInsurance />}
              />
              <Route path="term-nri-plans" element={<TermNriPlans />} />
              <Route
                path="term-insurance-inner"
                element={<TermInsuranceInner />}
              />
              <Route path="term-tax-benefits" element={<TermTaxBenefits />} />
              <Route
                path="family-insurance"
                element={<TermFamilyHealthInsurance />}
              />
            </Route>
            <Route path="/plans" element={<Plans />} />
            <Route path="/afterplanfatch" element={<Afterplanfatch />} />
            <Route path="/proposal" element={<Proposalfrom />} />
            <Route path="/medicaldetails" element={<MedicalDetails />} />
            <Route path="/kyc" element={<KYCfrom />} />
            <Route
              path="/compairpolicy"
              element={<CompairPolicyPage />}
            />
            <Route path="/afterPayment" element={<AfterPayment />} />

            <Route path="/careers" element={<Career />} />
            <Route path="/termsandcondition" element={<Termsandcondition />} />
            <Route path="/blogs" element={<Beemablog />} />
            <Route path="/blogs/:slugs/:id" element={<Singelblog />} />
            <Route path="/contractus" element={<ContractUs />} />
            <Route path="/privacy-policy" element={<Privacypolicy />} />


            <Route path="/bike-insurance/*">
              <Route path="" element={<BikeInsurence />} />

              <Route
                path="bike-compare-quotes"
                element={<BikeCompareQuotes />}
              />
              <Route path="bike-companies" element={<BikeCompanies />} />
              <Route
                path="bike-cashless-garages"
                element={<BikeCashlessGarages />}
              />
              <Route
                path="bike-stand-alone-garages"
                element={<BikeStandAlone />}
              />
              <Route path="bike-renewal" element={<BikeRenewal />} />
              <Route
                path="bike-comprehensive"
                element={<BikeComprehensive />}
              />
              <Route
                path="bike-zero-depreciation"
                element={<BikeZeroDepreciation />}
              />
              <Route path="bike-third-party" element={<BikeThirdParty />} />
              <Route path="bike-addon-covers" element={<BikeAddonCovers />} />
              <Route
                path="bike-claimn-settlement"
                element={<BikeClaimSettlement />}
              />
            </Route>

            <Route path="car-insurance/*">
              <Route path="" element={<Carinsurence />} />
              <Route path="compare-quotes" element={<CompareQuotes />} />
              <Route path="cashless-garage" element={<CashlessGarage />} />
              <Route
                path="premium-calculator"
                element={<PremiumCalculator />}
              />
              <Route path="renewal" element={<Renewal />} />
              <Route path="addoncover" element={<AddonCover />} />
              <Route path="claimn-settlement" element={<ClaimSettlement />} />
            </Route>

            <Route path="health/*">
              <Route path="payment/success" element={<PaymentSuccess />} />
              <Route path="payment/failed" element={<PaymentFiled />} />
              <Route path="payment/process" element={<PaymentProcess/>} />

            </Route>
          </Routes>
          {shouldShowFooter() && <Footer />}
        </Router>
      </ErrorBoundary>
    </>
  );
}

export default App;
